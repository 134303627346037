import { html } from "htm/preact";
import { generateId, getPage, Pagination, t, useInput } from "./core";
import { useContext, useEffect, useRef, useState } from "preact/hooks";

import Select from 'react-select'
import { citites, socialSecurityOptions } from "./data";

import { Api, debounce, filesStore, getDownloadUrl, merge, searchObjects, SessionContext, SFile, state, useSession } from "./store";
import { Billable } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreatableSelect from 'react-select/creatable';
import { Drive } from "./products";
import { FileUpload, ObjectRow, UpdateObject } from "./crud";
import { IdentifierTypes } from "./entities";
import { IdentityTypeIcon } from "./visitors-log";


// hello

const options = citites.map(it => ({ label: it.c + ', ' + it.g, value: it.id1 + '-' + it.id2 }));
const insuranceOptions = [{ label: 'Selectioner' }, { label: 'CNSS', value: 'cnss' }, { label: 'CNRPS', value: 'cnrps' }];

/*
function useForm(pairs:[string,string][],callback:any){

    const debounce = 
    const out:any[] = [];
    for(const pair of pairs) {
        const [state, setState] = useState(pair[1]);
        out.push([state, (ev: any) => {
            setState(ev.target.value);


            // dispatch({collection, type: 'mutate', id,  data: {[pair[0]]: ev.target.value}})
        }]);
    }
    return out;
}*/




/*
pub struct Contact {
    pub id: String,
    pub name: String,
    pub legal_status: String,
    pub address: Option<String>,
    pub postal_code: Option<String>,
    pub created_by: i64,
    pub created_at: NaiveDateTime,
    pub updated_at: NaiveDateTime,
    pub data: JsonMap,
}
*/

/*
    pub id: String,
    pub name: String,
    pub phone: String,
    pub email: Option<String>,
    pub address: Option<String>,
    pub postal_code: Option<String>,
    pub created_by: i64,
    pub created_at: NaiveDateTime,
    pub updated_at: NaiveDateTime,
    pub data: JsonMap,
    */

export interface Contact {
    occupation: string;
    id: number;
    identifier: string;
    identifier_type: string;
    identifier_date?: Date;
    name: string;
    phone: string;
    email?: string;
    address?: string;
    postal_code?: string;
    data: { [key: string]: string };
}

export interface NewContact {
    identifier: string;
    identifier_type: string;
    identifier_date?: string;
    name: string;
    phone: string;
    email?: string;
    address?: string;
    postal_code?: string;
    data: { [key: string]: string };
}


export const LegalStatus = [
    'EI', 'SUARL', 'SARL', 'SA'
]

export function ContactForm({ data: contactData, formRef, onContactAdded }: { data: Contact, formRef?: any, onContactAdded: any }) {


    const contact = contactData;

    console.log('contact', contact);

    const [identifier, setIdentifier] = useInput(contact?.identifier || '');
    const [identifier_type, setIdentifierType] = useState<any>(contact?.identifier_type ? IdentifierTypes.find(it => it.value == contact.identifier_type) : undefined);
    const [identifier_date, setIdentifierDate] = useInput(contact?.identifier_date);

    const [phone, setPhone] = useInput(contact?.phone);
    const [email, setEmail] = useInput(contact?.email);
    const [name, setName] = useInput(contact?.name || '');
    //    const [legal_status, setLegalStatus] = useInput(contact?.legal_status || '');
    const [address, setAddress] = useInput(contact?.address || '');
    const [postal_code, setPostalCode] = useInput(contact?.postal_code || '');
    const [data, setData] = useState(contact?.data || {});
    const [occupation, setOccupation] = useInput(contact?.occupation || '');

    //    const [contacts, setContacts] = useState<any[]>([]);


    const [validated, setValidated] = useState(false);
    const [state, dispatch] = useSession();

    const onSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {


            const newContact: NewContact = { identifier, identifier_type: identifier_type?.value, identifier_date, name, email, phone, address, postal_code, data };

            if (contact?.id) {

                Api.patch('/contacts', contact.id, newContact, state.token)
                    .then((contactType) => {
                        toast.success(t('Contact updated successfully'));
                        route('/contacts/' + contactType.id);
                    });

            } else {
                Api.post('/contacts', newContact, state.token).then((contact) => {
                    toast.success(t('User added successfully'));
                    if (onContactAdded) {
                        onContactAdded(contact);
                    } else {
                        route('/contacts/' + contact.id);
                    }
                });
            }

        }

    };


    return html`<form ref=${formRef} id="contact-form" onsubmit="${onSubmit}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-12">
        <label class="form-label" for="name">${t('Name')}</label>
        <input pattern=".{3,}" class="form-control" id="name" type="text" oninput="${setName}" value="${name}" required />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="name">${t('Unique Identifier')}</label>
        <input type="text" class="form-control" id="name" value=${identifier} onInput=${setIdentifier} required />
        <div class="invalid-feedback">${t('Unique Identifier')} ${t('is required')}</div>
    </div>
    <div class="col-lg-3">
        <label class="form-label" for="type">${t('Identifier Type')}</label>
        <${Select} value=${identifier_type}
        options=${IdentifierTypes} onChange=${setIdentifierType}
        className="rs form-control ${validated && (identifier_type ? 'is-valid' : 'is-invalid')}" />
    </div>
    <div class="col-lg-3">
        <label class="form-label" for="type">${t('Identifier Date')}</label>
        <input type="date"  value=${identifier_date} onInput=${setIdentifierDate} class="form-control" />
    </div>
     <div class="col-lg-6">
        <label class="form-label" for="occupation">${t('Occupation')}</label>
        <input pattern=".{3,}" class="form-control" id="occupation" type="text" oninput="${setOccupation}" value="${occupation}" required />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="phone">${t('Phone')}</label>
        <input pattern=".{3,}" class="form-control" id="phone" type="text" oninput="${setPhone}" value="${phone}" required />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="email">${t('Email')}</label>
        <input pattern=".{3,}" class="form-control" id="email" type="email" oninput="${setEmail}" value="${email}" required />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="address">${t('Address')}</label>
        <input pattern=".{3,}" class="form-control" id="address" type="text" oninput="${setAddress}" value="${address}" required />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="postal_code">${t('Postal Code')}</label>
        <input pattern=".{3,}" class="form-control" id="postal_code" type="text" oninput="${setPostalCode}" value="${postal_code}" required />
    </div>
    ${formRef ? '' : html`
    <div class="col-12 d-flex justify-content-end">
        <button id="contact-submit" class="btn btn-primary" type="submit"> ${contact?.id ? t('Update') : t('Create')}</button>
    </div>`}
</form>`;
}


export function AddIncident() {
    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">${t('Add')} ${t('Incident')}</h5>
            </div>
            <div class="card-body bg-light">
                <${IncidentForm} />
            </div>
        </div>
    </div>
</div>`;
}


export interface FormObject {
    id: string;
    kind: string;
    data: { [key: string]: any };
    meta: { [key: string]: any };
}


export function IncidentsOverview() {

    const [session, dispatch] = useSession();


    const [objects, setObjects] = useState<Contact[]>([]);
    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;
    // const allUsers = searchObjects(session.users, q, sort, dir, page);
    // const users = allUsers.slice((page - 1) * 20, page * 20);


    const onSearch = (q?: any) => {
        Promise.all([
            Api.search('/forms/security-incident', q || '', 1, {}, session.token),

        ]).then(([data]: { data: any[] }[]) => {

            setObjects(data.data);
        });
    };

    useEffect(() => {
        onSearch();
        dispatch({ type: 'onSearch', data: onSearch });
    }, []);



    const onDelete = (object: any) => {
        setObjects(objects.filter(it => it.id != object.id));
    }

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t('Incidents')}</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a href="/security/incidents-add" class="btn btn-falcon-default btn-sm" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Add')}</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('id')}
                            class="sort ${sort.dir.id}" style="width:10px" data-sort>
                            ${t('Date de reclamation')}</th>
                        <th onclick=${onSort('name')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.name}" data-sort>${t('Details')}</th>
                      <!--  <th onclick=${onSort('role')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.role}" data-sort>Email
                        </th> -->
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Name')}</th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5 desktop" data-sort="address"
                            style="min-width: 200px;">${t('Status')}</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${objects.map(it => Row(it, onDelete))}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${objects.length} page=${page}/>
</div>`;
}


export function Row(object: any, onDelete: any) {

    const path = '/forms/security-incident';

    const [open, setOpen] = useState(false);
    const [state, dispatch] = useSession();
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);


    function onDeleteClick(object: any) {
        if (busy) return;
        setBusy(true);
        Api.remove(path + '/' + object.id, state.token || '')
            .then(() => {
                console.log('DELETED');
                setOpen(false);
                onDelete(object);
                setBusy(false);
            })
            .catch((err) => {
                console.log('ERROR', err);
                setBusy(false);
            });
    };

    JSON.stringify("OBJECT", object);

    return html`<tr class="btn-reveal-trigger">
    <td class="no align-middle white-space-nowrap">${object.data.dateReclamation}</td>
    <td class="name align-middle white-space-nowrap">
        <a href="/security/incidents/${object.id}">
            <pre style="line-height:1.3;font-size:13px">${Object.keys(object.data).filter(key => object.data[key] === true || object.data[key] === 'Oui')
            .filter(key => FIELDS_THAT_DO_NOT_HAVE_DETAILS.includes(key))
            .map(key => LABELS_MAP[key]).join('\n')}</pre>
        </a>
    </td>
    <td class="phone align-middle white-space-nowrap desktop">${object.data.name}</td>
    <td class="address align-middle white-space-nowrap pl-5 desktop">Detecté</td>
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(object)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}

export const UpdateContact = (props: any) => UpdateObject(ContactForm, '/contacts', props);
export const ContactsOverview = (props: any) => ObjectsOverview('Contacts', '/contacts', ContactRow, props);

function diff(a: any, base: any) {
    const out: any = {};
    for (const key in a) {
        if (a[key] != base[key]) {
            out[key] = a[key];
        }
    }
    return out;
}

export function ContactRow(row: Contact) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);

    const [state, dispatch] = useSession();

    function onDeleteClick(row: Contact) {
        dispatch({
            type: 'mutate',
            collection: 'users',
            id: row.id,
            data: { deletedAt: Date.now() },
        });
    };

    return html`<tr class="btn-reveal-trigger">
    <td class="no align-middle white-space-nowrap">${row.identifier}</td>
    <td class="name align-middle white-space-nowrap">
        <a href="/users/${row.id}">
            <h6>${row.name}</h6>
        </a>
    </td>
    <td class="phone align-middle white-space-nowrap desktop">${(row.roles || []).map(t).join(',')}</td>
    <td class="address align-middle white-space-nowrap pl-5 desktop">${t(row.status)}</td>
<!--    <td class="joined align-middle desktop">${row.status}</td> -->
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(user)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}

function NoValue(label: string) {
    return html`<p class="font-italic text-400 mb-0">${label}</p>`;
}

function Value({ label, value }: { label: string, value: string }) {
    return value ? (html`${value}`) : NoValue(label);
}

export function IncidentDetails({ id }: { id?: string }) {

    const [session, dispatch] = useSession();

    const [user, setUser] = useState<{ data: Contact } | undefined>();

    useEffect(() => {
        if (id) {
            Api.get('/forms/security-incident', id, session.token as any).then((data) => {
                setUser(data);
            });
        }
    }, []);


    if (user) {

        return html`<div class="card mb-3">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h5 class="mb-0">#${user.data.name}</h5>
            </div>

<!--            <div class="col-auto"><a class="btn btn-falcon-default btn-sm" href="/contacts/${id}/edit">
                    <${FontAwesomeIcon} icon=${['fas', 'pencil-alt']} /> ${t('Update')}</a></div>-->
        </div>
    </div>
    <div class="card-body bg-light border-top">
        <${IncidentView} formValues=${user.data.data} />
    </div>
</div>
`;

    } else {
        return html`<div>User ${id} not found</div>`;
    }
}


export interface Incident {

}


export const IncidentForm = ({ data }) => {


    const [session, dispatch] = useSession();

    const [formValues, setFormValues] = useState({
        name: session.user?.name || '',
        phone: session.user?.phone || '',
        email: session.user?.email || '',
        affectation: '',
        dateReclamation: new Date().toISOString().split('T')[0],
        dateHeureIncident: false,
        dateHeureIncidentDetail: '',
        incidentBloquant: false,
        incidentBloquantDetail: '',
        responsableFournisseur: false,
        responsableFournisseurDetail: '',
        incidentRepetitif: false,
        incidentRepetitifDetail: '',
        serveur: false,
        serveurDetail: '',
        systemeExploitation: false,
        systemeExploitationDetail: '',
        posteTravail: false,
        posteTravailDetail: '',
        messagerie: false,
        ordinateurPortable: false,
        logicielBureautique: false,
        imprimante: false,
        logicielTechnique: false,
        controleAcces: false,
        autreActif: false,
        autreActifDetail: '',
        perteObjet: false,
        fraude: false,
        mauvaisFonctionnementSysteme: false,
        erreurSaisie: false,
        mauvaisFonctionnementLogiciel: false,
        accidentElectrique: false,
        mauvaisFonctionnementMateriel: false,
        arretLogicielCritique: false,
        degradationPerformance: false,
        volEquipement: false,
        detectionVirus: false,
        volSupportAmovible: false,
        antivirusDesactive: false,
        volDocuments: false,
        accesNonAutorise: false,
        volOrdinateurPortable: false,
        divulgationDonnees: false,
        degradationServiceInternet: false,
        modificationFichier: false,
        menaceHarcelement: false,
        autreIncident: false,
        autreIncidentDetail: '',
        dgrhContactee: '',
        donneesCompromises: '',
        detailsDonneesCompromises: '',
    });

    const set = (key: keyof Incident, field?: string, index?: number) => (value: any) => {
        if (field && index !== undefined) {
            const item = (formValues[key] as any)[index] || {} as any;
            (formValues[key] as any)[index] = { ...item, [field]: value.target ? value.target.value : value };
            setFormValues({ ...formValues });

        } else {
            setFormValues({ ...formValues, [key]: value.target ? value.target.value : value });
        }
    }

    const handleCheckboxChange = (field) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [field]: !prevValues[field],
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const [validated, setValidated] = useState(false);

    const handleSubmit = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {

            const newObject = { ...formValues };

            if (data?.id) {

                Api.patch('/forms/security-incident', data.id, newObject, session.token)
                    .then((res) => {
                        toast.success(t('Incident updated successfully'));
                    });

            } else {
                Api.post('/forms/security-incident', newObject, session.token).then((res) => {
                    toast.success(t('Incident Submitted Successfully'));
                    route('/security/incidents/' + res.id);
                    /*if (onAdded) {
                        onAdded(entity);
                    } else {
                        // route('/entities/' + entity.id);
                    }*/
                });
            }

        }

    };


    return html`<style>
    .form-check .form-check-input {
    float: unset !important;
    margin-right: 1em;
}
    </style>
    <form dir="ltr" onSubmit=${handleSubmit} class="row g-3 needs-validation" novalidate>
      <!-- Basic Information -->
      <div class="col-lg-6">
        <label for="nom" class="form-label">Nom</label>
        <input
          type="text"
          name="name"
          class="form-control"
          id="name"
          value=${formValues.name}
          disabled
        />
      </div>

      <div class="col-lg-6">
        <label for="telephone" class="form-label">Téléphone</label>
        <input
          type="text"
          name="telephone"
          class="form-control"
          id="telephone"
          value=${formValues.phone}
          disabled
          required
        />
      </div>

      <div class="col-lg-6">
        <label for="email" class="form-label">E-mail</label>
        <input
          type="email"
          name="email"
          class="form-control"
          id="email"
          value=${formValues.email}
          disabled
          required
        />
      </div>

      <div class="col-lg-6">
        <label for="affectation" class="form-label">Affectation</label>
        <input
          type="text"
          name="affectation"
          class="form-control"
          id="affectation"
          value=${formValues.affectation}
          disabled
          required
        />
      </div>

      <div class="col-lg-6">
        <label for="dateReclamation" class="form-label">Date de réclamation</label>
        <input
          type="date"
          name="dateReclamation"
          class="form-control"
          id="dateReclamation"
          value=${formValues.dateReclamation}
          onInput=${handleInputChange}
        />
      </div>

      <!-- Incident Information -->
      <h3>INFORMATIONS SUR L’INCIDENT</h3>

      <div class="col-6">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="dateHeureIncident"
            checked=${formValues.dateHeureIncident}
            onChange=${() => handleCheckboxChange('dateHeureIncident')}
          />
          <label class="form-check-label" for="dateHeureIncident">
            Date heure Incident
          </label>
        </div>
        ${formValues.dateHeureIncident &&
        html`
          <div class="mt-2">
            <input
              type="datetime-local"
              name="dateHeureIncidentDetail"
              class="form-control"
              value=${formValues.dateHeureIncidentDetail}
              onInput=${handleInputChange}
            />
          </div>
        `}
      </div>

      <div class="col-6">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="incidentBloquant"
            checked=${formValues.incidentBloquant}
            onChange=${() => handleCheckboxChange('incidentBloquant')}
          />
          <label class="form-check-label" for="incidentBloquant">
            Incident bloquant
          </label>
        </div>
        ${formValues.incidentBloquant &&
        html`
          <div class="mt-2">
            <input
              type="text"
              name="incidentBloquantDetail"
              class="form-control"
              value=${formValues.incidentBloquantDetail}
              onInput=${handleInputChange}
            />
          </div>
        `}
      </div>

      <div class="col-6">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="responsableFournisseur"
            checked=${formValues.responsableFournisseur}
            onChange=${() => handleCheckboxChange('responsableFournisseur')}
          />
          <label class="form-check-label" for="responsableFournisseur">
            Responsable/fournisseur de l’application
          </label>
        </div>
        ${formValues.responsableFournisseur &&
        html`
          <div class="mt-2">
            <input
              type="text"
              name="responsableFournisseurDetail"
              class="form-control"
              value=${formValues.responsableFournisseurDetail}
              onInput=${handleInputChange}
            />
          </div>
        `}
      </div>

      <div class="col-6">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="incidentRepetitif"
            checked=${formValues.incidentRepetitif}
            onChange=${() => handleCheckboxChange('incidentRepetitif')}
          />
          <label class="form-check-label" for="incidentRepetitif">
            Incident répétitif
          </label>
        </div>
        ${formValues.incidentRepetitif &&
        html`
          <div class="mt-2">
            <input
              type="text"
              name="incidentRepetitifDetail"
              class="form-control"
              value=${formValues.incidentRepetitifDetail}
              onInput=${handleInputChange}
            />
          </div>
        `}
      </div>

      <!-- Actif Touché par l’Incident -->
      <h3>ACTIF TOUCHE PAR L’INCIDENT</h3>

      <!-- Add checkboxes for assets like Serveur, Système d’exploitation, Poste de travail, etc. -->

      ${[
            { label: 'Serveur', name: 'serveur' },
            { label: 'Système d’exploitation', name: 'systemeExploitation' },
            { label: 'Poste de travail', name: 'posteTravail' },
            { label: 'Messagerie', name: 'messagerie' },
            { label: 'Ordinateur portable', name: 'ordinateurPortable' },
            { label: 'Logiciel bureautique', name: 'logicielBureautique' },
            { label: 'Imprimante', name: 'imprimante' },
            { label: 'Logiciel technique', name: 'logicielTechnique' },
            { label: 'Control d’accès', name: 'controleAcces' },
        ].map(
            (asset) =>
                html`
            <div class="col-6">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="${asset.name}"
                  checked=${formValues[asset.name]}
                  onChange=${() => handleCheckboxChange(asset.name)}
                />
                <label class="form-check-label" for="${asset.name}">
                  ${asset.label}
                </label>
              </div>
            </div>
          `
        )}

      <div class="col-6">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="autreActif"
            checked=${formValues.autreActif}
            onChange=${() => handleCheckboxChange('autreActif')}
          />
          <label class="form-check-label" for="autreActif">Autre</label>
        </div>
        ${formValues.autreActif &&
        html`
          <div class="mt-2">
            <input
              type="text"
              name="autreActifDetail"
              class="form-control"
              value=${formValues.autreActifDetail}
              onInput=${handleInputChange}
            />
          </div>
        `}
      </div>

      <!-- Nature of Incident -->
      <h3>NATURE DE L’INCIDENT OU DU PROBLEME</h3>

      ${[
            { label: 'Perte objet, service ou de fonctionnalités système', name: 'perteObjet' },
            { label: 'Fraude', name: 'fraude' },
            { label: 'Mauvais fonctionnement du système', name: 'mauvaisFonctionnementSysteme' },
            { label: 'Erreur pendant le processus de saisie', name: 'erreurSaisie' },
            { label: 'Mauvais fonctionnement d’un logiciel', name: 'mauvaisFonctionnementLogiciel' },
            { label: 'Accident de nature électrique', name: 'accidentElectrique' },
            { label: 'Mauvais fonctionnement du matériel', name: 'mauvaisFonctionnementMateriel' },
            { label: 'Arrêt d\'un logiciel critique dû à un bug', name: 'arretLogicielCritique' },
            { label: 'Dégradation de performance', name: 'degradationPerformance' },
            { label: 'Vol d\'équipement informatique à l\'intérieur des locaux', name: 'volEquipement' },
            { label: 'Détection d’un virus', name: 'detectionVirus' },
            { label: 'Vol ou altération d\'un support amovible', name: 'volSupportAmovible' },
            { label: 'Antivirus désactivé ou non à jour', name: 'antivirusDesactive' },
            { label: 'Vol de documents dans des bureaux', name: 'volDocuments' },
            { label: 'Accès non autorisé', name: 'accesNonAutorise' },
            { label: 'Vol d’un ordinateur portable', name: 'volOrdinateurPortable' },
            { label: 'Divulgation des données confidentielles', name: 'divulgationDonnees' },
            { label: 'Dégradation du service Internet', name: 'degradationServiceInternet' },
            { label: 'Modification accidentelle ou malveillante d\'un fichier partagé par un utilisateur non autorisé', name: 'modificationFichier' },
            { label: 'Menace / harcèlement par courriel', name: 'menaceHarcelement' },
            { label: 'Autre', name: 'autreIncident' },
        ].map(
            (incident) =>
                html`
            <div class="col-6">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="${incident.name}"
                  checked=${formValues[incident.name]}
                  onChange=${() => handleCheckboxChange(incident.name)}
                />
                <label class="form-check-label" for="${incident.name}">
                  ${incident.label}
                </label>
              </div>
              ${formValues[incident.name] && incident.name === 'autreIncident'
                        ? html`
                    <div class="mt-2">
                      <input
                        type="text"
                        name="autreIncidentDetail"
                        class="form-control"
                        value=${formValues.autreIncidentDetail}
                        onInput=${handleInputChange}
                      />
                    </div>
                  `
                        : ''}
            </div>
          `
        )}

      <!-- Additional Questions -->
      <h3>Questions supplémentaires</h3>

      <div class="col-6">
        <label class="form-label">En cas d’activités illégales, la DGRH a-t-elle été contactée ?</label>
        <select
          name="dgrhContactee"
          class="form-select"
          value=${formValues.dgrhContactee}
          onChange=${handleInputChange}
        >
          <option value="">Sélectionner</option>
          <option value="Oui">Oui</option>
          <option value="Non">Non</option>
          <option value="Ne s’applique pas">Ne s’applique pas</option>
        </select>
      </div>

      <div class="col-6">
        <label class="form-label">Des données sensibles ou critiques ont-elles été compromises ?</label>
        <select
          name="donneesCompromises"
          class="form-select"
          value=${formValues.donneesCompromises}
          onChange=${handleInputChange}
        >
          <option value="">Sélectionner</option>
          <option value="Oui">Oui</option>
          <option value="Non">Non</option>
          <option value="On ne sait pas">On ne sait pas</option>
        </select>
        ${formValues.donneesCompromises === 'Oui' &&
        html`
          <div class="mt-2">
            <label class="form-label">Détails :</label>
            <input
              type="text"
              name="detailsDonneesCompromises"
              class="form-control"
              value=${formValues.detailsDonneesCompromises}
              onInput=${handleInputChange}
            />
          </div>
        `}
      </div>

      <!-- Submit Button -->
      <div class="col-6 d-flex justify-content-end">
        <button id="incident-submit" class="btn btn-primary" type="submit">Soumettre</button>
      </div>
    </form>
  `;
};


export const IncidentView = ({ formValues }) => {

    return html`
    <div dir="ltr" class="container mt-4">
      <h2>Incident Report Details</h2>
      
      <!-- Basic Information -->
      <h3>Informations de base</h3>
      <div class="row">
        <div class="col-lg-6">
          <strong>Nom:</strong> ${formValues.name}
        </div>
        <div class="col-lg-6">
          <strong>Prénom:</strong> ${formValues.prenom}
        </div>
        <div class="col-lg-6">
          <strong>Téléphone:</strong> ${formValues.telephone}
        </div>
        <div class="col-lg-6">
          <strong>E-mail:</strong> ${formValues.email}
        </div>
        <div class="col-lg-6">
          <strong>Affectation:</strong> ${formValues.affectation}
        </div>
        <div class="col-lg-6">
          <strong>Date de réclamation:</strong> ${formValues.dateReclamation}
        </div>
      </div>
      
      <!-- Incident Information -->
      <h3>Informations sur l’incident</h3>
      <div class="row">
        <div class="col-6">
          <strong>Date heure Incident:</strong> ${formValues.dateHeureIncident
            ? formValues.dateHeureIncidentDetail
            : 'Non'}
        </div>
        <div class="col-6">
          <strong>Incident bloquant:</strong> ${formValues.incidentBloquant
            ? formValues.incidentBloquantDetail
            : 'Non'}
        </div>
        <div class="col-6">
          <strong>Responsable/fournisseur de l’application:</strong> ${formValues.responsableFournisseur
            ? formValues.responsableFournisseurDetail
            : 'Non'}
        </div>
        <div class="col-6">
          <strong>Incident répétitif:</strong> ${formValues.incidentRepetitif
            ? formValues.incidentRepetitifDetail
            : 'Non'}
        </div>
      </div>
      
      <!-- Actif Touché par l’Incident -->
      <h3>Actif touché par l’incident</h3>
      <div class="row">
        ${[
            { label: 'Serveur', name: 'serveur', detail: formValues.serveurDetail },
            { label: 'Système d’exploitation', name: 'systemeExploitation', detail: formValues.systemeExploitationDetail },
            { label: 'Poste de travail', name: 'posteTravail', detail: formValues.posteTravailDetail },
            { label: 'Messagerie', name: 'messagerie' },
            { label: 'Ordinateur portable', name: 'ordinateurPortable' },
            { label: 'Logiciel bureautique', name: 'logicielBureautique' },
            { label: 'Imprimante', name: 'imprimante' },
            { label: 'Logiciel technique', name: 'logicielTechnique' },
            { label: 'Control d’accès', name: 'controleAcces' },
            { label: 'Autre', name: 'autreActif', detail: formValues.autreActifDetail },
        ].map(
            (asset) =>
                html`
              <div class="col-6">
                <strong>${asset.label}:</strong> ${formValues[asset.name]
                        ? asset.detail || 'Oui'
                        : 'Non'}
              </div>
            `
        )}
      </div>
      
      <!-- Nature of Incident -->
      <h3>Nature de l’incident</h3>
      <div class="row">
        ${[
            { label: 'Perte objet, service ou de fonctionnalités système', name: 'perteObjet' },
            { label: 'Fraude', name: 'fraude' },
            { label: 'Mauvais fonctionnement du système', name: 'mauvaisFonctionnementSysteme' },
            { label: 'Erreur pendant le processus de saisie', name: 'erreurSaisie' },
            { label: 'Mauvais fonctionnement d’un logiciel', name: 'mauvaisFonctionnementLogiciel' },
            { label: 'Accident de nature électrique', name: 'accidentElectrique' },
            { label: 'Mauvais fonctionnement du matériel', name: 'mauvaisFonctionnementMateriel' },
            { label: 'Arrêt d\'un logiciel critique dû à un bug', name: 'arretLogicielCritique' },
            { label: 'Dégradation de performance', name: 'degradationPerformance' },
            { label: 'Vol d\'équipement informatique', name: 'volEquipement' },
            { label: 'Détection d’un virus', name: 'detectionVirus' },
            { label: 'Vol ou altération d\'un support amovible', name: 'volSupportAmovible' },
            { label: 'Antivirus désactivé ou non à jour', name: 'antivirusDesactive' },
            { label: 'Vol de documents dans des bureaux', name: 'volDocuments' },
            { label: 'Accès non autorisé', name: 'accesNonAutorise' },
            { label: 'Vol d’un ordinateur portable', name: 'volOrdinateurPortable' },
            { label: 'Divulgation des données confidentielles', name: 'divulgationDonnees' },
            { label: 'Dégradation du service Internet', name: 'degradationServiceInternet' },
            { label: 'Modification accidentelle ou malveillante d\'un fichier', name: 'modificationFichier' },
            { label: 'Menace / harcèlement par courriel', name: 'menaceHarcelement' },
            { label: 'Autre', name: 'autreIncident', detail: formValues.autreIncidentDetail },
        ].map(
            (incident) =>
                html`
              <div class="col-6">
                <strong>${incident.label}:</strong> ${formValues[incident.name]
                        ? incident.detail || 'Oui'
                        : 'Non'}
              </div>
            `
        )}
      </div>

      <!-- Additional Questions -->
      <h3>Questions supplémentaires</h3>
      <div class="row">
        <div class="col-6">
          <strong>DGRH contactée:</strong> ${formValues.dgrhContactee}
        </div>
        <div class="col-6">
          <strong>Données sensibles compromises:</strong> ${formValues.donneesCompromises}
        </div>
        ${formValues.donneesCompromises === 'Oui' &&
        html`
          <div class="col-6">
            <strong>Détails des données compromises:</strong> ${formValues.detailsDonneesCompromises}
          </div>
        `}
      </div>
    </div>
  `;
};



// LABELS

/*
{
    "accesNonAutorise": false,
    "accidentElectrique": false,
    "affectation": "",
    "antivirusDesactive": true,
    "arretLogicielCritique": false,
    "autreActif": false,
    "autreActifDetail": "",
    "autreIncident": false,
    "autreIncidentDetail": "",
    "controleAcces": false,
    "dateHeureIncident": true,
    "dateHeureIncidentDetail": "2024-10-26T00:20",
    "dateReclamation": "2024-10-13",
    "degradationPerformance": false,
    "degradationServiceInternet": false,
    "detailsDonneesCompromises": "aaaaaa",
    "detectionVirus": false,
    "dgrhContactee": "Oui",
    "divulgationDonnees": false,
    "donneesCompromises": "Oui",
    "email": "",
    "erreurSaisie": false,
    "fraude": false,
    "imprimante": false,
    "incidentBloquant": false,
    "incidentBloquantDetail": "",
    "incidentRepetitif": true,
    "incidentRepetitifDetail": "qsdqsd",
    "logicielBureautique": false,
    "logicielTechnique": true,
    "mauvaisFonctionnementLogiciel": false,
    "mauvaisFonctionnementMateriel": true,
    "mauvaisFonctionnementSysteme": false,
    "menaceHarcelement": false,
    "messagerie": false,
    "modificationFichier": false,
    "name": "Khaled Jemmi",
    "ordinateurPortable": false,
    "perteObjet": false,
    "phone": "",
    "posteTravail": false,
    "posteTravailDetail": "",
    "responsableFournisseur": false,
    "responsableFournisseurDetail": "",
    "serveur": false,
    "serveurDetail": "",
    "systemeExploitation": true,
    "systemeExploitationDetail": "",
    "volDocuments": false,
    "volEquipement": false,
    "volOrdinateurPortable": false,
    "volSupportAmovible": false
}*/
const LABELS_MAP = {
    "nom": "Nom",
    "prenom": "Prénom",
    "telephone": "Téléphone",
    "email": "E-mail",
    "affectation": "Affectation",
    "dateReclamation": "Date de réclamation",
    "dateHeureIncident": "Date heure Incident",
    "incidentBloquant": "Incident bloquant",
    "responsableFournisseur": "Responsable/fournisseur de l’application",
    "incidentRepetitif": "Incident répétitif",
    "serveur": "Serveur",
    "systemeExploitation": "Système d’exploitation",
    "posteTravail": "Poste de travail",
    "messagerie": "Messagerie",
    "ordinateurPortable": "Ordinateur portable",
    "logicielBureautique": "Logiciel bureautique",
    "imprimante": "Imprimante",
    "logicielTechnique": "Logiciel technique",
    "controleAcces": "Control d’accès",
    "autreActif": "Autre (fournir détail)",
    "perteObjet": "Perte objet, service ou de fonctionnalités système",
    "fraude": "Fraude",
    "mauvaisFonctionnementSysteme": "Mauvais fonctionnement du système",
    "erreurSaisie": "Erreur pendant le processus de saisie",
    "mauvaisFonctionnementLogiciel": "Mauvais fonctionnement d’un logiciel",
    "accidentElectrique": "Accident de nature électrique",
    "mauvaisFonctionnementMateriel": "Mauvais fonctionnement du matériel",
    "arretLogicielCritique": "Arrêt d'un logiciel critique dû à un bug",
    "degradationPerformance": "Dégradation de performance",
    "volEquipement": "Vol d'équipement informatique à l'intérieur des locaux",
    "detectionVirus": "Détection d’un virus",
    "volSupportAmovible": "Vol ou altération d'un support amovible",
    "antivirusDesactive": "Antivirus désactivé ou non à jour",
    "volDocuments": "Vol de documents dans des bureaux",
    "accesNonAutorise": "Accès non autorisé",
    "volOrdinateurPortable": "Vol d’un ordinateur portable",
    "divulgationDonnees": "Divulgation des données confidentielles",
    "degradationServiceInternet": "Dégradation du service Internet",
    "modificationFichier": "Modification accidentelle ou malveillante d'un fichier partagé par un utilisateur non autorisé",
    "menaceHarcelement": "Menace / harcèlement par courriel",
    "autreIncident": "Autre (fournir les renseignements supplémentaires)",
    "dgrhContactee": "En cas d’activités illégales, la DGRH a-t-elle été contactée ?",
    "donneesCompromises": "Des données sensibles ou critiques ont-elles été compromises ?",
    "detailsDonneesCompromises": "Détails"
}

const FIELDS_THAT_DO_NOT_HAVE_DETAILS = [
    "serveur",
    "systemeExploitation",
    "posteTravail",
    "messagerie",
    "ordinateurPortable",
    "logicielBureautique",
    "imprimante",
    "logicielTechnique",
    "controleAcces",
    "perteObjet",
    "fraude",
    "mauvaisFonctionnementSysteme",
    "erreurSaisie",
    "mauvaisFonctionnementLogiciel",
    "accidentElectrique",
    "mauvaisFonctionnementMateriel",
    "arretLogicielCritique",
    "degradationPerformance",
    "volEquipement",
    "detectionVirus",
    "volSupportAmovible",
    "antivirusDesactive",
    "volDocuments",
    "accesNonAutorise",
    "volOrdinateurPortable",
    "divulgationDonnees",
    "degradationServiceInternet",
    "modificationFichier",
    "menaceHarcelement",
    "autreIncident"
]