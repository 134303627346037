import { html } from "htm/preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { useInput } from "./core";
import { Session, SessionContext, useSession } from "./store";
import { ToastContainer, toast } from 'react-toastify';
import { route } from "preact-router";




export type RegisterMsg = login.Msg
  | { type: 'SetPassword2' }


export module login {


  export type Msg = { type: 'SetEmail', value: string }
    | { type: 'SetPassword', value: string }
    | { type: 'Submit' };

  export class Model {
    email = '';
    password = '';
    emailError?: string;
    passwordError?: string;

    validate() {

    }

  }




  export function update(model: Model, msg: Msg) {
    switch (msg.type) {
      case 'SetEmail':
        model.email = msg.value;
        break;
      case 'SetPassword':
        model.password = msg.value;
        break;
      case 'Submit':
        throw new Error("Submit Login");
    }
  }


  export function view(model: Model, dispatch: any) {
    return html`<div class="container" data-layout="container">
  <div class="row flex-center min-vh-100 py-6">
    <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4"><a class="d-flex flex-center mb-4" href="/"><img
          class="mr-2" src="../../assets/img/illustrations/falcon.png" alt="" width="58" /><span
          class="font-sans-serif font-weight-bolder fs-5 d-inline-block">Finances</span></a>
      <div class="card">
        <div class="card-body p-4 p-sm-5">
          <div class="row flex-between-center mb-2">
            <div class="col-auto">
              <h5>Log in</h5>
            </div>
            <div class="col-auto fs--1 text-600"><span class="mb-0 undefined">or</span> <span>
                <a href="/register">Créer un compte
                </a></span></div>
          </div>
          <${LoginForm} />
        </div>
      </div>
    </div>
  </div>
</div>`;
  }



  export function ViewSplit() {
    return html`
      <!-- ===============================================-->
      <!--    Main Content-->
      <!-- ===============================================-->
      <main class="main" id="top">
        <div class="container-fluid">
          <script>
            var isFluid = JSON.parse(localStorage.getItem('isFluid'));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                if (isFluid) {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  var container = document.querySelector('[data-layout]');
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  container.classList.remove('container');
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  container.classList.add('container-fluid');
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
          </script>
          <div class="row min-vh-100 bg-100">
            <div class="col-6 d-none d-lg-block position-relative">
              <div class="bg-holder" style="background-image:url(/assets/img/generic/14.jpg);background-position: 50% 20%;">
              </div>
              <!--/.bg-holder-->
            </div>
            <div class="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
              <div class="row justify-content-center g-0">
                <div class="col-lg-9 col-xl-8 col-xxl-6">
                  <div class="card">
                    <div class="card-header bg-circle-shape text-center p-2"><a
                        class="font-sans-serif font-weight-bolder fs-4 z-index-1 position-relative link-light"
                        href="/">Finances</a></div>
                    <div class="card-body p-4">
                      <div class="row flex-between-center">
                        <div class="col-auto">
                          <h3>Connexion</h3>
                        </div>
                        <div class="col-auto fs--1 text-600"><span class="mb-0 font-weight-semi-bold">Nouveau?
                          </span>
                          <span><a href="/register"> Créer un compte</a></span></div>
                      </div>
                      <${LoginForm} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main><!-- ===============================================-->
 `;
  }



  export function LoginForm() {

    const [state, setState] = useState('initial');
    const [email, setEmail] = useInput('');
    const [password, setPassword] = useInput('');

    const [session, dispatch] = useSession();


    if (session.token) {
      return route('/');
    }


    const onSubmit = async (ev: Event) => {
      // Handler
      ev.preventDefault();
      ev.stopPropagation();
      ev.stopImmediatePropagation();

      setState('validated');

      if ((ev.target as any).checkValidity()) {
        setState('waiting');
        if (await SessionContext.login({ email, password }, dispatch)) {
          toast.success(`Logged in as ${email}`);
          route(session.homepage);
        } else {
          setState('initial');
          setPassword({ target: { value: '' } });
          toast.error('Verifiez vos credentiels!')
        }
      }
    };

    return html`<form onsubmit=${onSubmit} class="needs-validation ${state == 'validated' ? 'was-validated' : ''}" novalidate>
  <div class="mb-3">
    <label class="form-label" for="split-login-email">Identifiant Unique</label>
    <input onchange=${setEmail} class="form-control" id="split-login-email" type="text" required /></div>
  <div class="mb-3">
    <div class="d-flex justify-content-between">
      <label class="form-label" for="split-login-password">Mot de passe</label>
      <a class="fs--1" href="/forgot-password">Mot de passe oublié ?</a></div>
    <input onchange=${setPassword} value=${password} class="form-control" id="split-login-password" type="password"
      pattern=".{6,}" required />
    <div class="invalid-feedback">At least 6 characters</div>
  </div>
  <div class="form-check mb-0"><input class="form-check-input" type="checkbox" id="split-checkbox" /><label
      class="form-check-label" for="split-checkbox">Remember me</label></div>
  <div class="mb-3"><button class="btn btn-primary btn-block mt-3  ${state == 'waiting' ? 'disabled' : ''} "
      type="submit" name="submit">Connexion</button></div>
</form>`;
  }

}


export module register {


  export function RegisterForm() {

    const [errors, setErrors] = useState({ confirmPassword: '', credentials: '' });

    const [state, setState] = useState('initial');
    const [email, setEmail] = useInput('');
    const [password, setPassword] = useInput('');
    const [password2, setPassword2] = useInput('');

    const [firstName, setFirstName] = useInput('');
    const [lastName, setLastName] = useInput('');

    const [session, dispatch] = useSession();


    if (session.token) {
      return route('/');
    }

    const onSubmit = async (ev: Event) => {
      // Handler
      ev.preventDefault();
      ev.stopPropagation();

      setState('validated');


      if ((ev.target as any).checkValidity()) {
        setState('waiting');
        if (await SessionContext.register({ organization: { name: 'test' }, user: { email, password, firstName, lastName } }, dispatch)) {
          toast.success(`Logged in as ${email}`);
          route(session.homepage);
        } else {
          setState('initial');
          toast.error('Verifiez vos credentiels!')
        }
      }
    };


    return html`<form onsubmit=${onSubmit} class="needs-validation ${state == 'validated' ? 'was-validated' : ''}" novalidate>
  <div class="row gx-3">
    <div class="mb-3 col-sm-6">
      <label class="form-label" for="first-name">Prénom</label>
      <input onchange=${setFirstName} value=${firstName} class="form-control" type="text" id="first-name"
        pattern=".{3,}" required />
      <div class="invalid-feedback">Au moins 3 caractères</div>
    </div>
    <div class="mb-3 col-sm-6">
      <label class="form-label" for="last-name">Nom</label>
      <input onchange=${setLastName} value=${lastName} class="form-control" type="text" id="last-name" pattern=".{3,}"
        required />
      <div class="invalid-feedback">Au moins 3 caractères</div>
    </div>
  </div>
  <div class="mb-3">
    <label class="form-label" for="split-email">Addresse Email</label>
    <input onchange=${setEmail} value=${email} class="form-control" type="email" id="split-email" required />
    <div class="invalid-feedback">Doit être une addresse email valide</div>
  </div>
  <div class="row gx-3">
    <div class="mb-3 col-sm-6">
      <label class="form-label" for="split-password">Mot de passe</label>
      <input onchange=${setPassword} value=${password} class="form-control" type="password" id="split-password"
        pattern=".{8,}" required />
      <div class="invalid-feedback">Au moins 8 caractères</div>
    </div>
    <div class="mb-3 col-sm-6">
      <label class="form-label" for="split-confirm-password">Confirmer mot de passe</label>
      <input onchange=${setPassword2} value=${password2} class="form-control" type="password"
        id="split-confirm-password" pattern=${password} required />
      <div class="invalid-feedback">Mot de passe different</div>
    </div>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="cover-register-checkbox" />
    <label class="form-label" for="cover-register-checkbox">I accept the <a href="#!">terms </a>and <a href="#!">privacy
        policy</a></label>
  </div>
  <div class="mb-3">
    <button class="btn btn-primary btn-block mt-3" type="submit" name="submit">S'inscrire</button>
  </div>
</form>`;
  }




  export function ViewSplit() {


    return html`<main class="main" id="top">
  <div class="container-fluid">
    <div class="row min-vh-100 bg-100">
      <div class="col-6 d-none d-lg-block position-relative">
        <div class="bg-holder" style="background-image:url(/assets/img/generic/19.jpg);">
        </div>
        <!--/.bg-holder-->

      </div>
      <div class="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
        <div class="row justify-content-center g-0">
          <div class="col-lg-9 col-xl-8 col-xxl-6">
            <div class="card">
              <div class="card-header bg-circle-shape text-center p-2"><a
                  class="font-sans-serif font-weight-bolder fs-4 z-index-1 position-relative link-light"
                  href="/">Finances</a></div>
              <div class="card-body p-4">
                <div class="row flex-between-center">
                  <div class="col-auto">
                    <h3>S'inscrire</h3>
                  </div>
                  <div class="col-auto fs--1 text-600"><span class="mb-0 font-weight-semi-bold">Déjà un utilisateur?
                    </span>
                    <span><a href="/login"> Connexion</a></span></div>
                </div>
                <${RegisterForm} />
                <div class="position-relative mt-4">
                  <hr class="bg-300" />
                  <div
                    class="position-absolute top-50 left-50 translate-middle px-3 bg-white font-sans-serif fs--1 text-500 text-nowrap">
                    or register with</div>
                </div>
                <div class="row g-2 mt-2">
                  <div class="col-sm-6"><a class="btn btn-outline-google-plus btn-sm btn-block" href="#"><span
                        class="fab fa-google-plus-g mr-2" data-fa-transform="grow-8"></span> google</a></div>
                  <div class="col-sm-6"><a class="btn btn-outline-facebook btn-sm btn-block" href="#"><span
                        class="fab fa-facebook-square mr-2" data-fa-transform="grow-8"></span> facebook</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>`;
  }

}


export function ForgotPassword() {

  const [email, setEmail] = useInput('');
  const [state, setState] = useState('initial');

  const [session, dispatch] = useSession();


  const onSubmit = async (ev: Event) => {
    // Handler
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();

    setState('validated');

    if ((ev.target as any).checkValidity()) {
      setState('waiting');
      if (await SessionContext.forgotPassword({ email })) {
        toast.success(`Logged in as ${email}`);
        route(session.homepage);
      } else {
        setState('initial');
        // setPassword({ target: { value: '' } });
        toast.error('Verifiez vos credentiels!')
      }
    }
  };

  return html`<main class="main" id="top">
  <div class="container-fluid">
    <div class="row min-vh-100 bg-100">
      <div class="col-6 d-none d-lg-block position-relative">
        <div class="bg-holder overlay"
          style="background-image:url(../../assets/img/generic/17.jpg);background-position: 50% 76%;">
        </div>
        <!--/.bg-holder-->

      </div>
      <div class="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
        <div class="row justify-content-center g-0">
          <div class="col-lg-9 col-xl-8 col-xxl-6">
            <div class="card">
              <div class="card-header bg-circle-shape text-center p-2"><a
                  class="font-sans-serif font-weight-bolder fs-4 z-index-1 position-relative link-light"
                  href="/">Finances</a></div>
              <div class="card-body p-4">
                <div class="text-center">
                  <h4 class="mb-0"> Forgot your password?</h4><small>Enter your email and we'll send you a reset
                    link.</small>
                  <form onsubmit=${onSubmit}
                    class="mb-3 mt-4 needs-validation ${state == 'validated' ? 'was-validated' : ''}" novalidate>
                    <input class="form-control" value=${email} onchange=${setEmail} type="email"
                      placeholder="Email address" required />
                    <div class="mb-3">
                      <button class="btn btn-primary btn-block mt-3" type="submit" name="submit">Send reset
                        link</button>
                    </div>
                  </form>
                  <a class="fs--1" href="/login">Login</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>`;
}


export function ResetPassword({ token }: { token: string }) {

  const [password, setPassword] = useInput('');
  const [state, setState] = useState('initial');


  const onSubmit = async (ev: Event) => {
    // Handler
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();

    setState('validated');

    if ((ev.target as any).checkValidity()) {
      setState('waiting');
      if (await SessionContext.resetPassword({ password, token })) {
        toast.success(`Password reset`);
        route('/login');
      } else {
        setState('initial');
        // setPassword({ target: { value: '' } });
        toast.error('Verifiez vos credentiels!')
      }
    }
  };

  return html`<main class="main" id="top">
  <div class="container-fluid">
    <div class="row min-vh-100 bg-100">
      <div class="col-6 d-none d-lg-block position-relative">
        <div class="bg-holder overlay"
          style="background-image:url(../../assets/img/generic/17.jpg);background-position: 50% 76%;">
        </div>
        <!--/.bg-holder-->

      </div>
      <div class="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
        <div class="row justify-content-center g-0">
          <div class="col-lg-9 col-xl-8 col-xxl-6">
            <div class="card">
              <div class="card-header bg-circle-shape text-center p-2"><a
                  class="font-sans-serif font-weight-bolder fs-4 z-index-1 position-relative link-light"
                  href="/">Finances</a></div>
              <div class="card-body p-4">
                <div class="text-center">
                  <h4 class="mb-0">Enter your new password?</h4><small>Enter your new password.</small>
                  <form onsubmit=${onSubmit}
                    class="mb-3 mt-4 needs-validation ${state == 'validated' ? 'was-validated' : ''}" novalidate>
                    <input class="form-control" value=${password} onchange=${setPassword} type="password"
                      placeholder="New Password" required />
                    <div class="mb-3">
                      <button class="btn btn-primary btn-block mt-3" type="submit" name="submit">Reset Password</button>
                    </div>
                  </form>
                  <a class="fs--1" href="/login">Login</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>`;
}



export function Logout() {


  const [session, dispatch] = useSession();

  if (session.token) {
    dispatch({ type: 'logout' });
  }

  return html`<main class="main" id="top">
  <div class="container-fluid">
    <div class="row min-vh-100 bg-100">
      <div class="col-6 d-none d-lg-block position-relative">
        <div class="bg-holder" style="background-image:url(../../assets/img/generic/19.jpg);">
        </div>
        <!--/.bg-holder-->

      </div>
      <div class="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
        <div class="row justify-content-center g-0">
          <div class="col-lg-9 col-xl-8 col-xxl-6">
            <div class="card">
              <div class="card-header bg-circle-shape text-center p-2"><a
                  class="font-sans-serif font-weight-bolder fs-4 z-index-1 position-relative link-light"
                  href="/">Finances</a></div>
              <div class="card-body p-4">
                <div class="text-center"><img class="d-block mx-auto mb-4"
                    src="../../assets/img/illustrations/rocket.png" alt="shield" width="70" />
                  <h3>See you again!</h3>
                  <p>Thanks for using Finances. You are <br />now successfully signed out.</p><a
                    class="btn btn-primary btn-sm mt-3" href="/login"><span class="fas fa-chevron-left mr-1"
                      data-fa-transform="shrink-4 down-1"></span>Return to Login</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>`;
}