import { html } from "htm/preact";
import { generateId, getPage, Pagination, t, useInput } from "./core";
import { useContext, useEffect, useRef, useState } from "preact/hooks";

import Select from 'react-select'
import { citites, socialSecurityOptions } from "./data";

import { Api, debounce, filesStore, getDownloadUrl, merge, searchObjects, SessionContext, SFile, state, useSession } from "./store";
import { Billable } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreatableSelect from 'react-select/creatable';
import { Drive } from "./products";
import AsyncCreatableSelect from "react-select/async-creatable";

/*
const offices: { id: any, name: string, parent?: string, code: any }[] = [
    {
        "id": 85,
        "name": "DGE",
        "parent": undefined,
        "code": 6500
    },
    {
        "id": 95,
        "name": "C.C  ARIANA",
        "parent": "CRCI Ariana",
        "code": 6060
    },
    {
        "id": 96,
        "name": "C.C  ARIANA (Cellule Verification)",
        "parent": "CRCI Ariana",
        "code": 63060
    },
    {
        "id": 97,
        "name": "C.C  ARIANA (Cellule Encadrement)",
        "parent": "CRCI Ariana",
        "code": 64060
    },
    {
        "id": 98,
        "name": "C.C  ARIANA (Cellule Avantages Fiscaux)",
        "parent": "CRCI Ariana",
        "code": 65060
    },
    {
        "id": 99,
        "name": "C.C  ARIANA (Cellule Enregistrement)",
        "parent": "CRCI Ariana",
        "code": 66060
    },
    {
        "id": 100,
        "name": "C.C  ARIANA (Cellule Contentieux)",
        "parent": "CRCI Ariana",
        "code": 67060
    },
    {
        "id": 101,
        "name": "C.C  ARIANA (Cellule AAF)",
        "parent": "CRCI Ariana",
        "code": 68060
    },
    {
        "id": 102,
        "name": "C.C  ARIANA (Atef touati)",
        "parent": "CRCI Ariana",
        "code": 69060
    },
    {
        "id": 103,
        "name": "B.C  ARIANA VILLE",
        "parent": "CRCI Ariana",
        "code": 6061
    },
    {
        "id": 104,
        "name": "B.C  ARIANA VILLE P2",
        "parent": "CRCI Ariana",
        "code": 62061
    },
    {
        "id": 105,
        "name": "B.C  ARIANA VILLE P3",
        "parent": "CRCI Ariana",
        "code": 63061
    },
    {
        "id": 106,
        "name": "B.C  MENZAH VI",
        "parent": "CRCI Ariana",
        "code": 6062
    },
    {
        "id": 107,
        "name": "B.C  MENZAH VI P2",
        "parent": "CRCI Ariana",
        "code": 62062
    },
    {
        "id": 108,
        "name": "B.C  MENZAH VI P3",
        "parent": "CRCI Ariana",
        "code": 63062
    },
    {
        "id": 109,
        "name": "B.C  ETTADHAMEN",
        "parent": "CRCI Ariana",
        "code": 6065
    },
    {
        "id": 110,
        "name": "B.C  ETTADHAMEN P2",
        "parent": "CRCI Ariana",
        "code": 62065
    },
    {
        "id": 111,
        "name": "B.C  KALAAT EL ANDALOUS",
        "parent": "CRCI Ariana",
        "code": 6067
    },
    {
        "id": 112,
        "name": "B.C  KALAAT EL ANDALOUS P2",
        "parent": "CRCI Ariana",
        "code": 62067
    },
    {
        "id": 113,
        "name": "B.C  BORJ LOUZIR ARIANA",
        "parent": "CRCI Ariana",
        "code": 6068
    },
    {
        "id": 114,
        "name": "B.C  BORJ LOUZIR ARIANA P2",
        "parent": "CRCI Ariana",
        "code": 62068
    },
    {
        "id": 115,
        "name": "B.C  CITE ADMINISTRATIVE ARIANA",
        "parent": "CRCI Ariana",
        "code": 6069
    },
    {
        "id": 116,
        "name": "B.C  CITE ADMINISTRATIVE ARIANA P2",
        "parent": "CRCI Ariana",
        "code": 62069
    },
    {
        "id": 117,
        "name": "B.C  CITE ADMINISTRATIVE ARIANA P3",
        "parent": "CRCI Ariana",
        "code": 63069
    },
    {
        "id": 118,
        "name": "B.C  RAOUED",
        "parent": "CRCI Ariana",
        "code": 6085
    },
    {
        "id": 119,
        "name": "B.C  RAOUED P2",
        "parent": "CRCI Ariana",
        "code": 62085
    },
    {
        "id": 120,
        "name": "C.C  BEN AROUS",
        "parent": "CRCI Ben Arous",
        "code": 6040
    },
    {
        "id": 121,
        "name": "C.C  BEN AROUS (Cellule Restitution)",
        "parent": "CRCI Ben Arous",
        "code": 62040
    },
    {
        "id": 122,
        "name": "C.C  BEN AROUS (Cellule Verification)",
        "parent": "CRCI Ben Arous",
        "code": 63040
    },
    {
        "id": 123,
        "name": "C.C  BEN AROUS (Cellule Encadrement)",
        "parent": "CRCI Ben Arous",
        "code": 64040
    },
    {
        "id": 124,
        "name": "C.C  BEN AROUS (Cellule Avantages Fiscaux)",
        "parent": "CRCI Ben Arous",
        "code": 65040
    },
    {
        "id": 125,
        "name": "C.C  BEN AROUS (Cellule Enregistrement)",
        "parent": "CRCI Ben Arous",
        "code": 66040
    },
    {
        "id": 126,
        "name": "C.C  BEN AROUS (Cellule Contentieux)",
        "parent": "CRCI Ben Arous",
        "code": 67040
    },
    {
        "id": 127,
        "name": "C.C  BEN AROUS (Cellule AAF)",
        "parent": "CRCI Ben Arous",
        "code": 68040
    },
    {
        "id": 128,
        "name": "B.C  BEN AROUS",
        "parent": "CRCI Ben Arous",
        "code": 6041
    },
    {
        "id": 129,
        "name": "B.C  BEN AROUS P2",
        "parent": "CRCI Ben Arous",
        "code": 62041
    },
    {
        "id": 130,
        "name": "B.C  BEN AROUS P3",
        "parent": "CRCI Ben Arous",
        "code": 63041
    },
    {
        "id": 131,
        "name": "B.C  HAMMAM LIF",
        "parent": "CRCI Ben Arous",
        "code": 6042
    },
    {
        "id": 132,
        "name": "B.C  HAMMAM LIF P2",
        "parent": "CRCI Ben Arous",
        "code": 62042
    },
    {
        "id": 133,
        "name": "B.C  RADES",
        "parent": "CRCI Ben Arous",
        "code": 6043
    },
    {
        "id": 134,
        "name": "B.C  RADES P2",
        "parent": "CRCI Ben Arous",
        "code": 62043
    },
    {
        "id": 135,
        "name": "B.C  MEGRINE",
        "parent": "CRCI Ben Arous",
        "code": 6044
    },
    {
        "id": 136,
        "name": "B.C MEGRINE P2",
        "parent": "CRCI Ben Arous",
        "code": 62044
    },
    {
        "id": 137,
        "name": "B.C MEGRINE P3",
        "parent": "CRCI Ben Arous",
        "code": 63044
    },
    {
        "id": 138,
        "name": "B.C FOUCHANA",
        "parent": "CRCI Ben Arous",
        "code": 6045
    },
    {
        "id": 139,
        "name": "B.C FOUCHANA P2",
        "parent": "CRCI Ben Arous",
        "code": 62045
    },
    {
        "id": 140,
        "name": "B.C  BOUMHEL",
        "parent": "CRCI Ben Arous",
        "code": 6046
    },
    {
        "id": 141,
        "name": "B.C BOUMHEL P2",
        "parent": "CRCI Ben Arous",
        "code": 62046
    },
    {
        "id": 142,
        "name": "B.C  EL MOUROUJ",
        "parent": "CRCI Ben Arous",
        "code": 6047
    },
    {
        "id": 143,
        "name": "B.C  EL MOUROUJ P2",
        "parent": "CRCI Ben Arous",
        "code": 62047
    },
    {
        "id": 144,
        "name": "B.C  MORNAG",
        "parent": "CRCI Ben Arous",
        "code": 6048
    },
    {
        "id": 145,
        "name": "B.C  MORNAG P2",
        "parent": "CRCI Ben Arous",
        "code": 62048
    },
    {
        "id": 146,
        "name": "B.C  YASMINET",
        "parent": "CRCI Ben Arous",
        "code": 6049
    },
    {
        "id": 147,
        "name": "B.C  YASSMINET P2",
        "parent": "CRCI Ben Arous",
        "code": 62049
    },
    {
        "id": 148,
        "name": "B.C  HAMMAM ECHATT P2",
        "parent": "CRCI Ben Arous",
        "code": 62081
    },
    {
        "id": 149,
        "name": "B.C. HAMMAM ECHAT",
        "parent": "CRCI Ben Arous",
        "code": 6081
    },
    {
        "id": 150,
        "name": "C.C  Bizerte",
        "parent": "CRCI Bizerte",
        "code": 6030
    },
    {
        "id": 151,
        "name": "C.C  Bizerte (Cellule Restitution)",
        "parent": "CRCI Bizerte",
        "code": 62030
    },
    {
        "id": 152,
        "name": "C.C  Bizerte (Cellule Verification)",
        "parent": "CRCI Bizerte",
        "code": 63030
    },
    {
        "id": 153,
        "name": "C.C  Bizerte (Cellule Encadrement)",
        "parent": "CRCI Bizerte",
        "code": 64030
    },
    {
        "id": 154,
        "name": "C.C  Bizerte (Avantages Fiscaux)",
        "parent": "CRCI Bizerte",
        "code": 65030
    },
    {
        "id": 155,
        "name": "C.C  Bizerte (Cellule Enregistrement)",
        "parent": "CRCI Bizerte",
        "code": 66030
    },
    {
        "id": 156,
        "name": "C.C  Bizerte (Cellule Contentieux)",
        "parent": "CRCI Bizerte",
        "code": 67030
    },
    {
        "id": 157,
        "name": "C.C  Bizerte (Cellule AAF)",
        "parent": "CRCI Bizerte",
        "code": 68030
    },
    {
        "id": 158,
        "name": "B.C  MATEUR",
        "parent": "CRCI Bizerte",
        "code": 6031
    },
    {
        "id": 159,
        "name": "B.C  MATEUR P2",
        "parent": "CRCI Bizerte",
        "code": 62031
    },
    {
        "id": 160,
        "name": "B.C  RAS-DJEBEL",
        "parent": "CRCI Bizerte",
        "code": 6032
    },
    {
        "id": 161,
        "name": "B.C  MENZEL BOURGUIBA",
        "parent": "CRCI Bizerte",
        "code": 6033
    },
    {
        "id": 162,
        "name": "B.C  MENZEL-BOURGUIBA P2",
        "parent": "CRCI Bizerte",
        "code": 62033
    },
    {
        "id": 163,
        "name": "B.C  RUE LA GOULETTE",
        "parent": "CRCI Bizerte",
        "code": 6034
    },
    {
        "id": 164,
        "name": "B.C  RUE LA GOULETTE P2",
        "parent": "CRCI Bizerte",
        "code": 62034
    },
    {
        "id": 165,
        "name": "B.C  TAREK IBN ZIED",
        "parent": "CRCI Bizerte",
        "code": 6035
    },
    {
        "id": 166,
        "name": "B.C  TAREK IBN ZIED P2",
        "parent": "CRCI Bizerte",
        "code": 62035
    },
    {
        "id": 167,
        "name": "B.C  TAREK IBN ZIED P3",
        "parent": "CRCI Bizerte",
        "code": 63035
    },
    {
        "id": 168,
        "name": "B.C  API Bizerte P2",
        "parent": "CRCI Bizerte",
        "code": 62036
    },
    {
        "id": 169,
        "name": "B.C  MENZEL JEMIL",
        "parent": "CRCI Bizerte",
        "code": 6037
    },
    {
        "id": 170,
        "name": "B.C  MENZEL JEMIL P2",
        "parent": "CRCI Bizerte",
        "code": 62037
    },
    {
        "id": 171,
        "name": "B.C  EL ALIA",
        "parent": "CRCI Bizerte",
        "code": 6039
    },
    {
        "id": 172,
        "name": "B.C  EL ALIA P2",
        "parent": "CRCI Bizerte",
        "code": 62039
    },
    {
        "id": 173,
        "name": "C.C  DE BEJA",
        "parent": "CRCI Beja",
        "code": 6050
    },
    {
        "id": 174,
        "name": "CC Beja (Cellule Restitution)",
        "parent": "CRCI Beja",
        "code": 62050
    },
    {
        "id": 175,
        "name": "C.C  DE BEJA (Cellule de verification)",
        "parent": "CRCI Beja",
        "code": 62050
    },
    {
        "id": 176,
        "name": "C.C  DE BEJA (Cellule Encadrement)",
        "parent": "CRCI Beja",
        "code": 63050
    },
    {
        "id": 177,
        "name": "C.C  DE BEJA (Avantages Fiscaux)",
        "parent": "CRCI Beja",
        "code": 64050
    },
    {
        "id": 178,
        "name": "CC Beja (Cellule Enregistrement)",
        "parent": "CRCI Beja",
        "code": 66050
    },
    {
        "id": 179,
        "name": "CC Beja (Cellule Contentieux)",
        "parent": "CRCI Beja",
        "code": 67050
    },
    {
        "id": 180,
        "name": "CC Beja (Cellule  AAF)",
        "parent": "CRCI Beja",
        "code": 68050
    },
    {
        "id": 181,
        "name": "B.C  MJEZ EL BEB",
        "parent": "CRCI Beja",
        "code": 6051
    },
    {
        "id": 182,
        "name": "B.C  MJEZ EL BEB P2",
        "parent": "CRCI Beja",
        "code": 62051
    },
    {
        "id": 183,
        "name": "B.C  BEJA-VILLE",
        "parent": "CRCI Beja",
        "code": 6052
    },
    {
        "id": 184,
        "name": "B.C  BEJA-VILLE P2",
        "parent": "CRCI Beja",
        "code": 62052
    },
    {
        "id": 185,
        "name": "B.C  BEJA-VILLE P3",
        "parent": "CRCI Beja",
        "code": 63052
    },
    {
        "id": 186,
        "name": "B.C  TEBOURSOUK",
        "parent": "CRCI Beja",
        "code": 6053
    },
    {
        "id": 187,
        "name": "B.C  TEBOURSOUK P2",
        "parent": "CRCI Beja",
        "code": 62053
    },
    {
        "id": 188,
        "name": "B.C API Beja",
        "parent": "CRCI Beja",
        "code": 62054
    },
    {
        "id": 189,
        "name": "B.C  NEFZA",
        "parent": "CRCI Beja",
        "code": 6055
    },
    {
        "id": 197,
        "name": "C.C  DE GABES",
        "parent": "CRCI Gabes",
        "code": 6170
    },
    {
        "id": 198,
        "name": "C.C  DE GABES (Chef centre)",
        "parent": "CRCI Gabes",
        "code": 62170
    },
    {
        "id": 199,
        "name": "C.C  DE GABES (Cellule de restitution)",
        "parent": "CRCI Gabes",
        "code": 63170
    },
    {
        "id": 200,
        "name": "C.C  DE GABES (Cellule de verification)",
        "parent": "CRCI Gabes",
        "code": 64170
    },
    {
        "id": 201,
        "name": "C.C  DE GABES (Cellule d'encadrement)",
        "parent": "CRCI Gabes",
        "code": 65170
    },
    {
        "id": 202,
        "name": "C.C  DE GABES (Avantages fiscaux)",
        "parent": "CRCI Gabes",
        "code": 66170
    },
    {
        "id": 203,
        "name": "CC Gabes C. Enreg (66170)",
        "parent": "CRCI Gabes",
        "code": 66170
    },
    {
        "id": 204,
        "name": "CC Gabes Contentieux (67170)",
        "parent": "CRCI Gabes",
        "code": 67170
    },
    {
        "id": 205,
        "name": "CC Gabes C. AAF (68170)",
        "parent": "CRCI Gabes",
        "code": 68170
    },
    {
        "id": 206,
        "name": "B.C  GABES-NORD (6172)",
        "parent": "CRCI Gabes",
        "code": 6172
    },
    {
        "id": 207,
        "name": "B.C  GABES-SUD (6173)",
        "parent": "CRCI Gabes",
        "code": 6173
    },
    {
        "id": 208,
        "name": "B.C  EL HAMMA P2 (62177)",
        "parent": "CRCI Gabes",
        "code": 62177
    },
    {
        "id": 209,
        "name": "B.C  MARETH P3 (63174)",
        "parent": "CRCI Gabes",
        "code": 63174
    },
    {
        "id": 210,
        "name": "175 B.C  GABES OUEST (6175)",
        "parent": "CRCI Gabes",
        "code": 6175
    },
    {
        "id": 211,
        "name": "Fadia Nasfi (IP Communicator)",
        "parent": "CRCI Gabes",
        "code": 68170
    },
    {
        "id": 212,
        "name": "B.C  GABES-NORD",
        "parent": "CRCI Gabes",
        "code": 62172
    },
    {
        "id": 213,
        "name": "B.C  GABES-SUD",
        "parent": "CRCI Gabes",
        "code": 62173
    },
    {
        "id": 214,
        "name": "B.C  MARETH",
        "parent": "CRCI Gabes",
        "code": 62174
    },
    {
        "id": 215,
        "name": "B.C  Gabes Ouest",
        "parent": "CRCI Gabes",
        "code": 62175
    },
    {
        "id": 216,
        "name": "B.C  API Gabes",
        "parent": "CRCI Gabes",
        "code": 62176
    },
    {
        "id": 217,
        "name": "B.C  GABES-NORD",
        "parent": "CRCI Gabes",
        "code": 6172
    },
    {
        "id": 218,
        "name": "B.C  GABES-SUD",
        "parent": "CRCI Gabes",
        "code": 6173
    },
    {
        "id": 219,
        "name": "B.C  MARETH",
        "parent": "CRCI Gabes",
        "code": 6174
    },
    {
        "id": 220,
        "name": "B.C  GHANNOUCHE",
        "parent": "CRCI Gabes",
        "code": 6175
    },
    {
        "id": 221,
        "name": "B.C  EL HAMMA",
        "parent": "CRCI Gabes",
        "code": 6177
    },
    {
        "id": 222,
        "name": "C.C  DE GAFSA",
        "parent": "CRCI Gafsa",
        "code": 6130
    },
    {
        "id": 223,
        "name": "C.C  DE GAFSA (Cellule de verification)",
        "parent": "CRCI Gafsa",
        "code": 62130
    },
    {
        "id": 224,
        "name": "C.C  DE GAFSA (Cellule d'encadrement)",
        "parent": "CRCI Gafsa",
        "code": 63130
    },
    {
        "id": 225,
        "name": "C.C  DE GAFSA (Avantages fiscaux)",
        "parent": "CRCI Gafsa",
        "code": 64130
    },
    {
        "id": 226,
        "name": "C.C  DE GAFSA (Audio Conference)",
        "parent": "CRCI Gafsa",
        "code": 65130
    },
    {
        "id": 227,
        "name": "CC Gafsa C. Restit (62130)",
        "parent": "CRCI Gafsa",
        "code": 62130
    },
    {
        "id": 228,
        "name": "CC Gafsa C. Enreg (66130)",
        "parent": "CRCI Gafsa",
        "code": 66130
    },
    {
        "id": 229,
        "name": "CC Gafsa Contentieux (67130)",
        "parent": "CRCI Gafsa",
        "code": 67130
    },
    {
        "id": 230,
        "name": "CC Gafsa C. AAF (68130)",
        "parent": "CRCI Gafsa",
        "code": 68130
    },
    {
        "id": 231,
        "name": "B.C  METLAOUI (6133)",
        "parent": "CRCI Gafsa",
        "code": 6133
    },
    {
        "id": 232,
        "name": "B.C  GAFSA GSAR P2 (62132)",
        "parent": "CRCI Gafsa",
        "code": 62132
    },
    {
        "id": 233,
        "name": "B.C  GAFSA-VILLE P2 (62134)",
        "parent": "CRCI Gafsa",
        "code": 62134
    },
    {
        "id": 234,
        "name": "B.C  METLAOUI",
        "parent": "CRCI Gafsa",
        "code": 62133
    },
    {
        "id": 235,
        "name": "B.C  API GAFSA",
        "parent": "CRCI Gafsa",
        "code": 62135
    },
    {
        "id": 236,
        "name": "B.C  GAFSA GSAR",
        "parent": "CRCI Gafsa",
        "code": 6132
    },
    {
        "id": 237,
        "name": "B.C  METLAOUI",
        "parent": "CRCI Gafsa",
        "code": 6133
    },
    {
        "id": 238,
        "name": "B.C  GAFSA-VILLE",
        "parent": "CRCI Gafsa",
        "code": 6134
    },
    {
        "id": 239,
        "name": "C.C  DE JENDOUBA",
        "parent": "CRCI Jendouba",
        "code": 6070
    },
    {
        "id": 240,
        "name": "C.C  DE JENDOUBA (Cellule de verification)",
        "parent": "CRCI Jendouba",
        "code": 62070
    },
    {
        "id": 241,
        "name": "C.C  DE JENDOUBA (Cellule d'enregistrement)",
        "parent": "CRCI Jendouba",
        "code": 63070
    },
    {
        "id": 242,
        "name": "C.C  DE JENDOUBA (Cellule d'encadrement)",
        "parent": "CRCI Jendouba",
        "code": 64070
    },
    {
        "id": 243,
        "name": "C.C  DE JENDOUBA (Avantages fiscaux)",
        "parent": "CRCI Jendouba",
        "code": 65070
    },
    {
        "id": 244,
        "name": "CC Jendouba C. Restit (62070)",
        "parent": "CRCI Jendouba",
        "code": 62070
    },
    {
        "id": 245,
        "name": "CC Jendouba Contentieux (67070)",
        "parent": "CRCI Jendouba",
        "code": 67070
    },
    {
        "id": 246,
        "name": "CC jendouba C. AAF (68070)",
        "parent": "CRCI Jendouba",
        "code": 68070
    },
    {
        "id": 247,
        "name": "B.C  BOUSSALEM (6071)",
        "parent": "CRCI Jendouba",
        "code": 6071
    },
    {
        "id": 248,
        "name": "B.C  TABARKA P3 (63072)",
        "parent": "CRCI Jendouba",
        "code": 63072
    },
    {
        "id": 249,
        "name": "B.C  JENDOUBA P3 (63074)",
        "parent": "CRCI Jendouba",
        "code": 63074
    },
    {
        "id": 250,
        "name": "B.C  AIN DRAHEM P2 (62076)",
        "parent": "CRCI Jendouba",
        "code": 62076
    },
    {
        "id": 251,
        "name": "B.C  GHARDIMAOU P2 (62073)",
        "parent": "CRCI Jendouba",
        "code": 62073
    },
    {
        "id": 252,
        "name": "B.C  RUE D'ALGER JENDOUBA P2 (62075)",
        "parent": "CRCI Jendouba",
        "code": 62075
    },
    {
        "id": 253,
        "name": "B.C  TABARKA",
        "parent": "CRCI Jendouba",
        "code": 62072
    },
    {
        "id": 254,
        "name": "B.C  JENDOUBA",
        "parent": "CRCI Jendouba",
        "code": 62074
    },
    {
        "id": 255,
        "name": "B.C  API JENDOUBA",
        "parent": "CRCI Jendouba",
        "code": 62077
    },
    {
        "id": 256,
        "name": "B.C  BOUSSALEM",
        "parent": "CRCI Jendouba",
        "code": 6071
    },
    {
        "id": 257,
        "name": "B.C  TABARKA",
        "parent": "CRCI Jendouba",
        "code": 6072
    },
    {
        "id": 258,
        "name": "B.C  GHARDIMAOU",
        "parent": "CRCI Jendouba",
        "code": 6073
    },
    {
        "id": 259,
        "name": "B.C  JENDOUBA",
        "parent": "CRCI Jendouba",
        "code": 6074
    },
    {
        "id": 260,
        "name": "B.C  RUE D\"ALGER JENDOUBA",
        "parent": "CRCI Jendouba",
        "code": 6075
    },
    {
        "id": 261,
        "name": "B.C  AIN DRAHEM",
        "parent": "CRCI Jendouba",
        "code": 6076
    },
    {
        "id": 262,
        "name": "C.C  DE KAIROUAN",
        "parent": "CRCI Kairouan",
        "code": 6210
    },
    {
        "id": 263,
        "name": "C.C  DE KAIROUAN  (Cellule de restitution)",
        "parent": "CRCI Kairouan",
        "code": 62210
    },
    {
        "id": 264,
        "name": "C.C  DE KAIROUAN  (Cellule de verification)",
        "parent": "CRCI Kairouan",
        "code": 63210
    },
    {
        "id": 265,
        "name": "C.C  DE KAIROUAN Cellule d'encadrement)",
        "parent": "CRCI Kairouan",
        "code": 64210
    },
    {
        "id": 266,
        "name": "C.C  DE KAIROUAN  (Avantages fiscaux)",
        "parent": "CRCI Kairouan",
        "code": 65210
    },
    {
        "id": 267,
        "name": "C.C  DE KAIROUAN  (Audio Conference)",
        "parent": "CRCI Kairouan",
        "code": 66210
    },
    {
        "id": 268,
        "name": "CC Kairouan C. Enreg (66210)",
        "parent": "CRCI Kairouan",
        "code": 66210
    },
    {
        "id": 269,
        "name": "CC Kairouan Contentieux (67210)",
        "parent": "CRCI Kairouan",
        "code": 67210
    },
    {
        "id": 270,
        "name": "CC Kairouan C. AAF (68210)",
        "parent": "CRCI Kairouan",
        "code": 68210
    },
    {
        "id": 271,
        "name": "B.C  KAIROUAN KHAWARIZMI P3 (63211)",
        "parent": "CRCI Kairouan",
        "code": 63211
    },
    {
        "id": 272,
        "name": "B.C  BOUHAJLA P2 (62212)",
        "parent": "CRCI Kairouan",
        "code": 62212
    },
    {
        "id": 273,
        "name": "B.C  HAFFOUZ P3 (63213)",
        "parent": "CRCI Kairouan",
        "code": 63213
    },
    {
        "id": 274,
        "name": "B.C  OUESLATIA P2 (62216)",
        "parent": "CRCI Kairouan",
        "code": 62216
    },
    {
        "id": 275,
        "name": "B.C  SBIKHA P2 (62214)",
        "parent": "CRCI Kairouan",
        "code": 62214
    },
    {
        "id": 276,
        "name": "B.C  HAJEB EL AYOUN P2 (62215)",
        "parent": "CRCI Kairouan",
        "code": 62215
    },
    {
        "id": 277,
        "name": "B.C  EL MANSOURA P2 (62217)",
        "parent": "CRCI Kairouan",
        "code": 62217
    },
    {
        "id": 278,
        "name": "B.C  EL KHAOUARIZMI KAIROUAN",
        "parent": "CRCI Kairouan",
        "code": 62211
    },
    {
        "id": 279,
        "name": "B.C  HAFFOUZ",
        "parent": "CRCI Kairouan",
        "code": 62213
    },
    {
        "id": 280,
        "name": "B.C  API Kairouan",
        "parent": "CRCI Kairouan",
        "code": 62218
    },
    {
        "id": 281,
        "name": "B.C  EL KHAOUARIZMI KAIROUAN",
        "parent": "CRCI Kairouan",
        "code": 6211
    },
    {
        "id": 282,
        "name": "B.C  BOU-HAJLA",
        "parent": "CRCI Kairouan",
        "code": 6212
    },
    {
        "id": 283,
        "name": "B.C  HAFFOUZ",
        "parent": "CRCI Kairouan",
        "code": 6213
    },
    {
        "id": 284,
        "name": "B.C  SBIKHA",
        "parent": "CRCI Kairouan",
        "code": 6214
    },
    {
        "id": 285,
        "name": "B.C  HAJEB EL AYOUN",
        "parent": "CRCI Kairouan",
        "code": 6215
    },
    {
        "id": 286,
        "name": "B.C  OUESLATIA",
        "parent": "CRCI Kairouan",
        "code": 6216
    },
    {
        "id": 287,
        "name": "B.C  EL MANSOURA",
        "parent": "CRCI Kairouan",
        "code": 6217
    },
    {
        "id": 288,
        "name": "C.C  DE KEBILI",
        "parent": "CRCI Kebili",
        "code": 6180
    },
    {
        "id": 289,
        "name": "C.C  DE KEBILI (Cellule de verification)",
        "parent": "CRCI Kebili",
        "code": 62180
    },
    {
        "id": 290,
        "name": "C.C  DE KEBILI (Cellule d'enregistrement)",
        "parent": "CRCI Kebili",
        "code": 63180
    },
    {
        "id": 291,
        "name": "C.C  DE KEBILI (Cellule d'encadrement)",
        "parent": "CRCI Kebili",
        "code": 64180
    },
    {
        "id": 292,
        "name": "CC Kebili Contentieux (67180)",
        "parent": "CRCI Kebili",
        "code": 67180
    },
    {
        "id": 293,
        "name": "CC Kebili A. Fiscaux (65180)",
        "parent": "CRCI Kebili",
        "code": 65180
    },
    {
        "id": 294,
        "name": "CC Kebili (68180)",
        "parent": "CRCI Kebili",
        "code": 68180
    },
    {
        "id": 295,
        "name": "B.C  API   KEBILI",
        "parent": "CRCI Kebili",
        "code": 62183
    },
    {
        "id": 296,
        "name": "B.C  KEBILI",
        "parent": "CRCI Kebili",
        "code": 6181
    },
    {
        "id": 297,
        "name": "B.C  DOUZ",
        "parent": "CRCI Kebili",
        "code": 6182
    },
    {
        "id": 298,
        "name": "C.C  LE KEF",
        "parent": "CRCI Kef",
        "code": 6090
    },
    {
        "id": 299,
        "name": "C.C  LE KEF  (Cellule de verification)",
        "parent": "CRCI Kef",
        "code": 62090
    },
    {
        "id": 300,
        "name": "C.C  LE KEF  (Cellule d'encadrement)",
        "parent": "CRCI Kef",
        "code": 63090
    },
    {
        "id": 301,
        "name": "C.C  LE KEF  (Avantages fiscaux)",
        "parent": "CRCI Kef",
        "code": 64090
    },
    {
        "id": 302,
        "name": "CC Kef C. Restit (62090)",
        "parent": "CRCI Kef",
        "code": 62090
    },
    {
        "id": 303,
        "name": "CC Kef C. Enreg (66090)",
        "parent": "CRCI Kef",
        "code": 66090
    },
    {
        "id": 304,
        "name": "CC Kef Contentieux (67090)",
        "parent": "CRCI Kef",
        "code": 67090
    },
    {
        "id": 305,
        "name": "CC Kef C. AAF (68090)",
        "parent": "CRCI Kef",
        "code": 68090
    },
    {
        "id": 306,
        "name": "B.C  DAHMANI P2 (62091)",
        "parent": "CRCI Kef",
        "code": 62091
    },
    {
        "id": 307,
        "name": "B.C  DU KEF P2 (62092)",
        "parent": "CRCI Kef",
        "code": 62092
    },
    {
        "id": 308,
        "name": "B.C  TAJEROUINE P3 (63093)",
        "parent": "CRCI Kef",
        "code": 63093
    },
    {
        "id": 309,
        "name": "B.C  API   KEF",
        "parent": "CRCI Kef",
        "code": 62095
    },
    {
        "id": 310,
        "name": "B.C  TAJEROUINE",
        "parent": "CRCI Kef",
        "code": 62093
    },
    {
        "id": 311,
        "name": "B.C  DAHMANI",
        "parent": "CRCI Kef",
        "code": 6091
    },
    {
        "id": 312,
        "name": "B.C  DU KEF",
        "parent": "CRCI Kef",
        "code": 6092
    },
    {
        "id": 313,
        "name": "B.C  TAJEROUINE",
        "parent": "CRCI Kef",
        "code": 6093
    },
    {
        "id": 314,
        "name": "C.C  DE KASSERINE",
        "parent": "CRCI Kasserine",
        "code": 6110
    },
    {
        "id": 315,
        "name": "C.C  DE KASSERINE (Cellule de verification)",
        "parent": "CRCI Kasserine",
        "code": 62110
    },
    {
        "id": 316,
        "name": "C.C  DE KASSERINE (Cellule d'encadrement)",
        "parent": "CRCI Kasserine",
        "code": 63110
    },
    {
        "id": 317,
        "name": "C.C  DE KASSERINE (Avantages fiscaux)",
        "parent": "CRCI Kasserine",
        "code": 64110
    },
    {
        "id": 318,
        "name": "C.C  DE KASSERINE (Audio Conference)",
        "parent": "CRCI Kasserine",
        "code": 65110
    },
    {
        "id": 319,
        "name": "CC Kasserine C. Restit (62110)",
        "parent": "CRCI Kasserine",
        "code": 62110
    },
    {
        "id": 320,
        "name": "CC Kasserine C. Enreg (66110)",
        "parent": "CRCI Kasserine",
        "code": 66110
    },
    {
        "id": 321,
        "name": "CC Kasserine Contentieux (67110)",
        "parent": "CRCI Kasserine",
        "code": 67110
    },
    {
        "id": 322,
        "name": "CC Kasserine C. AAF (68110)",
        "parent": "CRCI Kasserine",
        "code": 68110
    },
    {
        "id": 323,
        "name": "B.C  THALA P2 (62112)",
        "parent": "CRCI Kasserine",
        "code": 62112
    },
    {
        "id": 324,
        "name": "B.C  SBEITLA P2 (62111)",
        "parent": "CRCI Kasserine",
        "code": 62111
    },
    {
        "id": 325,
        "name": "B.C  KASSERINE P2 (62113)",
        "parent": "CRCI Kasserine",
        "code": 62113
    },
    {
        "id": 326,
        "name": "B.C  FERIANA P2 (62114)",
        "parent": "CRCI Kasserine",
        "code": 62114
    },
    {
        "id": 327,
        "name": "Mdala Saihi (IP Communicator)",
        "parent": "CRCI Kasserine",
        "code": 66110
    },
    {
        "id": 328,
        "name": "B.C   API   KASSERINE",
        "parent": "CRCI Kasserine",
        "code": 62115
    },
    {
        "id": 329,
        "name": "B.C   Banlieu   KASSERINE",
        "parent": "CRCI Kasserine",
        "code": 61116
    },
    {
        "id": 330,
        "name": "B.C  SBEITLA",
        "parent": "CRCI Kasserine",
        "code": 6111
    },
    {
        "id": 331,
        "name": "B.C  THALA",
        "parent": "CRCI Kasserine",
        "code": 6112
    },
    {
        "id": 332,
        "name": "B.C  KASSERINE",
        "parent": "CRCI Kasserine",
        "code": 6113
    },
    {
        "id": 333,
        "name": "B.C  FERIANA",
        "parent": "CRCI Kasserine",
        "code": 6114
    },
    {
        "id": 334,
        "name": "C.C  DE MAHDIA",
        "parent": "CRCI Mahdia",
        "code": 6220
    },
    {
        "id": 335,
        "name": "C.C  DE MAHDIA (Cellule de restitution)",
        "parent": "CRCI Mahdia",
        "code": 62220
    },
    {
        "id": 336,
        "name": "C.C  DE MAHDIA (Cellule de verification)",
        "parent": "CRCI Mahdia",
        "code": 63220
    },
    {
        "id": 337,
        "name": "C.C  DE MAHDIA (Cellule d'encadrement)",
        "parent": "CRCI Mahdia",
        "code": 64220
    },
    {
        "id": 338,
        "name": "C.C  DE MAHDIA (Avantages fiscaux)",
        "parent": "CRCI Mahdia",
        "code": 65220
    },
    {
        "id": 339,
        "name": "CC Mahdia C. Enreg (66220)",
        "parent": "CRCI Mahdia",
        "code": 66220
    },
    {
        "id": 340,
        "name": "CC Mahdia Contentieux (67220)",
        "parent": "CRCI Mahdia",
        "code": 67220
    },
    {
        "id": 341,
        "name": "CC Mahdia C. AAF (68220)",
        "parent": "CRCI Mahdia",
        "code": 68220
    },
    {
        "id": 342,
        "name": "B.C  EL JEM P2 (62221)",
        "parent": "CRCI Mahdia",
        "code": 62221
    },
    {
        "id": 343,
        "name": "B.C  KSOUR-ESSEF P2 (62223)",
        "parent": "CRCI Mahdia",
        "code": 62223
    },
    {
        "id": 344,
        "name": "B.C  BOUMERDES P2 (62225)",
        "parent": "CRCI Mahdia",
        "code": 62225
    },
    {
        "id": 345,
        "name": "B.C  LA CHEBBA P2 (62224)",
        "parent": "CRCI Mahdia",
        "code": 62224
    },
    {
        "id": 346,
        "name": "B.C  EL JEM",
        "parent": "CRCI Mahdia",
        "code": 62221
    },
    {
        "id": 347,
        "name": "B.C  MAHDIA AV. ALI BELHOUANE",
        "parent": "CRCI Mahdia",
        "code": 62222
    },
    {
        "id": 348,
        "name": "B.C  MAHDIA AV. ALI BELHOUANE",
        "parent": "CRCI Mahdia",
        "code": 63222
    },
    {
        "id": 349,
        "name": "B.C  API Mahdia",
        "parent": "CRCI Mahdia",
        "code": 62227
    },
    {
        "id": 350,
        "name": "B.C  EL JEM",
        "parent": "CRCI Mahdia",
        "code": 6221
    },
    {
        "id": 351,
        "name": "B.C  ےMAHDIA AV. ALI BELHOUANE",
        "parent": "CRCI Mahdia",
        "code": 6222
    },
    {
        "id": 352,
        "name": "B.C  KSOUR-ESSEF",
        "parent": "CRCI Mahdia",
        "code": 6223
    },
    {
        "id": 353,
        "name": "B.C  LA CHEBBA",
        "parent": "CRCI Mahdia",
        "code": 6224
    },
    {
        "id": 354,
        "name": "B.C  BOUMERDES",
        "parent": "CRCI Mahdia",
        "code": 6225
    },
    {
        "id": 355,
        "name": "B.C  CHORBEN",
        "parent": "CRCI Mahdia",
        "code": 6226
    },
    {
        "id": 356,
        "name": "C.C  DE MEDNINE",
        "parent": "CRCI Medenine",
        "code": 6150
    },
    {
        "id": 357,
        "name": "C.C  DE MEDNINE (Cellule de restitution)",
        "parent": "CRCI Medenine",
        "code": 62150
    },
    {
        "id": 358,
        "name": "C.C  DE MEDNINE (Cellule de verification)",
        "parent": "CRCI Medenine",
        "code": 63150
    },
    {
        "id": 359,
        "name": "C.C  DE MEDNINE (Cellule d'encadrement)",
        "parent": "CRCI Medenine",
        "code": 64150
    },
    {
        "id": 360,
        "name": "C.C  DE MEDNINE (Avantages fiscaux)",
        "parent": "CRCI Medenine",
        "code": 65150
    },
    {
        "id": 361,
        "name": "C.C  DE MEDNINE (Audio Conference)",
        "parent": "CRCI Medenine",
        "code": 66150
    },
    {
        "id": 362,
        "name": "CC Medenine C. Enreg (66150)",
        "parent": "CRCI Medenine",
        "code": 66150
    },
    {
        "id": 363,
        "name": "CC Medenine Contentieux (67150)",
        "parent": "CRCI Medenine",
        "code": 67150
    },
    {
        "id": 364,
        "name": "CC Medenine C. AAF (68150)",
        "parent": "CRCI Medenine",
        "code": 68150
    },
    {
        "id": 365,
        "name": "B.C  ZARZIS P2 (62151)",
        "parent": "CRCI Medenine",
        "code": 62151
    },
    {
        "id": 366,
        "name": "B.C  MEDNINE P2 (62153)",
        "parent": "CRCI Medenine",
        "code": 62153
    },
    {
        "id": 367,
        "name": "B.C  BEN GUERDANE P2 (62152)",
        "parent": "CRCI Medenine",
        "code": 62152
    },
    {
        "id": 368,
        "name": "B.C  AJIM DJERBA P2 (62155)",
        "parent": "CRCI Medenine",
        "code": 62155
    },
    {
        "id": 369,
        "name": "B.C  DJERBA MIDOUN P2 (62154)",
        "parent": "CRCI Medenine",
        "code": 62154
    },
    {
        "id": 370,
        "name": "B.C  JERBA P2 (62156)",
        "parent": "CRCI Medenine",
        "code": 62156
    },
    {
        "id": 371,
        "name": "Ali El Ghoul (IP Communicator)",
        "parent": "CRCI Medenine",
        "code": 67150
    },
    {
        "id": 372,
        "name": "BC G.U Douanes Medenine",
        "parent": "CRCI Medenine",
        "code": 62157
    },
    {
        "id": 373,
        "name": "BC API Medenine",
        "parent": "CRCI Medenine",
        "code": 62158
    },
    {
        "id": 374,
        "name": "B.C  ZARZIS",
        "parent": "CRCI Medenine",
        "code": 6151
    },
    {
        "id": 375,
        "name": "B.C  BEN GUERDANE",
        "parent": "CRCI Medenine",
        "code": 6152
    },
    {
        "id": 376,
        "name": "B.C  MEDNINE",
        "parent": "CRCI Medenine",
        "code": 6153
    },
    {
        "id": 377,
        "name": "B.C  DJERBA MIDOUN",
        "parent": "CRCI Medenine",
        "code": 6154
    },
    {
        "id": 378,
        "name": "B.C  AJIM DJERBA",
        "parent": "CRCI Medenine",
        "code": 6155
    },
    {
        "id": 379,
        "name": "B.C. JERBA",
        "parent": "CRCI Medenine",
        "code": 6156
    },
    {
        "id": 380,
        "name": "BC API Medenine",
        "parent": "CRCI Medenine",
        "code": 6157
    },
    {
        "id": 381,
        "name": "C.C  MANOUBA",
        "parent": "CRCI Mannouba",
        "code": 6300
    },
    {
        "id": 382,
        "name": "C.C  MANOUBA (Cellule de restitution)",
        "parent": "CRCI Mannouba",
        "code": 62300
    },
    {
        "id": 383,
        "name": "C.C  MANOUBA (Cellule de verification)",
        "parent": "CRCI Mannouba",
        "code": 63300
    },
    {
        "id": 384,
        "name": "C.C  MANOUBA (Cellule d'encadrement)",
        "parent": "CRCI Mannouba",
        "code": 64300
    },
    {
        "id": 385,
        "name": "C.C  MANOUBA (Avantages fiscaux)",
        "parent": "CRCI Mannouba",
        "code": 65300
    },
    {
        "id": 386,
        "name": "CC Manouba C. Enreg (66300)",
        "parent": "CRCI Mannouba",
        "code": 66300
    },
    {
        "id": 387,
        "name": "CC Manouba Contentieux (67300)",
        "parent": "CRCI Mannouba",
        "code": 67300
    },
    {
        "id": 388,
        "name": "CC Manouba C. AAF (68300)",
        "parent": "CRCI Mannouba",
        "code": 68300
    },
    {
        "id": 389,
        "name": "B.C  TEBOURBA (6063)",
        "parent": "CRCI Mannouba",
        "code": 6063
    },
    {
        "id": 390,
        "name": "B.C  MANOUBA P2 (62064)",
        "parent": "CRCI Mannouba",
        "code": 62064
    },
    {
        "id": 391,
        "name": "B.C  EJDAIDA P2 (62066)",
        "parent": "CRCI Mannouba",
        "code": 62066
    },
    {
        "id": 392,
        "name": "B.C  EL MORNAGUIA P2 (62301)",
        "parent": "CRCI Mannouba",
        "code": 62301
    },
    {
        "id": 393,
        "name": "B.C  OUED ELLIL P2 (62302)",
        "parent": "CRCI Mannouba",
        "code": 62302
    },
    {
        "id": 394,
        "name": "B.C DENDEN P2 (62303)",
        "parent": "CRCI Mannouba",
        "code": 62303
    },
    {
        "id": 395,
        "name": "BC DOUAR HICHER P2 (62304)",
        "parent": "CRCI Mannouba",
        "code": 62304
    },
    {
        "id": 396,
        "name": "B.C  TEBOURBA",
        "parent": "CRCI Mannouba",
        "code": 6063
    },
    {
        "id": 397,
        "name": "B.C  MANOUBA",
        "parent": "CRCI Mannouba",
        "code": 6064
    },
    {
        "id": 398,
        "name": "B.C  EL TADHAMEN",
        "parent": "CRCI Mannouba",
        "code": 6065
    },
    {
        "id": 399,
        "name": "B.C  JDEIDA",
        "parent": "CRCI Mannouba",
        "code": 6066
    },
    {
        "id": 400,
        "name": "B.C  EL MORNAGUIA",
        "parent": "CRCI Mannouba",
        "code": 6301
    },
    {
        "id": 401,
        "name": "B.C  OUED ELLIL",
        "parent": "CRCI Mannouba",
        "code": 6302
    },
    {
        "id": 402,
        "name": "B.C  EDENDEN",
        "parent": "CRCI Mannouba",
        "code": 6303
    },
    {
        "id": 403,
        "name": "B.C  LE BARDO",
        "parent": "CRCI Mannouba",
        "code": 6409
    },
    {
        "id": 404,
        "name": "B.C  TEBOURBA",
        "parent": "CRCI Mannouba",
        "code": 62063
    },
    {
        "id": 405,
        "name": "B.C  JDEIDA",
        "parent": "CRCI Mannouba",
        "code": 62066
    },
    {
        "id": 406,
        "name": "B.C   Daouar Hicher",
        "parent": "CRCI Mannouba",
        "code": 62304
    },
    {
        "id": 407,
        "name": "C.C  DE MONASTIR",
        "parent": "CRCI Monastir",
        "code": 6230
    },
    {
        "id": 408,
        "name": "C.C  DE MONASTIR (Chef Centre)",
        "parent": "CRCI Monastir",
        "code": 62230
    },
    {
        "id": 409,
        "name": "C.C  DE MONASTIR (Cellule de restitution)",
        "parent": "CRCI Monastir",
        "code": 63230
    },
    {
        "id": 410,
        "name": "C.C  DE MONASTIR (Cellule de verification)",
        "parent": "CRCI Monastir",
        "code": 64230
    },
    {
        "id": 411,
        "name": "C.C  DE MONASTIR (Avantages fiscaux)",
        "parent": "CRCI Monastir",
        "code": 65230
    },
    {
        "id": 412,
        "name": "C.C  DE MONASTIR (Audio Conference)",
        "parent": "CRCI Monastir",
        "code": 66230
    },
    {
        "id": 413,
        "name": "CC Monastir C. Enreg (66230)",
        "parent": "CRCI Monastir",
        "code": 66230
    },
    {
        "id": 414,
        "name": "CC Monastir Contentieux (67230)",
        "parent": "CRCI Monastir",
        "code": 67230
    },
    {
        "id": 415,
        "name": "CC Monastir C. AAF (68230)",
        "parent": "CRCI Monastir",
        "code": 68230
    },
    {
        "id": 416,
        "name": "B.C  MOKNINE P2 (62231)",
        "parent": "CRCI Monastir",
        "code": 62231
    },
    {
        "id": 417,
        "name": "B.C  KSAAR-HELLAL P2 (62232)",
        "parent": "CRCI Monastir",
        "code": 62232
    },
    {
        "id": 418,
        "name": "B.C  JAMMEL P2 (62233)",
        "parent": "CRCI Monastir",
        "code": 62233
    },
    {
        "id": 419,
        "name": "B.C  OUERDANINE P2 (62236)",
        "parent": "CRCI Monastir",
        "code": 62236
    },
    {
        "id": 420,
        "name": "B.C  BEMBLA P2 (62237)",
        "parent": "CRCI Monastir",
        "code": 62237
    },
    {
        "id": 421,
        "name": "B.C  DE TEBOULBA (62238)",
        "parent": "CRCI Monastir",
        "code": 62238
    },
    {
        "id": 422,
        "name": "B.C  BKALTA P2 (62262)",
        "parent": "CRCI Monastir",
        "code": 62262
    },
    {
        "id": 423,
        "name": "B.C  KSIBET EL MEDIOUNI P2 (62235)",
        "parent": "CRCI Monastir",
        "code": 62235
    },
    {
        "id": 424,
        "name": "B.C  SAHLINE P2 (62239)",
        "parent": "CRCI Monastir",
        "code": 62239
    },
    {
        "id": 425,
        "name": "B.C  ZARMEDINE (62261)",
        "parent": "CRCI Monastir",
        "code": 62261
    },
    {
        "id": 426,
        "name": "Sonia Hamza (IP Communicator)",
        "parent": "CRCI Monastir",
        "code": 67230
    },
    {
        "id": 427,
        "name": "B.C  MONASTIR P2",
        "parent": "CRCI Monastir",
        "code": 62234
    },
    {
        "id": 428,
        "name": "B.C  MONASTIR P3",
        "parent": "CRCI Monastir",
        "code": 63234
    },
    {
        "id": 429,
        "name": "B.C   API Monastir",
        "parent": "CRCI Monastir",
        "code": 62263
    },
    {
        "id": 430,
        "name": "B.C  MOKNINE",
        "parent": "CRCI Monastir",
        "code": 6231
    },
    {
        "id": 431,
        "name": "B.C  KSAAR-HELLAL",
        "parent": "CRCI Monastir",
        "code": 6232
    },
    {
        "id": 432,
        "name": "B.C  JAMMEL",
        "parent": "CRCI Monastir",
        "code": 6233
    },
    {
        "id": 433,
        "name": "B.C  MONASTIR",
        "parent": "CRCI Monastir",
        "code": 6234
    },
    {
        "id": 434,
        "name": "B.C  KSIBET EL MEDIOUNI",
        "parent": "CRCI Monastir",
        "code": 6235
    },
    {
        "id": 435,
        "name": "B.C  EL OUERDANINE",
        "parent": "CRCI Monastir",
        "code": 6236
    },
    {
        "id": 436,
        "name": "B.C  BEMBLA",
        "parent": "CRCI Monastir",
        "code": 6237
    },
    {
        "id": 437,
        "name": "B.C  DE TEBOULBA",
        "parent": "CRCI Monastir",
        "code": 6238
    },
    {
        "id": 438,
        "name": "B.C  SAHLINE",
        "parent": "CRCI Monastir",
        "code": 6239
    },
    {
        "id": 439,
        "name": "B.C  ZARMEDINE",
        "parent": "CRCI Monastir",
        "code": 6261
    },
    {
        "id": 440,
        "name": "B.C  BKALTA",
        "parent": "CRCI Monastir",
        "code": 6262
    },
    {
        "id": 441,
        "name": "C.C  DE NABEUL",
        "parent": "CRCI Nabeul",
        "code": 6250
    },
    {
        "id": 442,
        "name": "C.C  DE NABEUL (Chef de restitution)",
        "parent": "CRCI Nabeul",
        "code": 62250
    },
    {
        "id": 443,
        "name": "C.C  DE NABEUL (Cellule de verification)",
        "parent": "CRCI Nabeul",
        "code": 63250
    },
    {
        "id": 444,
        "name": "C.C  DE NABEUL (Cellule d'enregistrement)",
        "parent": "CRCI Nabeul",
        "code": 64250
    },
    {
        "id": 445,
        "name": "C.C  DE NABEUL (Cellule d'encadrement)",
        "parent": "CRCI Nabeul",
        "code": 65250
    },
    {
        "id": 446,
        "name": "C.C  DE NABEUL (Avantages fiscaux)",
        "parent": "CRCI Nabeul",
        "code": 66250
    },
    {
        "id": 447,
        "name": "C.C  DE NABEUL (Audio Conference)",
        "parent": "CRCI Nabeul",
        "code": 67250
    },
    {
        "id": 448,
        "name": "CC Nabeul Contentieux (67250)",
        "parent": "CRCI Nabeul",
        "code": 67250
    },
    {
        "id": 449,
        "name": "CC Nabeul C. AAF (68250)",
        "parent": "CRCI Nabeul",
        "code": 68250
    },
    {
        "id": 450,
        "name": "B.C  GROMBALIA P2 (62251)",
        "parent": "CRCI Nabeul",
        "code": 62251
    },
    {
        "id": 451,
        "name": "B.C  KELIBIA P2 (62253)",
        "parent": "CRCI Nabeul",
        "code": 62253
    },
    {
        "id": 452,
        "name": "B.C  SOLIMAN P2 (62254)",
        "parent": "CRCI Nabeul",
        "code": 62254
    },
    {
        "id": 453,
        "name": "B.C  KORBA P2 (62258)",
        "parent": "CRCI Nabeul",
        "code": 62258
    },
    {
        "id": 454,
        "name": "B.C  MENZEL-TEMIME P3 (63256)",
        "parent": "CRCI Nabeul",
        "code": 63256
    },
    {
        "id": 455,
        "name": "B.C  DAR CHAABANE P3 (63257)",
        "parent": "CRCI Nabeul",
        "code": 63257
    },
    {
        "id": 456,
        "name": "Salma Kherbich (IP Communicator)",
        "parent": "CRCI Nabeul",
        "code": 68250
    },
    {
        "id": 457,
        "name": "B.C  HAMMAMET",
        "parent": "CRCI Nabeul",
        "code": 62252
    },
    {
        "id": 458,
        "name": "B.C  HAMMAMET",
        "parent": "CRCI Nabeul",
        "code": 63252
    },
    {
        "id": 459,
        "name": "B.C  NABEUL",
        "parent": "CRCI Nabeul",
        "code": 62255
    },
    {
        "id": 460,
        "name": "B.C  NABEUL",
        "parent": "CRCI Nabeul",
        "code": 63255
    },
    {
        "id": 461,
        "name": "B.C  MENZEL-TEMIME",
        "parent": "CRCI Nabeul",
        "code": 62256
    },
    {
        "id": 462,
        "name": "B.C  DAR CHAABANE",
        "parent": "CRCI Nabeul",
        "code": 62257
    },
    {
        "id": 463,
        "name": "B.C  API Nabeul",
        "parent": "CRCI Nabeul",
        "code": 62281
    },
    {
        "id": 464,
        "name": "B.C  GROMBALIA",
        "parent": "CRCI Nabeul",
        "code": 6251
    },
    {
        "id": 465,
        "name": "B.C  HAMMAMET",
        "parent": "CRCI Nabeul",
        "code": 6252
    },
    {
        "id": 466,
        "name": "B.C  KELIBIA",
        "parent": "CRCI Nabeul",
        "code": 6253
    },
    {
        "id": 467,
        "name": "B.C  SOLIMAN",
        "parent": "CRCI Nabeul",
        "code": 6254
    },
    {
        "id": 468,
        "name": "B.C  NABEUL",
        "parent": "CRCI Nabeul",
        "code": 6255
    },
    {
        "id": 469,
        "name": "B.C  MENZEL-TEMIME",
        "parent": "CRCI Nabeul",
        "code": 6256
    },
    {
        "id": 470,
        "name": "B.C  DAR CHAABANE",
        "parent": "CRCI Nabeul",
        "code": 6257
    },
    {
        "id": 471,
        "name": "B.C  KORBA",
        "parent": "CRCI Nabeul",
        "code": 6258
    },
    {
        "id": 472,
        "name": "C.C  DE SIDI BOUZID",
        "parent": "CRCI Sidi Bouzid",
        "code": 6120
    },
    {
        "id": 473,
        "name": "C.C  DE SIDI BOUZID  (Cellule de verification)",
        "parent": "CRCI Sidi Bouzid",
        "code": 62120
    },
    {
        "id": 474,
        "name": "C.C  DE SIDI BOUZID  (Cellule d'encadrement)",
        "parent": "CRCI Sidi Bouzid",
        "code": 63120
    },
    {
        "id": 475,
        "name": "C.C  DE SIDI BOUZID  (Avantages fiscaux)",
        "parent": "CRCI Sidi Bouzid",
        "code": 64120
    },
    {
        "id": 476,
        "name": "CC  Sidi Bouzid C. Restit (62120)",
        "parent": "CRCI Sidi Bouzid",
        "code": 62120
    },
    {
        "id": 477,
        "name": "CC  Sidi Bouzid C. Enreg (66120)",
        "parent": "CRCI Sidi Bouzid",
        "code": 66120
    },
    {
        "id": 478,
        "name": "CC  Sidi Bouzid Contentieux (67120)",
        "parent": "CRCI Sidi Bouzid",
        "code": 67120
    },
    {
        "id": 479,
        "name": "CC  Sidi Bouzid C. AAF (68120)",
        "parent": "CRCI Sidi Bouzid",
        "code": 68120
    },
    {
        "id": 480,
        "name": "B.C  MAKNASSY P3 (63121)",
        "parent": "CRCI Sidi Bouzid",
        "code": 63121
    },
    {
        "id": 481,
        "name": "B.C  OULEDHAFFOUZ P2 (62124)",
        "parent": "CRCI Sidi Bouzid",
        "code": 62124
    },
    {
        "id": 482,
        "name": "B.C  JELMA P2 (62125)",
        "parent": "CRCI Sidi Bouzid",
        "code": 62125
    },
    {
        "id": 483,
        "name": "B.C  MAKNASSY",
        "parent": "CRCI Sidi Bouzid",
        "code": 62121
    },
    {
        "id": 484,
        "name": "B.C  SIDI BOUZID AV. LA REPUBLIQUE",
        "parent": "CRCI Sidi Bouzid",
        "code": 62122
    },
    {
        "id": 485,
        "name": "B.C  SIDI BOUZID AV. LA REPUBLIQUE",
        "parent": "CRCI Sidi Bouzid",
        "code": 63122
    },
    {
        "id": 486,
        "name": "B.C  API Sidi Bouzid",
        "parent": "CRCI Sidi Bouzid",
        "code": 62127
    },
    {
        "id": 487,
        "name": "B.C  MAKNASSY",
        "parent": "CRCI Sidi Bouzid",
        "code": 6121
    },
    {
        "id": 488,
        "name": "B.C  SIDI BOUZID AV. LA REPUBLIQUE",
        "parent": "CRCI Sidi Bouzid",
        "code": 6122
    },
    {
        "id": 489,
        "name": "B.C  OULED HAFFOUZ",
        "parent": "CRCI Sidi Bouzid",
        "code": 6124
    },
    {
        "id": 490,
        "name": "B.C  JELMA",
        "parent": "CRCI Sidi Bouzid",
        "code": 6125
    },
    {
        "id": 491,
        "name": "C.C  DE SFAX I",
        "parent": "CRCI Sfax 1",
        "code": 6190
    },
    {
        "id": 492,
        "name": "C.C  DE SFAX I  (Cellule de restitution)",
        "parent": "CRCI Sfax 1",
        "code": 62190
    },
    {
        "id": 493,
        "name": "C.C  DE SFAX I  (Cellule de verification)",
        "parent": "CRCI Sfax 1",
        "code": 63190
    },
    {
        "id": 494,
        "name": "C.C  DE SFAX I  (Cellule d'encadrement)",
        "parent": "CRCI Sfax 1",
        "code": 64190
    },
    {
        "id": 495,
        "name": "C.C  DE SFAX I  (avantages fiscaux)",
        "parent": "CRCI Sfax 1",
        "code": 65190
    },
    {
        "id": 496,
        "name": "C.C  DE SFAX I  (Audio Conference)",
        "parent": "CRCI Sfax 1",
        "code": 66190
    },
    {
        "id": 497,
        "name": "CC Sfax I C. Enreg (66190)",
        "parent": "CRCI Sfax 1",
        "code": 66190
    },
    {
        "id": 498,
        "name": "CC Sfax I Contentieux (67190)",
        "parent": "CRCI Sfax 1",
        "code": 67190
    },
    {
        "id": 499,
        "name": "CC Sfax I C. AAF (68190)",
        "parent": "CRCI Sfax 1",
        "code": 68190
    },
    {
        "id": 500,
        "name": "B.C  JBENIANA (6191)",
        "parent": "CRCI Sfax 1",
        "code": 6191
    },
    {
        "id": 501,
        "name": "B.C  SAKIET EZZIT P2 (62198)",
        "parent": "CRCI Sfax 1",
        "code": 62198
    },
    {
        "id": 502,
        "name": "B.C  EL HENCHA P2 (62199)",
        "parent": "CRCI Sfax 1",
        "code": 62199
    },
    {
        "id": 503,
        "name": "B.C  GREMDA P2 (62201)",
        "parent": "CRCI Sfax 1",
        "code": 62201
    },
    {
        "id": 504,
        "name": "SFAX I B. GARANTIE  (6203)",
        "parent": "CRCI Sfax 1",
        "code": 6203
    },
    {
        "id": 505,
        "name": "Skander Walha  (IP Communicator)",
        "parent": "CRCI Sfax 1",
        "code": 67190
    },
    {
        "id": 506,
        "name": "B.C  JBENIANA",
        "parent": "CRCI Sfax 1",
        "code": 62191
    },
    {
        "id": 507,
        "name": "B.C  SFAX-VILLE P2",
        "parent": "CRCI Sfax 1",
        "code": 62193
    },
    {
        "id": 508,
        "name": "B.C  SFAX-VILLE P3",
        "parent": "CRCI Sfax 1",
        "code": 63193
    },
    {
        "id": 509,
        "name": "B.C   SFAX-NORD P2",
        "parent": "CRCI Sfax 1",
        "code": 62195
    },
    {
        "id": 510,
        "name": "B.C  SFAX-NORD P3",
        "parent": "CRCI Sfax 1",
        "code": 63195
    },
    {
        "id": 511,
        "name": "B.C  EL HENCHA",
        "parent": "CRCI Sfax 1",
        "code": 62199
    },
    {
        "id": 512,
        "name": "BC Gui Uni Trav. Tun. Etranger",
        "parent": "CRCI Sfax 1",
        "code": 62204
    },
    {
        "id": 513,
        "name": "C.C  DE SFAX II  (Cellule de restitution)",
        "parent": "CRCI Sfax 2",
        "code": 62290
    },
    {
        "id": 514,
        "name": "C.C  DE SFAX II  (Cellule de restitution)",
        "parent": "CRCI Sfax 2",
        "code": 63290
    },
    {
        "id": 515,
        "name": "C.C  DE SFAX II  (Cellule de verification)",
        "parent": "CRCI Sfax 2",
        "code": 64290
    },
    {
        "id": 516,
        "name": "C.C  DE SFAX II  (Cellule d'encadrement)",
        "parent": "CRCI Sfax 2",
        "code": 65290
    },
    {
        "id": 517,
        "name": "C.C  DE SFAX II  (avantages fiscaux)",
        "parent": "CRCI Sfax 2",
        "code": 66290
    },
    {
        "id": 518,
        "name": "C.C  DE SFAX II  (Audio Conference)",
        "parent": "CRCI Sfax 2",
        "code": 67290
    },
    {
        "id": 519,
        "name": "CC Sfax II Contentieux (67290)",
        "parent": "CRCI Sfax 2",
        "code": 67290
    },
    {
        "id": 520,
        "name": "CC Sfax II C. AAF (68290)",
        "parent": "CRCI Sfax 2",
        "code": 68290
    },
    {
        "id": 521,
        "name": "B.C  MAHRES P2 (62192)",
        "parent": "CRCI Sfax 2",
        "code": 62192
    },
    {
        "id": 522,
        "name": "B.C  AGAREB P2 (62194)",
        "parent": "CRCI Sfax 2",
        "code": 6194
    },
    {
        "id": 523,
        "name": "B.C  BIR ALI B. KHALIFA P2 (62202)",
        "parent": "CRCI Sfax 2",
        "code": 62202
    },
    {
        "id": 524,
        "name": "B.C  SFAX-SUD P2",
        "parent": "CRCI Sfax 2",
        "code": 62196
    },
    {
        "id": 525,
        "name": "B.C  SFAX-SUD P3",
        "parent": "CRCI Sfax 2",
        "code": 63196
    },
    {
        "id": 526,
        "name": "B.C  SFAX-OUEST P2",
        "parent": "CRCI Sfax 2",
        "code": 62197
    },
    {
        "id": 527,
        "name": "B.C  SFAX-OUEST P3",
        "parent": "CRCI Sfax 2",
        "code": 63197
    },
    {
        "id": 528,
        "name": "B.C  JBENIANA",
        "parent": "CRCI Sfax 2",
        "code": 6191
    },
    {
        "id": 529,
        "name": "B.C  MAHRES",
        "parent": "CRCI Sfax 2",
        "code": 6192
    },
    {
        "id": 530,
        "name": "B.C  SFAX-VILLE",
        "parent": "CRCI Sfax 2",
        "code": 6193
    },
    {
        "id": 531,
        "name": "B.C  AGAREB",
        "parent": "CRCI Sfax 2",
        "code": 6194
    },
    {
        "id": 532,
        "name": "B.C  RTE TUNIS SFAX",
        "parent": "CRCI Sfax 2",
        "code": 6195
    },
    {
        "id": 533,
        "name": "B.C  SFAX-SUD",
        "parent": "CRCI Sfax 2",
        "code": 6196
    },
    {
        "id": 534,
        "name": "B.C  SFAX-OUEST",
        "parent": "CRCI Sfax 2",
        "code": 6197
    },
    {
        "id": 535,
        "name": "B.C  SAKIET EZZIT",
        "parent": "CRCI Sfax 2",
        "code": 6198
    },
    {
        "id": 536,
        "name": "B.C  EL HENCHA",
        "parent": "CRCI Sfax 2",
        "code": 6199
    },
    {
        "id": 537,
        "name": "GUICHET UNIQUE (A.P.I.) SFAX",
        "parent": "CRCI Sfax 2",
        "code": 6200
    },
    {
        "id": 538,
        "name": "B.C  GREMDA",
        "parent": "CRCI Sfax 2",
        "code": 6201
    },
    {
        "id": 539,
        "name": "B.C  BIR ALI B. KHALIFA",
        "parent": "CRCI Sfax 2",
        "code": 6202
    },
    {
        "id": 540,
        "name": "BC Gui Uni Trav. Tun. Etranger",
        "parent": "CRCI Sfax 2",
        "code": 6204
    },
    {
        "id": 541,
        "name": "C.C  DE SELIANA",
        "parent": "CRCI Siliana",
        "code": 6100
    },
    {
        "id": 542,
        "name": "C.C  DE SELIANA (Cellule de verification)",
        "parent": "CRCI Siliana",
        "code": 62100
    },
    {
        "id": 543,
        "name": "C.C  DE SELIANA (Cellule d'encadrement)",
        "parent": "CRCI Siliana",
        "code": 63100
    },
    {
        "id": 544,
        "name": "C.C  DE SELIANA (Avantages fiscaux)",
        "parent": "CRCI Siliana",
        "code": 64100
    },
    {
        "id": 545,
        "name": "CC Seliana C. Enreg (66100)",
        "parent": "CRCI Siliana",
        "code": 66100
    },
    {
        "id": 546,
        "name": "CC Seliana Contentieux (67100)",
        "parent": "CRCI Siliana",
        "code": 67100
    },
    {
        "id": 547,
        "name": "CC Seliana C. AAF (68100)",
        "parent": "CRCI Siliana",
        "code": 68100
    },
    {
        "id": 548,
        "name": "B.C  GAAFOUR",
        "parent": "CRCI Siliana",
        "code": 62103
    },
    {
        "id": 549,
        "name": "B.C  API Siliana",
        "parent": "CRCI Siliana",
        "code": 62104
    },
    {
        "id": 550,
        "name": "B.C  SELIANA",
        "parent": "CRCI Siliana",
        "code": 6101
    },
    {
        "id": 551,
        "name": "B.C  MAKTHAR",
        "parent": "CRCI Siliana",
        "code": 6102
    },
    {
        "id": 552,
        "name": "B.C  GAAFOUR",
        "parent": "CRCI Siliana",
        "code": 6103
    },
    {
        "id": 553,
        "name": "B.C  BOU ARADA",
        "parent": "CRCI Siliana",
        "code": 6105
    },
    {
        "id": 554,
        "name": "C.C  DE SOUSSE",
        "parent": "CRCI Sousse",
        "code": 6240
    },
    {
        "id": 555,
        "name": "C.C  DE SOUSSE (Cellule de restitution)",
        "parent": "CRCI Sousse",
        "code": 62240
    },
    {
        "id": 556,
        "name": "C.C  DE SOUSSE (Cellule de verification)",
        "parent": "CRCI Sousse",
        "code": 63240
    },
    {
        "id": 557,
        "name": "C.C  DE SOUSSE (Cellule d'encadrement)",
        "parent": "CRCI Sousse",
        "code": 64240
    },
    {
        "id": 558,
        "name": "C.C  DE SOUSSE (Avantages fiscaux)",
        "parent": "CRCI Sousse",
        "code": 65240
    },
    {
        "id": 559,
        "name": "C.C  DE SOUSSE (Audio Conference)",
        "parent": "CRCI Sousse",
        "code": 66240
    },
    {
        "id": 560,
        "name": "CC Sousse C. Enreg (66240)",
        "parent": "CRCI Sousse",
        "code": 66240
    },
    {
        "id": 561,
        "name": "CC Sousse C. AAF (68240)",
        "parent": "CRCI Sousse",
        "code": 68240
    },
    {
        "id": 562,
        "name": "B.C  MSAKEN P2 (62242)",
        "parent": "CRCI Sousse",
        "code": 62242
    },
    {
        "id": 563,
        "name": "B.C  ENFIDHA P3 (62243)",
        "parent": "CRCI Sousse",
        "code": 63243
    },
    {
        "id": 564,
        "name": "B.C  HAMMAM-SOUSSE P2 (62244)",
        "parent": "CRCI Sousse",
        "code": 62244
    },
    {
        "id": 565,
        "name": "B.C  KLAA SGHIRA P3 (63245)",
        "parent": "CRCI Sousse",
        "code": 63245
    },
    {
        "id": 566,
        "name": "B.C  SOUSSE-SUD P2 (62241)",
        "parent": "CRCI Sousse",
        "code": 62241
    },
    {
        "id": 567,
        "name": "B.C  CITE ERRIADH P2 (62273)",
        "parent": "CRCI Sousse",
        "code": 62273
    },
    {
        "id": 568,
        "name": "B.C  KAALA KEBIRA P2 (62247)",
        "parent": "CRCI Sousse",
        "code": 62247
    },
    {
        "id": 569,
        "name": "B.C  SIDI BOU ALI P2 (62249)",
        "parent": "CRCI Sousse",
        "code": 62249
    },
    {
        "id": 570,
        "name": "Amira Najar  (IP Communicator)",
        "parent": "CRCI Sousse",
        "code": 67240
    },
    {
        "id": 571,
        "name": "B.C  ENFIDHA",
        "parent": "CRCI Sousse",
        "code": 62243
    },
    {
        "id": 572,
        "name": "B.C  KALAA SGHIRA",
        "parent": "CRCI Sousse",
        "code": 62245
    },
    {
        "id": 573,
        "name": "B.C  SOUSSE VILLE P2",
        "parent": "CRCI Sousse",
        "code": 62246
    },
    {
        "id": 574,
        "name": "B.C  SOUSSE VILLE P3",
        "parent": "CRCI Sousse",
        "code": 63246
    },
    {
        "id": 575,
        "name": "B.C  SOUSSE-KHEZAMA P2",
        "parent": "CRCI Sousse",
        "code": 62248
    },
    {
        "id": 576,
        "name": "B.C  SOUSSE-KHEZAMA P3",
        "parent": "CRCI Sousse",
        "code": 63248
    },
    {
        "id": 577,
        "name": "GUICHET UNIQUE SOUSSE",
        "parent": "CRCI Sousse",
        "code": 62272
    },
    {
        "id": 578,
        "name": "B.C  SOUSSE-SUD",
        "parent": "CRCI Sousse",
        "code": 6241
    },
    {
        "id": 579,
        "name": "B.C  MSAKEN",
        "parent": "CRCI Sousse",
        "code": 6242
    },
    {
        "id": 580,
        "name": "B.C  ENFIDHA",
        "parent": "CRCI Sousse",
        "code": 6243
    },
    {
        "id": 581,
        "name": "B.C  HAMMAM-SOUSSE",
        "parent": "CRCI Sousse",
        "code": 6244
    },
    {
        "id": 582,
        "name": "B.C  KALAA SGHIRA",
        "parent": "CRCI Sousse",
        "code": 6245
    },
    {
        "id": 583,
        "name": "B.C  SOUSSE VILLE",
        "parent": "CRCI Sousse",
        "code": 6246
    },
    {
        "id": 584,
        "name": "B.C  KAALA KEBIRA",
        "parent": "CRCI Sousse",
        "code": 6247
    },
    {
        "id": 585,
        "name": "B.C  SOUSSE-KHEZAMA",
        "parent": "CRCI Sousse",
        "code": 6248
    },
    {
        "id": 586,
        "name": "B.C  SIDI BOUALI",
        "parent": "CRCI Sousse",
        "code": 6249
    },
    {
        "id": 587,
        "name": "B.C  API SOUSSE",
        "parent": "CRCI Sousse",
        "code": 6271
    },
    {
        "id": 588,
        "name": "GUICHET UNIQUE SOUSSE",
        "parent": "CRCI Sousse",
        "code": 6272
    },
    {
        "id": 589,
        "name": "B.C  CITE ERRIADH SOUSSE",
        "parent": "CRCI Sousse",
        "code": 6273
    },
    {
        "id": 591,
        "name": "C.C  DE TATAOUINE",
        "parent": "CRCI Tataouine",
        "code": 6160
    },
    {
        "id": 592,
        "name": "C.C  DE TATAOUINE (Cellule de verification)",
        "parent": "CRCI Tataouine",
        "code": 62160
    },
    {
        "id": 593,
        "name": "C.C  DE TATAOUINE (Cellule d'encadrement)",
        "parent": "CRCI Tataouine",
        "code": 63160
    },
    {
        "id": 594,
        "name": "CC Tataouine C. Enreg (66160)",
        "parent": "CRCI Tataouine",
        "code": 66160
    },
    {
        "id": 595,
        "name": "CC Tataouine Contentieux (67160)",
        "parent": "CRCI Tataouine",
        "code": 67160
    },
    {
        "id": 596,
        "name": "CC Tataouine A. Fiscaux (65160)",
        "parent": "CRCI Tataouine",
        "code": 65160
    },
    {
        "id": 597,
        "name": "CC Tataouine C. AAF (68160)",
        "parent": "CRCI Tataouine",
        "code": 68160
    },
    {
        "id": 598,
        "name": "B.C   API  TATAOUINE",
        "parent": "CRCI Tataouine",
        "code": 62163
    },
    {
        "id": 599,
        "name": "B.C  TATAOUINE",
        "parent": "CRCI Tataouine",
        "code": 6161
    },
    {
        "id": 600,
        "name": "B.C  GHOMRASSEN",
        "parent": "CRCI Tataouine",
        "code": 6162
    },
    {
        "id": 602,
        "name": "C.C  TUNIS I",
        "parent": "CRCI Tunis 1",
        "code": 6010
    },
    {
        "id": 601,
        "name": "B.C  BAB SOUIKA",
        "parent": "CRCI Tunis 1",
        "code": 6009
    },
    {
        "id": 603,
        "name": "C.C  TUNIS I  (Cellule de restitution)",
        "parent": "CRCI Tunis 2",
        "code": 62010
    },
    {
        "id": 604,
        "name": "C.C  TUNIS I  (Cellule de verification)",
        "parent": "CRCI Tunis 2",
        "code": 63010
    },
    {
        "id": 605,
        "name": "C.C  TUNIS I  (Cellule d'encadrement)",
        "parent": "CRCI Tunis 2",
        "code": 64010
    },
    {
        "id": 606,
        "name": "C.C  TUNIS I  (Avantages fiscaux)",
        "parent": "CRCI Tunis 2",
        "code": 65010
    },
    {
        "id": 607,
        "name": "Chakib Znaidi  (IP Communicator)",
        "parent": "CRCI Tunis 2",
        "code": 66010
    },
    {
        "id": 608,
        "name": "CC Tunis I C. Enreg (66010)",
        "parent": "CRCI Tunis 1",
        "code": 66010
    },
    {
        "id": 609,
        "name": "CC Tunis I Contentieux (67010)",
        "parent": "CRCI Tunis 1",
        "code": 67010
    },
    {
        "id": 610,
        "name": "CC Tunis I C. AAF (68010)",
        "parent": "CRCI Tunis 1",
        "code": 68010
    },
    {
        "id": 611,
        "name": "B.C  EL JAZIRA P2 (62012)",
        "parent": "CRCI Tunis 1",
        "code": 62012
    },
    {
        "id": 612,
        "name": "B.C  RUE ANGLETERRE P2 (62013)",
        "parent": "CRCI Tunis 1",
        "code": 62013
    },
    {
        "id": 613,
        "name": "B.C  RUE DE ROME P2 (62014)",
        "parent": "CRCI Tunis 1",
        "code": 62014
    },
    {
        "id": 614,
        "name": "B.C  EL OUARDIA P2 (62019)",
        "parent": "CRCI Tunis 1",
        "code": 62019
    },
    {
        "id": 615,
        "name": "TUNIS I B. GARANTIE",
        "parent": "CRCI Tunis 1",
        "code": 6910
    },
    {
        "id": 616,
        "name": "G. U. T. E.",
        "parent": "CRCI Tunis 1",
        "code": 6011
    },
    {
        "id": 617,
        "name": "B.C  EL JAZIRA",
        "parent": "CRCI Tunis 1",
        "code": 6012
    },
    {
        "id": 618,
        "name": "B.C  RUE ANGLETERRE",
        "parent": "CRCI Tunis 1",
        "code": 6013
    },
    {
        "id": 619,
        "name": "B.C  RUE DE ROME",
        "parent": "CRCI Tunis 1",
        "code": 6014
    },
    {
        "id": 620,
        "name": "B.C  LA GARE",
        "parent": "CRCI Tunis 1",
        "code": 6015
    },
    {
        "id": 621,
        "name": "B.C  RUE LOUIS LUMIERE",
        "parent": "CRCI Tunis 1",
        "code": 6016
    },
    {
        "id": 622,
        "name": "B.C  EL OUARDIA",
        "parent": "CRCI Tunis 1",
        "code": 6019
    },
    {
        "id": 623,
        "name": "B.C  Guichet unique commerce",
        "parent": "CRCI Tunis 1",
        "code": 6415
    },
    {
        "id": 624,
        "name": "C.C  TUNIS II",
        "parent": "CRCI Tunis 2",
        "code": 6400
    },
    {
        "id": 625,
        "name": "CC Tunis II C. Enreg (66400)",
        "parent": "CRCI Tunis 2",
        "code": 66400
    },
    {
        "id": 626,
        "name": "CC Tunis II Contentieux (67400)",
        "parent": "CRCI Tunis 2",
        "code": 67400
    },
    {
        "id": 627,
        "name": "CC Tunis II C. AAF (68400)",
        "parent": "CRCI Tunis 2",
        "code": 68400
    },
    {
        "id": 628,
        "name": "B.C  EL MANAR P2 (62406)",
        "parent": "CRCI Tunis 2",
        "code": 62406
    },
    {
        "id": 629,
        "name": "B.C  MENZAH 9 P2 (62407)",
        "parent": "CRCI Tunis 2",
        "code": 62407
    },
    {
        "id": 630,
        "name": "B.C  KSAR SAID P2 (62408)",
        "parent": "CRCI Tunis 2",
        "code": 62408
    },
    {
        "id": 631,
        "name": "B.C  LE BARDO P2 (62409)",
        "parent": "CRCI Tunis 2",
        "code": 62409
    },
    {
        "id": 632,
        "name": "B.C  SIDI HSIN ESSIJOUMI (6413)",
        "parent": "CRCI Tunis 2",
        "code": 6413
    },
    {
        "id": 633,
        "name": "B.C CITE EZZOUHOUR P2 (62414)",
        "parent": "CRCI Tunis 2",
        "code": 62414
    },
    {
        "id": 634,
        "name": "API  TUNIS P2 (62405)",
        "parent": "CRCI Tunis 2",
        "code": 62405
    },
    {
        "id": 635,
        "name": "B.C  RUE D\"AUTRICHE",
        "parent": "CRCI Tunis 2",
        "code": 6401
    },
    {
        "id": 636,
        "name": "B.C  CHARGUIA",
        "parent": "CRCI Tunis 2",
        "code": 6402
    },
    {
        "id": 637,
        "name": "B.C  NELSAN MANDELA",
        "parent": "CRCI Tunis 2",
        "code": 6403
    },
    {
        "id": 638,
        "name": "B.C  LES BERGES DU LAC",
        "parent": "CRCI Tunis 2",
        "code": 6404
    },
    {
        "id": 639,
        "name": "GUICHET UNIQUE (A.P.I.) TUNIS",
        "parent": "CRCI Tunis 2",
        "code": 6405
    },
    {
        "id": 640,
        "name": "B.C  EL MANAR",
        "parent": "CRCI Tunis 2",
        "code": 6406
    },
    {
        "id": 641,
        "name": "B.C  EL MENZAH IX",
        "parent": "CRCI Tunis 2",
        "code": 6407
    },
    {
        "id": 642,
        "name": "B.C  KSAR ASSAID",
        "parent": "CRCI Tunis 2",
        "code": 6408
    },
    {
        "id": 643,
        "name": "B.C  LA MARSA",
        "parent": "CRCI Tunis 2",
        "code": 6410
    },
    {
        "id": 644,
        "name": "B.C  LA GOULETTE",
        "parent": "CRCI Tunis 2",
        "code": 6411
    },
    {
        "id": 645,
        "name": "B.C  CITE MAHRAJENE",
        "parent": "CRCI Tunis 2",
        "code": 6412
    },
    {
        "id": 646,
        "name": "B.C  SIDI HSINE ESSIJOUMI",
        "parent": "CRCI Tunis 2",
        "code": 6413
    },
    {
        "id": 647,
        "name": "B.C  CITE EZZOUHOUR",
        "parent": "CRCI Tunis 2",
        "code": 6414
    },
    {
        "id": 648,
        "name": "C.C. Tunis III",
        "parent": "CRCI Tunis 2",
        "code": 6600
    },
    {
        "id": 649,
        "name": "B.C. Montplaisir",
        "parent": "CRCI Tunis 2",
        "code": 6601
    },
    {
        "id": 650,
        "name": "B.C  BAB SOUIKA P2",
        "parent": "CRCI Tunis 2",
        "code": 62009
    },
    {
        "id": 651,
        "name": "B.C  BAB SOUIKA P3",
        "parent": "CRCI Tunis 2",
        "code": 63009
    },
    {
        "id": 652,
        "name": "G. U. T. E.",
        "parent": "CRCI Tunis 2",
        "code": 62011
    },
    {
        "id": 653,
        "name": "B.C  LA GARE P2",
        "parent": "CRCI Tunis 2",
        "code": 62015
    },
    {
        "id": 654,
        "name": "B.C  LA GARE P3",
        "parent": "CRCI Tunis 2",
        "code": 63015
    },
    {
        "id": 655,
        "name": "B.C  RUE LOUIS LUMIERE P2",
        "parent": "CRCI Tunis 2",
        "code": 62016
    },
    {
        "id": 656,
        "name": "B.C  RUE LOUIS LUMIERE P3",
        "parent": "CRCI Tunis 2",
        "code": 63016
    },
    {
        "id": 657,
        "name": "C.C  TUNIS II  (Cellule de restitution)",
        "parent": "CRCI Tunis 2",
        "code": 62400
    },
    {
        "id": 658,
        "name": "C.C  TUNIS II  (Cellule de verification)",
        "parent": "CRCI Tunis 2",
        "code": 63400
    },
    {
        "id": 659,
        "name": "C.C  TUNIS II (Cellule d'encadrement)",
        "parent": "CRCI Tunis 2",
        "code": 64400
    },
    {
        "id": 660,
        "name": "C.C  TUNIS II  (Avantages fiscaux)",
        "parent": "CRCI Tunis 2",
        "code": 65400
    },
    {
        "id": 661,
        "name": "Hichem Manaii  (IP Communicator)",
        "parent": "CRCI Tunis 2",
        "code": 66400
    },
    {
        "id": 662,
        "name": "B.C  RUE D\"Autriche P2",
        "parent": "CRCI Tunis 2",
        "code": 62401
    },
    {
        "id": 663,
        "name": "B.C  RUE D\"Autriche P3",
        "parent": "CRCI Tunis 2",
        "code": 63401
    },
    {
        "id": 664,
        "name": "B.C  CHARGUIA P2",
        "parent": "CRCI Tunis 2",
        "code": 62402
    },
    {
        "id": 665,
        "name": "B.C  CHARGUIA P¨3",
        "parent": "CRCI Tunis 2",
        "code": 63402
    },
    {
        "id": 666,
        "name": "B.C  NELSAN MANDELA P2",
        "parent": "CRCI Tunis 2",
        "code": 62403
    },
    {
        "id": 667,
        "name": "B.C  NELSAN MANDELA P3",
        "parent": "CRCI Tunis 2",
        "code": 63403
    },
    {
        "id": 668,
        "name": "B.C  LES BERGES DU LAC P2",
        "parent": "CRCI Tunis 2",
        "code": 62404
    },
    {
        "id": 669,
        "name": "B.C  LES BERGES DU LAC P3",
        "parent": "CRCI Tunis 2",
        "code": 63404
    },
    {
        "id": 670,
        "name": "B.C  LA MARSA P2",
        "parent": "CRCI Tunis 2",
        "code": 62410
    },
    {
        "id": 671,
        "name": "B.C  LA MARSA P3",
        "parent": "CRCI Tunis 2",
        "code": 63410
    },
    {
        "id": 672,
        "name": "B.C  LA GOULETTE P2",
        "parent": "CRCI Tunis 2",
        "code": 62411
    },
    {
        "id": 673,
        "name": "B.C  LA GOULETTE P3",
        "parent": "CRCI Tunis 2",
        "code": 63411
    },
    {
        "id": 674,
        "name": "B.C  CITE MAHRAJENE P2",
        "parent": "CRCI Tunis 2",
        "code": 62412
    },
    {
        "id": 675,
        "name": "B.C  CITE MAHRAJENE P3",
        "parent": "CRCI Tunis 2",
        "code": 63412
    },
    {
        "id": 676,
        "name": "B.C  SIDI HSINE ESSIJOUMI P2",
        "parent": "CRCI Tunis 2",
        "code": 62413
    },
    {
        "id": 677,
        "name": "C.C. Tunis III  (Cellule de restitution)",
        "parent": "CRCI Tunis 3",
        "code": 62600
    },
    {
        "id": 678,
        "name": "C.C. Tunis III  (Cellule de verification)",
        "parent": "CRCI Tunis 3",
        "code": 63600
    },
    {
        "id": 679,
        "name": "C.C. Tunis III  (Cellule d'encadrement)",
        "parent": "CRCI Tunis 3",
        "code": 64600
    },
    {
        "id": 680,
        "name": "C.C. Tunis III  (Avantages fiscaux)",
        "parent": "CRCI Tunis 3",
        "code": 65600
    },
    {
        "id": 681,
        "name": "CC Tunis III C. Enreg (66600)",
        "parent": "CRCI Tunis 3",
        "code": 66600
    },
    {
        "id": 682,
        "name": "CC Tunis III Contentieux (67600)",
        "parent": "CRCI Tunis 3",
        "code": 67600
    },
    {
        "id": 683,
        "name": "CC Tunis III C. AAF (68600)",
        "parent": "CRCI Tunis 3",
        "code": 68600
    },
    {
        "id": 684,
        "name": "Anissa Berrezek   (IP Communicator)",
        "parent": "CRCI Tunis 3",
        "code": 66600
    },
    {
        "id": 685,
        "name": "B.C. Montplaisir P2",
        "parent": "CRCI Tunis 3",
        "code": 62601
    },
    {
        "id": 686,
        "name": "B.C. Montplaisir P3",
        "parent": "CRCI Tunis 3",
        "code": 63601
    },
    {
        "id": 693,
        "name": "C.C  DE TOZEUR",
        "parent": "CRCI Tozeur",
        "code": 6140
    },
    {
        "id": 694,
        "name": "C.C  DE TOZEUR (Chef centre)",
        "parent": "CRCI Tozeur",
        "code": 62140
    },
    {
        "id": 695,
        "name": "CC Tozeur C. Enreg (66140)",
        "parent": "CRCI Tozeur",
        "code": 66140
    },
    {
        "id": 696,
        "name": "CC Tozeur Contentieux (67140)",
        "parent": "CRCI Tozeur",
        "code": 67140
    },
    {
        "id": 697,
        "name": "CC Tozeur A. Fiscaux (65140)",
        "parent": "CRCI Tozeur",
        "code": 65140
    },
    {
        "id": 698,
        "name": "CC Tozeur C. AAF (68140)",
        "parent": "CRCI Tozeur",
        "code": 68140
    },
    {
        "id": 699,
        "name": "B.C  TOZEUR P2",
        "parent": "CRCI Tozeur",
        "code": 62141
    },
    {
        "id": 700,
        "name": "B.C  TOZEUR P3",
        "parent": "CRCI Tozeur",
        "code": 63141
    },
    {
        "id": 701,
        "name": "B.C  TOZEUR P4",
        "parent": "CRCI Tozeur",
        "code": 64141
    },
    {
        "id": 702,
        "name": "B.C   API  TOZEUR",
        "parent": "CRCI Tozeur",
        "code": 62142
    },
    {
        "id": 703,
        "name": "C.C  DE TOZEUR (Cellule de verification)",
        "parent": "CRCI Tozeur",
        "code": 63140
    },
    {
        "id": 704,
        "name": "C.C  DE TOZEUR (Cellule d'encadrement)",
        "parent": "CRCI Tozeur",
        "code": 64140
    },
    {
        "id": 705,
        "name": "B.C  TOZEUR",
        "parent": "CRCI Tozeur",
        "code": 6141
    },
    {
        "id": 706,
        "name": "C.C  DE ZAGHOUAN",
        "parent": "CRCI Zaghouan",
        "code": 6020
    },
    {
        "id": 707,
        "name": "C.C  DE ZAGHOUAN(cellule de verification)",
        "parent": "CRCI Zaghouan",
        "code": 62020
    },
    {
        "id": 708,
        "name": "CC Zaghouan C. Enreg (66020)",
        "parent": "CRCI Zaghouan",
        "code": 66020
    },
    {
        "id": 709,
        "name": "CC Zaghouan Contentieux (67020)",
        "parent": "CRCI Zaghouan",
        "code": 67020
    },
    {
        "id": 710,
        "name": "CC Zaghouan A. Fiscaux (65020)",
        "parent": "CRCI Zaghouan",
        "code": 65020
    },
    {
        "id": 711,
        "name": "CC Zaghouan C. AAF (68020)",
        "parent": "CRCI Zaghouan",
        "code": 68020
    },
    {
        "id": 712,
        "name": "B.C  ZAGHOUAN P2 (62021)",
        "parent": "CRCI Zaghouan",
        "code": 62021
    },
    {
        "id": 713,
        "name": "B.C  EL FAHS P2 (62022)",
        "parent": "CRCI Zaghouan",
        "code": 62022
    },
    {
        "id": 714,
        "name": "C.C  DE ZAGHOUAN(cellule d'encadrement)",
        "parent": "CRCI Zaghouan",
        "code": 63020
    },
    {
        "id": 715,
        "name": "B.C   API    ZAGHOUAN",
        "parent": "CRCI Zaghouan",
        "code": 62023
    },
    {
        "id": 716,
        "name": "B.C  ZAGHOUAN",
        "parent": "CRCI Zaghouan",
        "code": 6021
    },
    {
        "id": 717,
        "name": "B.C  EL FAHS",
        "parent": "CRCI Zaghouan",
        "code": 6022
    }
];


const lines: any[] = [];

for (const office of offices) {
    office.code = office.code.toString();
    office.code = office.code.slice(office.code.length - 3);

    if (lines.find(it => it.code == office.code)) {
        continue;
    }

    if (!office.name.includes('C.C') && office.parent?.includes('CRCI')) {
        const parentName = office.parent.replace('CRCI', '').trim().toLowerCase().replace('medenine', 'mednine')
            .replace('mannouba', 'manouba')
            .replace('siliana', 'seliana')
            .replace('1', 'I')
            .replace('2', 'II')
            .replace('3', 'III')
            .toLowerCase();
        const parent = lines.find(it => {
            const newName = it.name.replace('C.C', '').replace(' DE ', '').replace(' LE ', '')
                .replace('(Cellule de restitution)', '')
                .trim().toLowerCase();
            //            console.log("NEWNAME:" + newName, parentName, JSON.stringify(it));
            return newName == parentName;
        });
        if (!parent) {
            console.log('not found', office, lines.pop(), parentName);
            throw new Error('not found');
            break;
        } else {
            office.parent = parent.code;
        }
    } else if (office.name.includes('C.C')) {
        office.parent = '500';
    } else {
        console.log('what todo', office);
    }
    lines.push(office);
}

console.log('INSERT INTO offices (id, name, parent) VALUES ' + lines.map(it => `(${it.code},'${it.name.replace("'", "''")}',${it.parent || 'NULL'})`).join(',') + ';');
*/
// hello

const options = citites.map(it => ({ label: it.c + ', ' + it.g, value: it.id1 + '-' + it.id2 }));
const insuranceOptions = [{ label: 'Selectioner' }, { label: 'CNSS', value: 'cnss' }, { label: 'CNRPS', value: 'cnrps' }];

/*
function useForm(pairs:[string,string][],callback:any){

    const debounce = 
    const out:any[] = [];
    for(const pair of pairs) {
        const [state, setState] = useState(pair[1]);
        out.push([state, (ev: any) => {
            setState(ev.target.value);


            // dispatch({collection, type: 'mutate', id,  data: {[pair[0]]: ev.target.value}})
        }]);
    }
    return out;
}*/


export function FileUpload() {

    return html`<form class="dropzone dropzone-multiple p-0" id="my-awesome-dropzone" data-dropzone="data-dropzone" action="#!">
    <div class="fallback"><input name="file" type="file" multiple="multiple" /></div>
    <div class="dz-message" data-dz-message="data-dz-message"> <img class="me-2" src="../../../assets/img/icons/cloud-upload.svg" width="25" alt="" />Drop your files here</div>
    <div class="dz-preview dz-preview-multiple m-0 d-flex flex-column">
      <div class="d-flex media mb-3 pb-3 border-bottom btn-reveal-trigger"><img class="dz-image" src="../../../assets/img/generic/image-file-2.png" alt="..." data-dz-thumbnail="data-dz-thumbnail" />
        <div class="flex-1 d-flex flex-between-center">
          <div>
            <h6 data-dz-name="data-dz-name"></h6>
            <div class="d-flex align-items-center">
              <p class="mb-0 fs-10 text-400 lh-1" data-dz-size="data-dz-size"></p>
              <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress=""></span></div>
            </div><span class="fs-11 text-danger" data-dz-errormessage="data-dz-errormessage"></span>
          </div>
          <div class="dropdown font-sans-serif"><button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal dropdown-caret-none" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="fas fa-ellipsis-h"></span></button>
            <div class="dropdown-menu dropdown-menu-end border py-2"><a class="dropdown-item" href="#!" data-dz-remove="data-dz-remove">Remove File</a></div>
          </div>
        </div>
      </div>
    </div>
  </form>`;
}

export function UserForm(userData?: { user: { data?: User, roles: Role[], office: Office } }) {

    const user2 = userData?.user || ({} as any);

    const { data: user, roles: rolesData, office: officeData } = user2;

    const [name, setName] = useInput(user?.name || '');
    const [nameAr, setNameAr] = useInput(user?.name_ar || '');

    const [roles, setRoles] = useState<RoleOption[]>((rolesData?.map(it => ({ label: it.name, value: it.id })) || []));
    const [gender, setGender] = useState('male');
    const [password, setPassword] = useInput(undefined);
    const [status, setStatus] = useState(user?.status ? { label: t(user.status), value: user.status } : { label: t('Active'), value: 'active' });

    const [identifier, setIdentifier] = useInput(user?.identifier || '');
    const [office, setOffice] = useState<any>(officeData ? { label: officeData.id + ' - ' + officeData.name, value: officeData.id } : null);

    const [validated, setValidated] = useState(false);
    const [state, dispatch] = useSession();



    const onSubmit = async (ev: Event) => {
        try {
            ev.preventDefault();
            ev.stopPropagation();
            setValidated(true);
            if ((ev.target as any).checkValidity()) {


                const data: any = {
                    new_user: {
                        identifier, name, password: password || undefined, status: status.value, office_id: office.value,
                        name_ar: nameAr,
                    },
                    roles: roles.map(it => it.value),
                };


                if (user?.id) {

                    Api.patch('/users', user.id, data, state.token).then((user) => {
                        toast.success(t('User updated successfully'));
                        route('/users/' + user.id);
                    });

                } else {
                    Api.post('/users', data, state.token).then((user) => {

                        toast.success(t('User added successfully'));
                        route('/users/' + user.id);
                    });
                }

            }
        } catch (e: any) {
            alert(e.message);
        }

    };

    const loadOptionsOffices = (inputValue: string) => {
        return Api.search('/offices', inputValue, 1, {}, state.token)
            .then((data) => {
                return data.data.map((it: any) => ({ label: it.id + ' - ' + it.name, value: it.id }));
            });
    }

    const handleCreateOffice = async (inputValue: string) => {
        alert("TODO");
    }

    console.log('roles', roles);

    return html`<form onsubmit="${onSubmit}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-6">
        <label class="form-label" for="last-name">${t('Name')}</label>
        <input required pattern=".{3,}" class="form-control" id="last-name" type="text" oninput="${setName}"
            value="${name}" />
    </div>
        <div class="col-lg-6">
        <label class="form-label" for="last-name">${t('Name')} ${t('Arabic')}</label>
        <input required pattern=".{3,}" class="form-control" id="last-name" type="text" oninput="${setNameAr}"
            value="${nameAr}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="first-name">${t('Unique Identifier')}</label>
        <input required pattern=".{3,}" class="form-control" id="first-name" type="text" oninput="${setIdentifier}"
            value=${identifier} />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="last-name">${t('Password')}</label>
        <input ${userData ? '' : 'required'} pattern=".{6,}" class="form-control" id="last-name" type="text" oninput="${setPassword}"
            value="${password}" />
    </div>
    <!--<div class="col-lg-6">
        <div class="row">
            <div class="col">qsd
                <label class="form-label">Sexe</label>
                <div class="row" style="padding-left:40px">
                    <div onclick="${() => setGender('male')}" class="col form-check mb-0 lh-1">
                        <input class="form-check-input" type="radio" value="male" id="tag-everyone"
                            checked="${gender == 'male' ? 'checked' : ''}" name="tag-settings" required />
                        <label class="form-check-label mb-0" for="tag-everyone">Homme
                        </label>
                    </div>
                    <div onclick="${() => setGender('female')}" class="col form-check mb-0 lh-1">
                        <input class="form-check-input" type="radio" value="female" id="group-members"
                            checked="${gender == 'female' ? 'checked' : ''}" name="tag-settings" required />
                        <label class="form-check-label mb-0" for="group-members">Femme
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
    <div class="col">
                <label class="form-label">Roles</label>
                <${Select} isMulti  value=${roles} options=${ROLES} onChange=${setRoles}
                    className="rs form-control ${validated && (roles.length ? 'is-valid' : 'is-invalid')}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="email3">${t('Office')}</label>
        <${AsyncCreatableSelect} 
            getOptionLabel=${option => option.__isNew__ ? t('Add') + ` '${option.value}'` : option.label} cacheOptions defaultOptions
        isClearable onCreateOption=${handleCreateOffice} loadOptions=${loadOptionsOffices}  value=${office} onChange="${setOffice}"
            className="rs form-control ${validated && (office ? 'is-valid' : 'is-invalid')}" />
    </div>
        <div class="col">
                <label class="form-label">${t('Status')}</label>
                <${Select}  value=${status} options=${[{ value: 'active', label: t('Active') }, { value: 'inactive', label: t('Inactive') }]} onChange=${setStatus}
                    className="rs form-control ${validated && (status ? 'is-valid' : 'is-invalid')}" />
    </div>
    <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-primary" type="submit"> ${user?.id ? t('Update') : t('Create')}</button>
    </div>
</form>`;
}


export function AddUser() {
    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">${t('Add')} ${t('User')}</h5>
            </div>
            <div class="card-body bg-light">
                <${UserForm} />
            </div>
        </div>
    </div>
</div>`;
}

export function UpdateUser({ id }: { id: string }) {
    const [session] = useSession();


    const [user, setUser] = useState();


    useEffect(() => {
        if (id) {
            Api.get('/users', id, session.token as any).then((data) => {
                setUser(data);
            });
        }
    }, []);

    if (user) {

        return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">Update User</h5>
            </div>
            <div class="card-body bg-light">
                <${UserForm} user=${user} />
            </div>
        </div>
    </div>
</div>`;
    } else {
        return html`<div>Loading</div>`;
    }
}


export function UsersOverview(props: any) {

    const [session, dispatch] = useSession();

    const [users, setUsers] = useState<User[]>([]);
    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;
    // const allUsers = searchObjects(session.users, q, sort, dir, page);
    // const users = allUsers.slice((page - 1) * 20, page * 20);

    const onSearch = (q?: any) => {
        Api.search('/users', q || '', 1, {}, session.token).then(({ data }: { data: User[] }) => {
            setUsers(data);
            //dispatch({ type: 'set', collection: 'users', data: users });
        });
    };

    useEffect(() => {
        onSearch();
        dispatch({ type: 'onSearch', data: onSearch });
    }, []);


    const onDelete = (user: User) => {
        setUsers(users.filter(it => it.id != user.id));
    };

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t('Users')}</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a href="/users-add" class="btn btn-falcon-default btn-sm" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Add')}</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('identifier')}
                            class="sort ${sort.dir.identifier}" style="width:10px" data-sort>
                            ${t('Unique Identifier')}</th>
                        <th onclick=${onSort('name')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.name}" data-sort>${t('Name')}</th>
                        <th onclick=${onSort('name_ar')} class="sort pr-1 align-middle white-space-nowrap ${sort.dir.name_ar}" data-sort>${t('Name')} ${t('Arabic')}</th>
                      <!--  <th onclick=${onSort('role')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.role}" data-sort>Email
                        </th> -->
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Roles')}</th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5 desktop" data-sort="address"
                            style="min-width: 200px;">${t('Status')}</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${users.map(it => UserRow(it, onDelete))}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${users.length} page=${page}/>
</div>`;
}


export interface User {
    name_ar?: string;
    id: number;
    identifier: string;
    name: string;
    roles: string[];
    status: string;
    password: string;
    office_id: number;
}

function diff(a: any, base: any) {
    const out: any = {};
    for (const key in a) {
        if (a[key] != base[key]) {
            out[key] = a[key];
        }
    }
    return out;
}

export function UserRow(user: User, onDelete: any) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);

    const [state, dispatch] = useSession();

    function onDeleteClick(user: User) {
        Api.remove('/users/' + user.id, state.token).then(() => {
            onDelete(user);
            toast.success(t('User removed successfully'));

        });
    };

    return html`<tr class="btn-reveal-trigger">
    <td class="no align-middle white-space-nowrap">${user.identifier}</td>
    <td class="name align-middle white-space-nowrap">
        <a href="/users/${user.id}">
            <h6>${user.name}</h6>
        </a>
    </td>
    <td class="email align-middle white-space-nowrap">${user.name_ar}</td>
    <td class="phone align-middle white-space-nowrap desktop">${(user.roles || []).map(t).join(',')}</td>
    <td class="address align-middle white-space-nowrap pl-5 desktop">${t(user.status)}</td>
<!--    <td class="joined align-middle desktop">${user.status}</td> -->
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(user)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}

export interface Office {
    id: number;
    name: string;
    parent: string;
    code: number;
}

function NoValue(label: string) {
    return html`<p class="font-italic text-400 mb-0">${label}</p>`;
}

function Value({ label, value }: { label: string, value: string }) {
    return value ? (html`${value}`) : NoValue(label);
}


export interface Role {
    id: number;
    name: string;
}

export const ROLES: RoleOption[] = [
    { value: 1, label: 'Admin' },
    { value: 2, label: 'Directeur' },
    { value: 3, label: 'Agent' },
    { value: 4, label: 'Agent Acceuil' },
];

export interface RoleOption {
    label: string;
    value: number;
}

export function UserDetails({ id }: { id?: string }) {

    const [session, dispatch] = useSession();

    const [offices, setOffices] = useState<any[]>([]);

    const [user, setUser] = useState<{ data: User, office: Office, roles: Role[] } | undefined>();

    useEffect(() => {
        if (id) {
            Api.get('/users', id, session.token as any).then((data) => {
                setUser(data);
            });
        }

        Api.search('/offices', '', 1, {}, session.token).then((data) => {
            setOffices(data.data);
        });
    }, []);
    /*
    const saveNotes:any = useRef(debounce(function(notes:string){
        dispatch({type:'mutate',collection: 'users',id, data:{notes} });
    },4000)).current;
*/
    const setNotes = (ev: any) => dispatch({ type: 'mutate', collection: 'users', id, data: { notes: ev.target.value }, debounce: true });
    if (user) {

        return html`<div class="card mb-3">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h5 class="mb-0">#${user.data.name}</h5>
            </div>

            <div class="col-auto"><a class="btn btn-falcon-default btn-sm" href="/users/${id}/edit">
                    <${FontAwesomeIcon} icon=${['fas', 'pencil-alt']} /> ${t('Update')}</a></div>
        </div>
    </div>
    <div class="card-body bg-light border-top">
        <div class="row">
            <div class="col-lg col-xxl-5">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Name')}</p>
                    </div>
                    <div class="col">${user.data.name}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Name')} ${t('Arabic')}</p>
                    </div>
                    <div class="col">${user.data.name_ar}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Unique Identifier')}</p>
                    </div>
                    <div class="col">${user.data.identifier}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Office')}</p>
                    </div>
                    <div class="col">${user.data.office_id} - ${user.office.name}</div>
                </div>

            </div>
            <div class="col-lg col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Roles')}</p>
                    </div>
                    <div class="col">${user.roles.map(it => t(it.name)).join(', ')}</div>
                </div>
            </div>
        </div>
    </div>
</div>
`;

    } else {
        return html`<div>User ${id} not found</div>`;
    }
}
