// import "preact/debug";
import 'react-datetime/css/react-datetime.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { faHome, faPlus, faEllipsisH, fas } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import 'bootstrap';
import 'react-toastify/dist/ReactToastify.css';

library.add(faPlus, faEllipsisH, fas);



import { h, render, Component, createContext } from 'preact';
import { html } from 'htm/preact';
import { UrlPattern } from './routing';
import { ForgotPassword, login, Logout, register, RegisterMsg, ResetPassword } from "./auth";
import { useContext, useEffect, useReducer, useState } from "preact/hooks";
import { Main } from './dashboard';
import { AddPatient, PatientsOverview } from './patients';
import { Api, Session, SessionContext, StateProvider, url, useSession } from './store';
import { Mutation } from './shared/core';
import { ToastContainer } from 'react-toastify';
import { Router } from 'preact-router';
import { PrescriptionPublicView } from './drugs';


import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

/*Sentry.init({
    dsn: "https://daeb752e8081465a9107a9e97d478a67@o341344.ingest.sentry.io/5755253",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
*/


type Setter = { type: 'setter', setter: (model: Model, value: any) => void, value: any }

type Msg = { type: 'Login', value: login.Msg }
    | { type: 'Register', value: RegisterMsg };


const f: Mutation = {} as any;
export class Model {
    login = new login.Model();
    logged?: Logged;
}

export type Role = 'regular' | 'manager' | 'admin';

export interface Trip {
    destination: string;
    startDate: number;
    endDate: number;
    comment: string;
}

export interface Logged {
    trips: Trip[];
}


// view.bind([])

/*
<!doctype html>
<html lang="en">
  <head>
  	<title>Sidebar 05</title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <link href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900" rel="stylesheet">
		
		<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
		<link rel="stylesheet" href="css/style.css">
  </head>
  <body>*/

  const cssText = require("bundle-text:./assets/explorer/css/style.css");


if (window.location.pathname.startsWith('/explorer')) {

    // add css for explorer
    let style = document.createElement('style');
    style.textContent = cssText;
    document.head.appendChild(style);

}


function Explorer({id}: {id: string}) {


    const [data, setData] = useState({files:[],folder:{}});

    const [selected, setSelected] = useState<File|null>(null);

    useEffect(() => {
        Api.get('/folders',`${id}/public`,undefined as any)
            .then((res)=>{
                res.files = res.files.sort();
                setData(res);
                setSelected(res.files[0]);
            })

    },[]);

    console.log(data);

    const onClick = (file) => {
        setSelected(file);
    }

    const getName = (file) => {
        // file.name.split('SMSI -')[1].split('.')[0]?.replace('VC-v1','')
        if(file.name.includes('SMSI -')) {
            return file.name.split('SMSI -')[1].split('.')[0]?.replace('VC-v1','');
        } else {
            return file.name.split('.')[0];
        }
    }

    return html`
		
		<div style=${data?'':'display:none' } dir="ltr" class="wrapper d-flex align-items-stretch">
			<nav id="sidebar" style="width:500px;max-width:500px">
				<div class="custom-menu">
					<!--<button type="button" id="sidebarCollapse" class="btn btn-primary">
	          <i class="fa fa-bars"></i>
	          <span class="sr-only">Toggle Menu</span>
	        </button>-->
        </div>
				<div style="padding-left:0px !important">
		  		<h1 class="p-3" style="height:120px;color:white;font-size:24px;line-height:1.3">${data.folder.name}</h1>
	        <ul class="list-unstyled components mb-5" style="height:calc(100vh - 140px);overflow-y:scroll">
	          ${data.files.map(file=>html`<li class="${file.id == selected?.id ? 'active':''}">
	            <a onClick=${()=>onClick(file)}><span class="fa fa-sticky-note mr-2"></span>${getName(file)}</a>
	          </li>`)}
	        </ul>


	      </div>
    	</nav>

        <!-- Page Content  -->
      <div id="content">
      ${selected ?html`
            <embed src=${url+'/folders/'+data.folder.id+'/public/'+selected.id}
                    type="application/pdf"
                    width='100%' style="height:100vh" >
            </embed>`: ''}
          </div>
		</div>
`;

}


// useEffect(() => {
//sync(state, dispatch);

function View() {


    // dispatch(['put', 'Patient', 'sqdqsd', { firstName: "hello" }])
    // }, [state]);

    /*
            // Advanced is an optional query
        // <Search path="/search/:query/:advanced?" />
*/
    /*
    
    */

    console.log('view')

    return html`<${StateProvider}>
    <${Router}>
        <${Main} default />
        <${login.ViewSplit} path="/login" />
        <${register.ViewSplit} path="/register" />
        <${Logout} path="/logout" />
        <${ForgotPassword} path="/forgot-password" />
        <${ResetPassword} path="/reset-password/:token" />
        <${PrescriptionPublicView} path="/p/:id" />
        <${Explorer} path="/explorer/:id" />
    </${Router}>

    <${ToastContainer} position="bottom-right" autoClose=${5000} hideProgressBar=${false} newestOnTop=${false}
        closeOnClick rtl=${false} pauseOnFocusLoss draggable pauseOnHover />
</${StateProvider}>`;
}



render(html`<${View} />`, document.body)
