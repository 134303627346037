import { html } from "htm/preact";
import { generateId, getPage, Pagination, t, useInput } from "./core";
import { useContext, useEffect, useRef, useState } from "preact/hooks";

import Select from 'react-select'
import { citites, socialSecurityOptions } from "./data";

import { Api, debounce, filesStore, getDownloadUrl, merge, searchPatients, SessionContext, SFile, state, useSession } from "./store";
import { Billable, Patient } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as storage from 'idb-keyval';
import CreatableSelect from 'react-select/creatable';
import { Drive } from "./products";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Checkbox } from "./ui";
import { PaymentStatus } from "./settings";
import { Button, Modal } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { ChangeOwnership, Share } from "./procedures";

// hello

const options = citites.map(it => ({ label: it.c + ', ' + it.g, value: it.id1 + '-' + it.id2 }));
const insuranceOptions = [{ label: 'Selectioner' }, { label: 'CNSS', value: 'cnss' }, { label: 'CNRPS', value: 'cnrps' }];

/*
function useForm(pairs:[string,string][],callback:any){

    const debounce = 
    const out:any[] = [];
    for(const pair of pairs) {
        const [state, setState] = useState(pair[1]);
        out.push([state, (ev: any) => {
            setState(ev.target.value);


            // dispatch({collection, type: 'mutate', id,  data: {[pair[0]]: ev.target.value}})
        }]);
    }
    return out;
}*/

export function PatientForm({ patient }: { patient?: Patient }) {

    const [firstName, setFirstName] = useInput(patient?.firstName || '');
    const [lastName, setLastName] = useInput(patient?.lastName || '');
    const [birthdate, setBirthdate] = useInput(patient?.birthdate || '');
    const [gender, setGender] = useState(patient?.gender || '');
    const [bloodType, setBloodType] = useState(patient?.bloodType);

    const [validated, setValidated] = useState(false);
    const [city, setCity] = useState(patient?.city || {} as any);
    const [insurance, setInsurance] = useState(patient?.insurance);
    const [insuranceId, setInsuranceId] = useInput(patient?.insuranceId);
    const [insuranceDate, setInsuranceDate] = useInput(patient?.insuranceDate);

    const [phones, setPhones] = useState(patient?.phones || []);

    const [state, dispatch] = useSession();



    const onSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {

            const id = patient?._id || generateId(state.user?.organizationId || '');

            const data: any = {
                firstName, lastName, birthdate, gender, phones, insurance, insuranceId,
                city: { label: city.label, value: city.value }
            };

            if (!patient?._id) {
                data.no = (state.patients.sort((a, b) => (a.no || 0) > (b.no || 0) ? -1 : 1)[0]?.no || 0) + 1;
            }

            dispatch({
                type: 'mutate', collection: 'patients', id,
                data,
            });

            toast.success(patient?._id ? 'Patient mise à jour' : 'Patient ajouté');
            route('/patients/' + id);
            /* DB.put('Patient',
                 randomString(9),
                 {
                     firstName,
                     lastName,
                     birthdate,
                     gender,
                     city,
                 }); */
        }

    };


    function handleCreate(value: any) {
        dispatch({
            type: 'mutate',
            collection: 'options',
            id: generateId(state.user?.organizationId || ''),
            data: { type: 'insurance', value },
        });
        setInsurance({ label: value, value });
    };

    const allSocialSecurityOptions = socialSecurityOptions
        .concat((state.options)
            .filter(it => it.type == 'insurance')
            .map(it => ({ label: it.value, value: it.value })));

    return html`<form onsubmit="${onSubmit}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-6">
        <label class="form-label" for="first-name">Prénom</label>
        <input required pattern=".{3,}" class="form-control" id="first-name" type="text" oninput="${setFirstName}"
            value=${firstName} />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="last-name">Nom</label>
        <input required pattern=".{3,}" class="form-control" id="last-name" type="text" oninput="${setLastName}"
            value="${lastName}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="email1">Date de naissance</label>
        <input class="form-control" id="email1" type="date" value="${birthdate}" oninput="${setBirthdate}" required />
        <div class="invalid-feedback">At least 8 characters</div>

    </div>
    <div class="col-lg-6">
        <div class="row">
            <div class="col">
                <label class="form-label">Sexe</label>
                <div class="row" style="padding-left:40px">
                    <div onclick="${() => setGender('male')}" class="col form-check mb-0 lh-1">
                        <input class="form-check-input" type="radio" value="male" id="tag-everyone"
                            checked="${gender == 'male' ? 'checked' : ''}" name="tag-settings" required />
                        <label class="form-check-label mb-0" for="tag-everyone">Homme
                        </label>
                    </div>
                    <div onclick="${() => setGender('female')}" class="col form-check mb-0 lh-1">
                        <input class="form-check-input" type="radio" value="female" id="group-members"
                            checked="${gender == 'female' ? 'checked' : ''}" name="tag-settings" required />
                        <label class="form-check-label mb-0" for="group-members">Femme
                        </label>
                    </div>
                </div>
            </div>
            <div class="col">
                <label class="form-label">Groupe Sanguin</label>
                <${Select} getOptionLabel=${option => option} getOptionValue=${option => option}  value=${[bloodType]}
                    options=${['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-']} onChange=${setBloodType}
                    className="rs form-control ${validated && (bloodType ? 'is-valid' : 'is-invalid')}" />
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="phones-id">Numéro de téléphone</label>
        <input pattern=".{3,}" class="form-control" id="phones-id" type="text" oninput="${(ev: any) => setPhones(ev.target.value.split(';'))}"
            value="${phones?.join(';')}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="email3">Ville</label>
        <${Select} options=${options} value="${city}" onChange="${setCity}"
            className="rs form-control ${validated && (city ? 'is-valid' : 'is-invalid')}" />

    </div>
    <div class="col-lg-6">
        <label class="form-label" for="insurance">Carnet de soins</label>
        <${CreatableSelect} isClearable options=${allSocialSecurityOptions} value="${insurance}" 
            onChange="${setInsurance}" onCreateOption=${handleCreate} 
            className="rs form-control ${validated && (insurance ? 'is-valid' : 'is-invalid')}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="insurance-id">Numéro du carnet de soins</label>
        <input pattern=".{3,}" class="form-control" id="insurance-id" type="text" oninput="${setInsuranceId}"
            value="${insuranceId}" />
    </div>
    <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-primary" type="submit"> ${patient?._id ? 'Update' : 'Créer'}</button>
    </div>
</form>`;
}


export function AddPatient() {
    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">Ajouter Patient</h5>
            </div>
            <div class="card-body bg-light">
                <${PatientForm} />
            </div>
        </div>
    </div>
</div>`;
}

export function UpdatePatient({ id }: { id: string }) {
    const [session] = useSession();
    const patient = session.patients.find(it => it._id == id);
    if (patient) {
        console.log('PATIENT111', id, session.patients.find(it => it._id == id))
        return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">Update Patient</h5>
            </div>
            <div class="card-body bg-light">
                <${PatientForm} patient=${patient} />
            </div>
        </div>
    </div>
</div>`;
    } else {
        return html`<div>Loading</div>`;
    }
}


export function PatientsOverview(props: any) {

    const [session] = useSession();

    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;
    const allPatients = searchPatients(session.patients, q, sort, dir, page);
    const patients = allPatients.slice((page - 1) * 20, page * 20);

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Patients</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a href="/patients-add" class="btn btn-falcon-default btn-sm" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Ajouter</span></a>
                            <!--
                    <a class="btn btn-falcon-default btn-sm mx-2" type="button"><span class="fas fa-filter"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Filter</span></a>
                    <a class="btn btn-falcon-default btn-sm" type="button"><span class="fas fa-external-link-alt"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Export</span></a>
-->
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                    <!--
                        <th>
                            <div class="form-check fs-0 mb-0 d-flex align-items-center">
                                <input class="form-check-input" id="checkbox-bulk-customers-select" type="checkbox"
                                    data-bulk-select='{"body":"table-customers-body","actions":"table-customers-actions","replacedElement":"table-customers-replace-element"}' />
                            </div>
                        </th>-->
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('no')}
                            class="sort ${sort.dir.no}" style="width:10px" data-sort>
                            N°</th>
                        <th onclick=${onSort('firstName')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.firstName}" data-sort>
                            Nom</th>
                      <!--  <th onclick=${onSort('email')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.email}" data-sort>Email
                        </th> -->
                        <th class="sort align-middle white-space-nowrap desktop" >Téléphone</th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5 desktop" data-sort="address"
                            style="min-width: 200px;">Addresse</th>
                        <th onclick=${onSort('birthdate')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.birthdate}  desktop" data-sort="joined">
                            Date de naissance</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${patients.map(PatientRow)}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${allPatients.length} page=${page}/>
</div>`;
}


export function PatientRow(patient: Patient) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, [])

    const [state, dispatch] = useSession();


    function onDeleteClick(patient: Patient) {
        dispatch({
            type: 'mutate',
            collection: 'patients',
            id: patient._id,
            data: { deletedAt: Date.now() },
        });
    };

    return html`<tr class="btn-reveal-trigger">
    <!--
    <td class="align-middle py-2" style="width: 28px;">
        <div class="form-check fs-0 mb-0 d-flex align-items-center">
            <input class="form-check-input" type="checkbox" id="customer-0"
                data-bulk-select-row="data-bulk-select-row" />
        </div>
    </td>
    -->
    <td class="no align-middle white-space-nowrap">${patient.no?.toString().padStart(5, '0')}</td>
    <td class="name align-middle white-space-nowrap"><a href="/patients/${patient._id}">
                    <h6>${patient.firstName} ${patient.lastName}</h6>
        </a></td>
<!--    <td class="email align-middle py-2">${patient.email}</td> -->
    <td class="phone align-middle white-space-nowrap desktop">${(patient.phones || [])[0]}</td>
    <td class="address align-middle white-space-nowrap pl-5 desktop">${patient.address} ${patient.city?.label}</td>
    <td class="joined align-middle desktop">${patient.birthdate}</td>
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(patient)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}


export function UnpaidPatientRow(patient: Patient & { unpaid: number, paymentStatus?: string, lastVisitAt: string }) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);

    const setPaymentStatus = event => {
        console.log("SET", event.target.value);
        dispatch({
            type: 'mutate', collection: 'patients', id: patient._id,
            data: { paymentStatus: event.target.value },
        });
    };

    const [state, dispatch] = useSession();

    const options = (state.organizations[0].paymentStatusOptions || []) as PaymentStatus[];
    const getColor = id => options.find(it => it.id == id)?.color;

    console.log("STATUS", patient.paymentStatus);

    return html`<tr class="btn-reveal-trigger">
    <!--
    <td class="align-middle py-2" style="width: 28px;">
        <div class="form-check fs-0 mb-0 d-flex align-items-center">
            <input class="form-check-input" type="checkbox" id="customer-0"
                data-bulk-select-row="data-bulk-select-row" />
        </div>
    </td>
    -->
    <td class="no align-middle white-space-nowrap py-2">${patient.no?.toString().padStart(5, '0')}</td>
    <td class="name align-middle white-space-nowrap py-2"><a href="/patients/${patient._id}">
            <div class="d-flex d-flex align-items-center">
                <!--<div class="avatar avatar-xl mr-2">
                    <div class="avatar-name rounded-circle"><span>OG</span></div>
                </div>-->
                <div class="flex-1">
                    <h5 class="mb-0 fs--1">${patient.firstName} ${patient.lastName}</h5>
                </div>
            </div>
        </a></td>
<!--    <td class="email align-middle py-2">${patient.email}</td> -->
    <td class="phone align-middle white-space-nowrap py-2 desktop">${(patient.phones || [])[0]}</td>
    <td class="address align-middle white-space-nowrap pl-5 py-2 desktop">${patient.address} ${patient.city?.label}</td>
    <td class="joined align-middle py-2 desktop">${patient.lastVisitAt}</td>
    <td class="joined align-middle py-2">${patient.unpaid}</td>
    <td class="joined align-middle py-2">
        <select class="form-select form-select-sm select-month me-2 bg-${getColor(patient.paymentStatus)} text-light" onChange="${setPaymentStatus}">
            <option></option>
            ${((state.organizations[0].paymentStatusOptions || []) as PaymentStatus[])
            .map(option => html`<option class="bg-${option.color}  text-light" value=${option.id} selected=${option.id == patient.paymentStatus}>${option.label}</option>`)}
        </select>
    </td>
</tr>`;
}

function NoValue(label: string) {
    return html`<p class="font-italic text-400 mb-0">${label}</p>`;
}

function Value({ label, value }: { label: string, value: string }) {
    return value ? (html`${value}`) : NoValue(label);
}

export function PatientDetails({ id }: { id?: string }) {

    const [session, dispatch] = useSession();
    const patient = session.patients.find(it => it._id == id);
    const [show, setShow] = useState(false);

    /*
    const saveNotes:any = useRef(debounce(function(notes:string){
        dispatch({type:'mutate',collection: 'patients',id, data:{notes} });
    },4000)).current;
*/
    const setNotes = (ev: any) => dispatch({ type: 'mutate', collection: 'patients', id, data: { notes: ev.target.value }, debounce: true });
    if (patient) {

        return html`<div class="card mb-3">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h5 class="mb-0">#${patient.no?.toString().padStart(5, '0')}</h5>
            </div>

            <div class="col-auto dropdown">
                <button onclick=${() => setShow(!show)} class="btn btn-falcon-default btn-sm dropdown-toggle ${show ? 'show' : ''}">
                <${FontAwesomeIcon} icon=${['fas', 'stamp']} /> Certificat</button>
                    <div class="dropdown-menu dropdown-menu-end py-0 ${show ? 'show' : ''}" aria-labelledby="dropdownMenuButton" style="position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate(0px, 38px);" data-popper-placement="bottom-end">
                        <a class="dropdown-item" href="/patients/${id}/medical-certificate">Médical</a>
                        <a class="dropdown-item" href="/patients/${id}/attendance-certificate">Présence</a>
                    </div>
            </div>    
            
            <div class="col-auto"><a class="btn btn-falcon-default btn-sm" href="/patients/${id}/exams">
                    <${FontAwesomeIcon} icon=${['fas', 'vial']} /> Demander des examens</a></div>

            <div class="col-auto"><a class="btn btn-falcon-default btn-sm" href="/patients/${id}/prescribe">
                    <${FontAwesomeIcon} icon=${['fas', 'pills']} /> Prescrire un traitement</a></div>

            <div class="col-auto"><a class="btn btn-falcon-default btn-sm" href="/patients/${id}/edit">
                    <${FontAwesomeIcon} icon=${['fas', 'pencil-alt']} /> Mettre à jour les détails</a></div>
        </div>
    </div>
    <div class="card-body bg-light border-top">
        <div class="row">
            <div class="col-lg col-xxl-5">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Prénom</p>
                    </div>
                    <div class="col">${patient.firstName}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Nom</p>
                    </div>
                    <div class="col">${patient.lastName}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Né(e) le</p>
                    </div>
                    <div class="col">${patient.birthdate}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Téléphone</p>
                    </div>
                    <div class="col">
                        <${Value} label="Pas de téléphone" value=${patient.phones?.join(';')} />
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-0">Addresse</p>
                    </div>
                    <div class="col">
                        <${Value} label="Pas d'addresse" value=${patient.address || patient.city?.label} />
                    </div>
                </div>
            </div>
            <div class="col-lg col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                <label style="width:100%" class="form-label" for="notes">Observations</label>
                <textarea class="form-control" id="notes" rows="4" value=${patient.notes} oninput="${setNotes}"></textarea>
            </div>
        </div>
    </div>

</div>
<${BillablesOverview} patient=${patient} />

<${Drive} idName="patientId" objectId=${patient._id} />
<!--
<div class="card mb-3">
    <div class="card-header">
        <h5 class="mb-0">Logs</h5>
    </div>
    <div class="card-body border-top p-0">
        <div class="row g-0 align-items-center border-bottom py-2 px-3">
            <div class="col-md-auto pr-3"><span class="badge badge-soft-success rounded-pill">200</span></div>
            <div class="col-md mt-1 mt-md-0"><code>POST /v1/invoiceitems</code></div>
            <div class="col-md-auto">
                <p class="mb-0">2019/02/23 15:29:45</p>
            </div>
        </div>
        <div class="row g-0 align-items-center border-bottom py-2 px-3">
            <div class="col-md-auto pr-3"><span class="badge badge-soft-warning rounded-pill">400</span></div>
            <div class="col-md mt-1 mt-md-0"><code>POST /v1/invoiceitems</code></div>
            <div class="col-md-auto">
                <p class="mb-0">2019/02/19 21:32:12</p>
            </div>
        </div>
        <div class="row g-0 align-items-center border-bottom py-2 px-3">
            <div class="col-md-auto pr-3"><span class="badge badge-soft-success rounded-pill">200</span></div>
            <div class="col-md mt-1 mt-md-0"><code>POST /v1/invoices/in_1Dnkhadfk</code></div>
            <div class="col-md-auto">
                <p class="mb-0">2019/02/26 12:23:43</p>
            </div>
        </div>
        <div class="row g-0 align-items-center border-bottom py-2 px-3">
            <div class="col-md-auto pr-3"><span class="badge badge-soft-success rounded-pill">200</span></div>
            <div class="col-md mt-1 mt-md-0"><code>POST /v1/invoices/in_1Dnkhadfk</code></div>
            <div class="col-md-auto">
                <p class="mb-0">2019/02/12 23:32:12</p>
            </div>
        </div>
        <div class="row g-0 align-items-center border-bottom py-2 px-3">
            <div class="col-md-auto pr-3"><span class="badge badge-soft-danger rounded-pill">404</span></div>
            <div class="col-md mt-1 mt-md-0"><code>POST /v1/invoices/in_1Dnkhadfk</code></div>
            <div class="col-md-auto">
                <p class="mb-0">2019/02/08 02:20:23</p>
            </div>
        </div>
        <div class="row g-0 align-items-center border-bottom py-2 px-3">
            <div class="col-md-auto pr-3"><span class="badge badge-soft-success rounded-pill">200</span></div>
            <div class="col-md mt-1 mt-md-0"><code>POST /v1/invoices/in_1Dnkhadfk</code></div>
            <div class="col-md-auto">
                <p class="mb-0">2019/02/01 12:29:34</p>
            </div>
        </div>
    </div>
    <div class="card-footer bg-light p-0"><a class="btn btn-link btn-block" href="#!">View more logs<span
                class="fas fa-chevron-right fs--2 ml-1"></span></a></div>
</div>
                -->
`;

    } else {
        return html`<div>Patient ${id} not found</div>`;
    }
}


export function BillablesOverview({ patient }: any) {

    const [state, dispatch] = useSession();

    const addBillable = () => {
        const today = new Date();
        const date = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
        dispatch({
            type: 'mutate',
            collection: 'billables',
            id: generateId(state.user?.organizationId || ''),
            data: { patientId: patient._id, date, price: 0, received: 0, description: '' },
        });
    };

    const billables = state.billables.filter((it: any) => it && !it.deletedAt && it.patientId == patient._id);

    const prescriptions = state.prescriptions.filter(it => it && !it.deletedAt && it.patientId == patient._id);

    for (const prescription of prescriptions) {
        let billable = billables.find(it => it.date == prescription.date);
        if (billable) {
            billable.prescriptions = prescriptions.filter(it => it.date == billable?.date);
        }
    }

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Visites</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a onclick=${addBillable} class="btn btn-falcon-default btn-sm" type="button">
                        <${FontAwesomeIcon} icon=${['fas', 'plus']} />
                        <span class="d-none d-sm-inline-block ml-1">Ajouter</span></a>
                        <!--
                    <a class="btn btn-falcon-default btn-sm mx-2" type="button"><span class="fas fa-filter"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Filter</span></a>
                    <a class="btn btn-falcon-default btn-sm" type="button"><span class="fas fa-external-link-alt"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Export</span></a>
                        -->
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th width="120px" class="sort" data-sort="date">Date</th>
                        <th class="sort align-middle" data-sort="name">Label</th>
                        <th width="100px" class="sort align-middle" data-sort="price">Tarif</th>
                        <th width="100px" class="sort align-middle" data-sort="received">Recu</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${billables.map((billable: any) => BillableRow(billable))}

                    <tr class="btn-reveal-trigger">
    <td class="align-middle">
    </td>
    <td class="align-middle py-2">
    </td>
    <td class="align-middle py-2">
    <strong> ${billables.reduce((p, n) => p + n.price, 0)} DT </strong>
    </td>
    <td class="align-middle py-2">
    <strong> ${billables.reduce((p, n) => p + n.received, 0)} DT</strong> 
    </td>
    <td class="align-middle white-space-nowrap py-2 text-right">
    Reste: <strong> ${billables.reduce((p, n) => p + n.price, 0) - billables.reduce((p, n) => p + n.received, 0)}  DT</strong>
    </td>

</tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer d-flex align-items-center justify-content-center">
        <button class="btn btn-sm btn-falcon-default mr-1" type="button" title="Previous"
            data-list-pagination="prev"><span class="fas fa-chevron-left"></span></button>
        <ul class="pagination mb-0"></ul>
        <button class="btn btn-sm btn-falcon-default ml-1" type="button" title="Next" data-list-pagination="next"><span
                class="fas fa-chevron-right"></span></button>
    </div>
</div>`;
}



export function BillableRow(billable: Billable) {

    const [state, dispatch] = useSession();
    /*
        const [billable,setBillable] = useState(billable1);
    
        useEffect(()=>{
            merge(billable,billable1);
            setBillable({...billable});
        },[state]);
      */
    const handler = (ev, field: string) => {
        //    setDirty(true);
        const data = { [field]: ev.target.value };
        //  setData(newData);
        if (field == 'price' || field == 'received') {
            data[field] = parseFloat(data[field].toString());
            if (isNaN(data[field])) {
                return;
            }
        }
        // setBillable({...billable,...data});
        dispatch({ type: 'mutate', collection: 'billables', data, id: billable._id, debounce: true });

    };


    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, [])


    function onDeleteClick() {
        dispatch({
            type: 'mutate',
            collection: 'billables',
            id: billable._id,
            data: { deletedAt: Date.now() },
        });
    };

    return html`<tr class="btn-reveal-trigger billable">
    <td class="align-middle">
        <input width="120px" class="form-control-plaintext" type="date" value=${billable.date} oninput=${ev => handler(ev, 'date')} />
    </td>
    <td class="align-middle py-2">
        <input value=${billable.description} oninput=${ev => handler(ev, 'description')} class="form-control-plaintext" type="text" />
        <div>${billable.prescriptions
            ?.map(it => ({ _id: it._id, lines: it.lines.map(line => line.drug.label) }))
            .map(p => html`<a href="/prescriptions/${p._id}" class="prescription">${p.lines.map(it => html`<div>${it.substr(0, 80)}...</div>`)}</a>`)
        } 
        </div>
    </td>
    <td class="align-middle py-2">
        <input value=${billable.price} oninput=${ev => handler(ev, 'price')} class="form-control-plaintext" type="number" />
    </td>
    <td class="align-middle py-2">
        <input value=${billable.received} oninput=${ev => handler(ev, 'received')} class="form-control-plaintext" type="number" />
    </td>
    <td class="align-middle white-space-nowrap py-2 text-right">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white py-2">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick()}>Delete</a></div>
            </div>
        </div>
    </td>

</tr>`;
}


export function PrescriptionsOverview({ patient }: any) {

    const [state, dispatch] = useSession();

    const billables = state.billables.filter((it: any) => it && !it.deletedAt && it.patientId == patient._id);

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Visites</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a class="btn btn-falcon-default btn-sm" type="button">
                        <${FontAwesomeIcon} icon=${['fas', 'plus']} />
                        <span class="d-none d-sm-inline-block ml-1">Ajouter</span></a>

                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th width="120px" class="sort" data-sort="date">Date</th>
                        <th class="sort align-middle" data-sort="name">Label</th>
                        <th width="100px" class="sort align-middle" data-sort="price">Tarif</th>
                        <th width="100px" class="sort align-middle" data-sort="received">Recu</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${billables.map((billable: any) => PrescriptionRow(billable))}

                    <tr class="btn-reveal-trigger">
    <td class="align-middle">
    </td>
    <td class="align-middle py-2">
    </td>
    <td class="align-middle py-2">
    <strong> ${billables.reduce((p, n) => p + n.price, 0)} DT </strong>
    </td>
    <td class="align-middle py-2">
    <strong> ${billables.reduce((p, n) => p + n.received, 0)} DT</strong> 
    </td>
    <td class="align-middle white-space-nowrap py-2 text-right">
    Reste: <strong> ${billables.reduce((p, n) => p + n.price, 0) - billables.reduce((p, n) => p + n.received, 0)}  DT</strong>
    </td>

</tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer d-flex align-items-center justify-content-center">
        <button class="btn btn-sm btn-falcon-default mr-1" type="button" title="Previous"
            data-list-pagination="prev"><span class="fas fa-chevron-left"></span></button>
        <ul class="pagination mb-0"></ul>
        <button class="btn btn-sm btn-falcon-default ml-1" type="button" title="Next" data-list-pagination="next"><span
                class="fas fa-chevron-right"></span></button>
    </div>
</div>`;
}



export function PrescriptionRow(billable: Billable) {

    const [state, dispatch] = useSession();

    const update: any = useRef(debounce(function (data: any) {
        dispatch({ type: 'mutate', collection: 'billables', data, id: billable._id, debounce: true });
    }, 500)).current;

    const [data, setData] = useState(billable);

    const handler = (field: string) => {
        return (ev: any) => {
            const newData = { ...data, [field]: ev.target.value };
            setData(newData);
            newData.price = parseFloat(newData.price.toString());
            newData.received = parseFloat(newData.received.toString());
            if (!isNaN(newData.price) && !isNaN(newData.received)) {
                update(newData);
            }
        };
    };


    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, [])


    function onDeleteClick() {
        dispatch({
            type: 'mutate',
            collection: 'billables',
            id: billable._id,
            data: { deletedAt: Date.now() },
        });
    };

    return html`<tr class="btn-reveal-trigger">
    <td class="align-middle">
        <input width="120px" class="form-control-plaintext" type="date" value=${data.date} oninput=${handler('date')} />
    </td>
    <td class="align-middle py-2">
        <input value=${data.description} oninput=${handler('description')} class="form-control-plaintext" type="text" />
    </td>
    <td class="align-middle py-2">
        <input value=${data.price} oninput=${handler('price')} class="form-control-plaintext" type="number" />
    </td>
    <td class="align-middle py-2">
        <input value=${data.received} oninput=${handler('received')} class="form-control-plaintext" type="number" />
    </td>
    <td class="align-middle white-space-nowrap py-2 text-right">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white py-2">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick()}>Delete</a></div>
            </div>
        </div>
    </td>

</tr>`;
}


export function UnpaidPatientsOverview(props: any) {

    const [session] = useSession();

    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;

    const unpaidMap = session.billables.reduce((p, n) => {
        const it = p[n.patientId] || { unpaid: 0, lastVisitAt: '0' };
        p[n.patientId] = it;
        it.unpaid += n.price - n.received;
        it.lastVisitAt = (it.lastVisitAt < n.date && n.date.length == 10) ? n.date : it.lastVisitAt;
        return p;
    }, {} as any);

    const page = getPage();

    let unpaidPatients = Object.keys(unpaidMap)
        .filter(key => unpaidMap[key].unpaid)
        .map(key => ({ ...session.patients.find(it => it._id == key), _id: key, ...unpaidMap[key] }));


    const [paymentStatusOptions, setPaymentStatusOptions] = useState([] as { id: string, label: string, color: string }[]);
    if (paymentStatusOptions.length) {
        const values = paymentStatusOptions.map(it => it.id);
        unpaidPatients = unpaidPatients.filter(it => values.includes(it.paymentStatus));
    }

    const allPatients = searchPatients(unpaidPatients as any, q, sort, dir, page);
    const patients = allPatients.slice((page - 1) * 20, page * 20);

    const positiveTotal = unpaidPatients.filter(it => it.unpaid > 0).reduce((p, n) => p + n.unpaid, 0);
    const negativeTotal = unpaidPatients.filter(it => it.unpaid < 0).reduce((p, n) => p + n.unpaid, 0);



    console.log('options', paymentStatusOptions);

    const setOption = (option: PaymentStatus) => {
        return (value) => {
            const exists = paymentStatusOptions.find(it => it.id == option.id);
            if (value && !exists) {
                setPaymentStatusOptions([...paymentStatusOptions, option]);
            } else if (!value && exists) {
                setPaymentStatusOptions(paymentStatusOptions.filter(it => it.id !== option.id))
            }
        };
    };

    console.log(session.organizations[0]?.paymentStatusOptions);

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Non payées</h5>
            </div>
            <div class="col-auto ms-auto">
                    

                  ${session.organizations[0]?.paymentStatusOptions?.map(option => html`<${Checkbox} label=${option.label} value=${false} set=${setOption(option)}></${Checkbox}>`)}

                      <a href="/settings" class="btn btn-falcon-primary btn-sm" type="button" >Ajouter Status</a>
            </div>
        </div>

        
    </div>
    <div class="card-body p-0">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                    <!--
                        <th>
                            <div class="form-check fs-0 mb-0 d-flex align-items-center">
                                <input class="form-check-input" id="checkbox-bulk-customers-select" type="checkbox"
                                    data-bulk-select='{"body":"table-customers-body","actions":"table-customers-actions","replacedElement":"table-customers-replace-element"}' />
                            </div>
                        </th>-->
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('no')}
                            class="sort ${sort.dir.no}" style="width:10px" data-sort>
                            N°</th>
                        <th onclick=${onSort('firstName')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.firstName}" data-sort>
                            Nom</th>
                      <!--  <th onclick=${onSort('email')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.email}" data-sort>Email
                        </th> -->
                        <th class="sort align-middle white-space-nowrap desktop" >Téléphone</th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5 desktop" data-sort="address"
                            style="min-width: 200px;">Addresse</th>
                        <th onclick=${onSort('lastVisitAt')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.lastVisitAt} desktop" data-sort="joined">
                            Dernière visite</th>
                        <th onclick=${onSort('unpaid')} class="sort align-middle white-space-nowrap ${sort.dir.unpaid}"
                        data-sort="unpaid">Reste</th>
                        <th style="width:150px" onclick=${onSort('paymentStatus')} class="sort align-middle white-space-nowrap ${sort.dir.paymentStatus}"
                        data-sort="unpaid">Status</th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${patients.map(UnpaidPatientRow as any)}
                </tbody>
            </table>
            <div style="text-align:right;padding-right:20px"><br/>
        <strong>Total Positive: </strong> ${positiveTotal} DT;
        <strong> Total Negative: </strong> ${negativeTotal}  DT
            </div>
        </div>
    </div>
    <${Pagination} count=${allPatients.length} page=${page}/>
</div>`;
}



export function FileViewer(file: SFile) {

    const [state, dispatch] = useSession();

    const [url, setUrl] = useState('');

    useEffect(() => {
        storage.get(file._id, filesStore)
            .then(fileData => {
                setUrl(window.URL.createObjectURL(new Blob([fileData.data], { type: fileData.type })));
            })
            .catch(err => {
                if (file.uploaded) {
                    getDownloadUrl(state.token || '', file._id, file.name)
                        .then(href => {
                            setUrl(href);
                        });
                } else {
                    alert("File not found");
                }
            });
    }, []);

    const getWidth = () => {
        return (document.querySelector('.content') || {}).clientWidth || 400;
    }

    const getHeight = () => {
        return window.innerHeight - ((document.querySelector('.navbar-top') || {}).clientHeight || 70);
    }

    if ((file.type || '').indexOf('image') !== -1) {
        return html`<${TransformWrapper} style="width:500px"

      >
        ${({ zoomIn, zoomOut, resetTransform, ...rest }) => html`<div className="tools" style="position:absolute;z-index:100">
              <button onClick=${() => zoomIn()}>+</button>
              <button onClick=${() => zoomOut()}>-</button>
              <button onClick=${() => resetTransform()}>x</button>
            </div>
            <${TransformComponent}>
              <div class="image" alt=${file.name}></div>
            </${TransformComponent}>
            <style>
                div.image{
    background-image: url("${url}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width:${getWidth()}px;
    height:${getHeight()}px;
}
            </style>
            `
            }
      </${TransformWrapper}>`;
    } else {
        return html`<div>loading</div>`;
    }
}

export function FileDetails({ id }: any) {
    const [session, dispatch] = useSession();

    const [password, setPassword] = useState('');

    const [file, setFile] = useState<any>();
    const [fileData, setFileData] = useState<any>();

    const [downloadedUrl, setDownloadedUrl] = useState('');

    const [showShare, setShowShare] = useState(false);
    const [access, setAccess] = useState<any>();

    const [validated, setValidated] = useState(false);
    const [shareUser, setShareUser] = useState<any>();


    useEffect(() => {
        Api.get('/files', id, session.token as any)
            .then(file => {
                file.shares.forEach((it: any) => {
                    it.created_by_name = file.users.find((t: any) => t.id == it.created_by)?.name;
                    it.user_name = file.users.find((t: any) => t.id == it.user_id)?.name;
                    it.office_name = file.offices.find((t: any) => t.id == it.office_id)?.name;
                });
                setFile(file);
                let pass = '';
                if (file.data.password) {
                    pass = prompt(t('Enter password'));
                    setPassword(pass);
                }

                Api.getBinary(`/files/${file.data.id}/download?password=${pass}`, session.token as any)
                    .then(fileData => {
                        setFileData(fileData);
                        let fileURL = window.URL.createObjectURL(fileData);

                        setDownloadedUrl(fileURL);
                    });
            });
    }, []);

    if (file) {

        const onDownloadClick = () => {

            let pass = '';
            if (file.data.password && !password) {
                pass = prompt('Enter password');
                setPassword(pass);
            } else if (fileData) {
                let fileURL = window.URL.createObjectURL(fileData);

                setDownloadedUrl(fileURL);
                let fileLink = document.createElement('a');
                fileLink.href = fileURL;

                // suggest a name for the downloaded file
                fileLink.download = file.data.name;

                // simulate click
                fileLink.click();
                return;
            }



            Api.getBinary(`/files/${file.data.id}/download?password=${pass}`, session.token as any)
                .then(fileData => {
                    setFileData(fileData);
                    let fileURL = window.URL.createObjectURL(fileData);

                    setDownloadedUrl(fileURL);

                    let fileLink = document.createElement('a');
                    fileLink.href = fileURL;

                    // suggest a name for the downloaded file
                    fileLink.download = file.data.name;

                    // simulate click
                    fileLink.click();
                });

        };

        const onShare = (share) => {
            setFile({ ...file, shares: [...file.shares, share] });
        }

        const onChanged = (share) => {
            route('/');
        };

        return html`<div class="card mb-3">
        <div class="card-header">
            
            <div class="row align-items-center">
                
                <div class="col">
                    <h5 class="mb-0">#${file.data.name}</h5>
                    <a onClick=${onDownloadClick} class="btn btn-falcon-primary btn-sm" type="button">${t('Download')}</a>
                    <${Share} label="File" id=${id} path="/files" onShare=${onShare} />
                    <${ChangeOwnership} label="File" id=${id} path="/files" onShare=${onChanged} />
                </div>
            </div>
        </div>
        </div>
    ${file.shares.length ? html`
    <div class="card mb-3">
        <div class="card-header">
            <h5 class="mb-0">${t('Shares')}</h5>
        </div>
        <div class="card-body bg-light">
            ${file.shares.map((it: any) => html`<div class="row">
                <div class="col">
                    <p>${t('Shared by')} <b>${it.created_by_name}</b> ${t('to')} <b>${it.user_name || it.office_name}</b> ${t('at')} <b>${new Date(it.created_at).toDateString()}</b></p>
                </div>
                <div class="col">
                    <button class="btn btn-danger btn-sm" onClick=${() => {
                Api.remove('/shares/' + it.id, session.token).then(() => {
                    setFile({ ...file, shares: file.shares.filter((t: any) => t.id != it.id) });
                    toast.success(t('Share removed successfully'));
                }
                );
            }
            }>${t('Remove')}</button>
            
                </div>
            </div>`)
                }
        </div>
    </div>` : ''}
    <div class="card mb-3">
<div class="card-body">
        <div class="row">
        ${file.data.mime == 'application/pdf' || file.data.mime?.includes('image') ?
                html`<object data=${downloadedUrl}
        type=${file.data.type}
        width='100%' height='700px'>
</object>` : ``}
            </div>
        </div>
        </div>
        `;

    } else {
        return html`<div>File loading</div>`;
    }
}