import { html } from "htm/preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { generateId, getPage, Pagination, t, useInput } from "./core";

import Select from 'react-select'
import { citites, socialSecurityOptions } from "./data";

import { Api, debounce, filesStore, getDownloadUrl, merge, searchObjects, SessionContext, SFile, state, useSession } from "./store";
import { Billable } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreatableSelect from 'react-select/creatable';
import { Drive } from "./products";
import { FileUpload, ObjectRow, UpdateObject } from "./crud";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Button, Modal } from "react-bootstrap";
import { ActivityForm } from "./activites";
import { Contact } from "./contacts";
import { IdentifierTypes } from "./entities";
import AsyncSelect from "react-select/async";



export function AddForm({ data, formRef, onAdded }: { data?: { data: EstablishmentType, departments: Department[] }, formRef: any, onAdded: any }) {


    const [officesOptions, setOfficesOptions] = useState<any[]>([]);
    const [form, setForm] = useState<Partial<EstablishmentType>>({ name: data?.data.name });

    const set = (key: keyof EstablishmentType) => (value: any) => {
        setForm({ ...form, [key]: value.target ? value.target.value : value });
    }

    const [departments, setDepartments] = useState<any[]>(data?.departments || []);

    const [entities, setEntities] = useState<any[]>([]);


    const [validated, setValidated] = useState(false);
    const [state, dispatch] = useSession();



    const onSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {

            const newObject = { name: form.name, departments };

            if (data) {

                Api.patch('/offices-types', data.data.id, newObject, state.token)
                    .then((entity) => {
                        toast.success(t('Updated successfully'));
                        onAdded(entity);
                    });

            } else {
                Api.post('/offices-types', newObject, state.token).then((entity) => {
                    toast.success(t('Added successfully'));
                    if (onAdded) {
                        onAdded(entity);
                    } else {
                        // route('/entities/' + entity.id);
                    }
                });
            }

        }

    };

    return html`<form ref=${formRef} id="entity-form" onsubmit="${onSubmit}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-12">
        <label class="form-label" for="name">${t('Name')}</label>
        <input type="text" class="form-control" id="name" value=${form.name} onInput=${set('name')} required />
        <div class="invalid-feedback">${t('Name')} ${t('is required')}</div>
    </div>
    <label class="form-label" for="name">${t('Departments')}</label>
    ${departments.map((it, index) => html`
    <div class="d-flex">
        <input type="text" class="form-control" id="name" value=${it.name} onInput=${(ev) => {
            departments[index].name = ev.target.value;
            setDepartments([...departments]);
        }} required />
        <div class="invalid-feedback">${t('Name is required')}</div>
        <button onClick=${() => setDepartments([...departments.filter((it, i) => i !== index)])} class="btn btn-primary" type="button"> ${t('Remove')}</button>
    </div>`)}
    <button style="width:30%"  onClick=${() => setDepartments([...departments, { name: '' }])} class="btn btn-primary" type="button"> ${t('Add')} ${t('Department')}</button>
    
    ${formRef ? '' : html`
    <div class="col-12 d-flex justify-content-end">
        <button id="entity-submit" class="btn btn-primary" type="submit"> ${data?.data.id ? t('Update') : t('Create')}</button>
    </div>`}
</form>`;
}


export interface NewEstablishmentType {
    date: string;
    name: string;
    identifier: string;
    identifier_type: string;
    badge_number: string;
    arrived_at: string;
    departed_at: string;
    office_id: number;
    department_id: number;
    user_id: number;
    purpose: string;
}

export interface EstablishmentType extends NewEstablishmentType {
    id: number;
    name: string;
}

export interface Department {
    office_type: number;
    id: number;
    name: string;
    office_id: number;
}

export function EstablishmentsTypes(name: string, path: string, ObjectRow_: any, props: any) {

    const [session, dispatch] = useSession();

    const establishmentTypeFormRef = useRef(null);


    const [formData, setFormData] = useState();

    const [showEstablishmentType, setShowEstablishmentType] = useState(false);


    const [objects, setObjects] = useState<{ data: EstablishmentType, departments: Department[] }[]>([]);
    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;


    const onSearch = (q?: any) => {
        Api.search('/offices-types', q || '', 1, {}, session.token)
            .then((data: { data: EstablishmentType[], departments: Department[] }) => {
                setObjects(data.data.map(it => {
                    const departments = data.departments.filter((d) => d.office_type == it.id);
                    return { data: it, departments };
                }));
            });
    };

    useEffect(() => {
        onSearch();
        dispatch({ type: 'onSearch', data: onSearch });
    }, []);

    const onDelete = (object: EstablishmentType) => {
        setObjects(objects.filter(it => it.data.id != object.id));
        toast.success(t('Log deleted successfully'));
    }

    const onAdded = (object: { data: EstablishmentType, departments: Department[] }) => {
        if (objects.find(it => it.data.id == object.data.id)) {
            setObjects(objects.map(it => it.data.id == object.data.id ? object : it));
        } else {
            setObjects([object, ...objects]);
        }
        setShowEstablishmentType(false);
    }

    const onEdit = (object: any) => {
        setFormData(object);
        setShowEstablishmentType(true);
    }

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t(name)}</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a onClick=${() => { setFormData(undefined); setShowEstablishmentType(true) }} class="btn btn-falcon-default btn-sm" type="button">
                    <span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Add')}</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive" style="height:100%">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('id')}
                            class="sort ${sort.dir.id}" style="width:10px" data-sort>
                            ${t('ID')}</th>
                        <th onclick=${onSort('name')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.name}" data-sort>${t('Name')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Departments')}</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${objects.map(it => EstablishmentTypeRow(it, onDelete, onEdit))}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${objects.length} page=${page}/>
</div>
  <${Modal}
show=${showEstablishmentType}
onHide=${() => setShowEstablishmentType(false)}
backdrop="static"
keyboard=${false}
size="xl"
>
<${Modal.Header} closeButton>
  <${Modal.Title}>${t('Add')} ${t('Establishment Type')}</Modal.Title>
</${Modal.Header}>
<${Modal.Body}>
  <${AddForm} data=${formData} formRef=${establishmentTypeFormRef} onAdded=${onAdded}/>
</${Modal.Body}>
<${Modal.Footer}>
  <${Button} variant="secondary" onClick=${() => setShowEstablishmentType(false)}>
    Close
    </${Button}>
  <${Button} variant="primary" onClick=${() => (establishmentTypeFormRef.current as any).dispatchEvent(new Event('submit'))} >${t('Add')}</${Button}>
</${Modal.Footer}>
</${Modal}>

`;
}


export function EstablishmentTypeRow(object: { data: EstablishmentType, departments: Department[] }, onDelete: any, onEdit?: any) {

    const [open, setOpen] = useState(false);
    const [state, dispatch] = useSession();
    const [busy, setBusy] = useState(false);

    const setDepartedAt = (ev: any) => {

        if (confirm('Are you sure you want to update the departure time?')) {

            const newObject = { ...object, departed_at: ev.target.value + ':00' };
            Api.patch('/offices-types', object.data.id, newObject, state.token || '')
                .then(() => {
                    console.log('UPDATED');
                    object.data.departed_at = newObject.departed_at;
                });
        }
    };

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);


    function onDeleteClick(object: EstablishmentType) {
        if (busy) return;
        setBusy(true);
        Api.remove('/offices-types/' + object.id, state.token || '')
            .then(() => {
                console.log('DELETED');
                setOpen(false);
                onDelete(object);
                setBusy(false);
            })
            .catch((err) => {
                console.log('ERROR', err);
                setBusy(false);
            });
    };


    console.log('object', object);

    return html`<tr class="btn-reveal-trigger">
    <td class="name align-middle white-space-nowrap">
            <h6>${object.data.id}</h6>
    </td>
        <td class="phone align-middle white-space-nowrap desktop">${object.data.name}</td>

    <td class="phone align-middle white-space-nowrap desktop">${object.departments.map(it => it.name).join(', ')}</td>
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <a class="dropdown-item" onclick=${() => onEdit && onEdit(object)}>${t('Edit')}</a>
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(object.data)}>${t('Delete')}</a></div>
            </div>
        </div>
    </td>
</tr>`;
}