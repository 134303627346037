import { html } from "htm/preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { generateId, getPage, Pagination, t, useInput } from "./core";

import Select from 'react-select'
import { citites, socialSecurityOptions } from "./data";

import { Api, can, debounce, filesStore, getDownloadUrl, merge, searchObjects, SessionContext, SFile, state, useSession } from "./store";
import { Billable } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreatableSelect from 'react-select/creatable';
import { Drive } from "./products";
import { FileUpload, ObjectRow, UpdateObject } from "./crud";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Button, Modal } from "react-bootstrap";
import { ActivityForm } from "./activites";
import { Contact } from "./contacts";
import { IdentifierTypes } from "./entities";
import AsyncSelect from "react-select/async";
import { Department } from "./establishments-types";
import { Office, User } from "./users";
import { AddGuestModal, Guest, IdentityTypeIcon, VisitorInfo } from "./visitors-log";



export function AddForm({ data, formRef, onAdded }: { data?: EquipmentLog, formRef: any, onAdded: any }) {
    const [state, dispatch] = useSession();


    const [officesOptions, setOfficesOptions] = useState<any[]>([]);
    const [form, setForm] = useState<Partial<EquipmentLog>>({
        datetime: new Date().toISOString().slice(0, 16),
        operation: { label: t('exit'), value: 'exit' },
        office_id: state.office ? { label: state.office.name, value: state.office.id } : undefined,
        equipments: [{ kind: '', model: '', reference: '' }],
    });

    const set = (key: keyof EquipmentLog, field?: string, index?: number) => (value: any) => {
        if (field && index !== undefined) {
            const item = (form[key] as any)[index] || {} as any;
            (form[key] as any)[index] = { ...item, [field]: value.target ? value.target.value : value };
            setForm({ ...form });

        } else {
            setForm({ ...form, [key]: value.target ? value.target.value : value });
        }
    }

    const [validated, setValidated] = useState(false);
    const [visitor, setVisitor] = useState<any>();

    const activityFormRef = useRef(null);
    const [showAddGuest, setShowAddGuest] = useState(false);

    const onGuestAdded = (guest: any) => {
        setVisitor(guest);
        setShowAddGuest(false);
    };

    useEffect(() => {
        Api.search('/offices', '', 1, {}, state.token).then((res) => {
            setOfficesOptions(res.data.map((it: any) => ({ label: it.name, value: it.id })));
        });
    }, []);


    const onSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {

            if (!visitor) {
                toast.error(t('Visitor is required'));
                return;
            }

            const newObject = { ...form };

            Object.keys(newObject).forEach((key) => {
                if (newObject[key]?.value) {
                    newObject[key] = newObject[key].value;
                }
            });

            newObject.datetime += ':00';
            newObject.purpose = newObject.purpose || '';

            if (visitor.guest) {
                newObject.guest_id = visitor.id;
            } else {
                newObject.user_id = visitor.id;
            }

            console.log('EQUP', newObject.equipments);
            newObject.equipments = newObject.equipments?.map(it => ({
                reference: it.reference?.value || '',
                model: it.model?.value || '',
                kind: it.kind?.value || ''
            }));

            if (data?.id) {

                Api.patch('/equipments-logs', data.id, newObject, state.token)
                    .then((entity) => {
                        toast.success(t('Log updated successfully'));
                    });

            } else {
                Api.post('/equipments-logs', newObject, state.token).then((entity) => {
                    toast.success(t('Log added successfully'));
                    if (onAdded) {
                        onAdded(entity);
                    } else {
                        // route('/entities/' + entity.id);
                    }
                });
            }

        }

    };


    const loadEquipmentOptions = (field) => (inputValue: string) =>
        Api.search('/equipments-logs', inputValue, 1, {}, state.token).then((res) => {
            return res.data.map((it: any) => it[0].equipments.map(it => it[field]).filter(it => it)).reduce((p, n) => p.concat(n), []).map(it => ({ label: it, value: it }));
        });

    const loadUsersOptions = (inputValue: string) =>
        Api.search('/users', inputValue, 1, {}, state.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });

    const loadDepartmentsOptions = (inputValue: string) =>
        Api.search('/departments', inputValue, 1, {}, state.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });

    const loadVisitorsOptions = (inputValue: string) =>
        Promise.all([
            Api.search('/users', inputValue, 1, {}, state.token),
            Api.search('/guests', inputValue, 1, {}, state.token)
        ]).then(([users, guests]) => {
            guests.data.forEach(it => it.guest = true);
            return users.data.concat(guests.data);
        });
    const handleCreateGuest = (inputValue: string) => {
        setShowAddGuest(true);
    };

    return html`<form ref=${formRef} id="entity-form" onsubmit="${onSubmit}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-6">
        <label class="form-label" for="type">${t('Operation')}</label>
        <${Select} value=${form.operation}
        options=${[{ label: t('entry'), value: 'entry' }, { label: t('exit'), value: 'exit' }]} onChange=${set('operation')}
        className="rs form-control ${validated && (form.operation ? 'is-valid' : 'is-invalid')}" />
    </div>

    <div class="col-lg-6">
        <label class="form-label" for="type">${t('Date Time')}</label>
        <input type="datetime-local"  value=${form.datetime} onInput=${set('datetime')} class="form-control" />
    </div>
    
    <div class="col-lg-4">
    <label class="form-label" for="name">${t('Visitor')}</label>
<${AsyncCreatableSelect} 
    getOptionLabel=${option => option.__isNew__ ? t('Add') + ` '${option.value}'` : option.name + ` (${t(option.guest ? 'Guest' : 'Employee')})`} getOptionValue=${option => option.id} cacheOptions defaultOptions
isClearable onCreateOption=${handleCreateGuest} loadOptions=${loadVisitorsOptions}  value=${visitor} onChange="${setVisitor}"
    className="rs form-control ${validated && (visitor ? 'is-valid' : 'is-invalid')}" />
</div>
    ${visitor ? html`<div class="col-lg-2">
                <label class="form-label" for="name">${t('Identifier')}</label>

        <div class="form-control">
        <${IdentityTypeIcon} type=${visitor.identifier_type}/>
        ${visitor.identifier}
        </div>
    </div>` : html`<div class="col-lg-2"></div>`}
    <div class="col-lg-6">
        <label class="form-label" for="name">${t('Destination')}</label>    
        <${AsyncCreatableSelect}  getOptionLabel=${option => option.__isNew__ ? t('Add') + ` '${option.value}'` : option.label} cacheOptions defaultOptions
        isClearable  loadOptions=${loadEquipmentOptions('model')}  value=${form.destination} onChange="${set('destination')}"
        className="rs form-control ${validated && (form.destination ? 'is-valid' : 'is-invalid')}" />
        <div class="invalid-feedback">${t('Destination')} ${t('is required')}</div>
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="type">${t('Establishment')}</label>
        <${Select} value=${form.office_id}
        options=${officesOptions} onChange=${set('office_id')}
        className="rs form-control ${validated && (form.office_id ? 'is-valid' : 'is-invalid')}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="type">${t('Department')}</label>
        <${AsyncSelect}  cacheOptions defaultOptions isClearable loadOptions=${loadDepartmentsOptions}  value=${form.department_id} onChange="${set('department_id')}"
        className="rs form-control ${validated && (form.department_id ? 'is-valid' : 'is-invalid')}" />
    </div>
    ${form.equipments?.map((equipment, index) => html`
    <div class="col-lg-3">
        <label class="form-label" for="type">${t('Equipment Reference')}</label>
                <${AsyncCreatableSelect}  getOptionLabel=${option => option.__isNew__ ? t('Add') + ` '${option.value}'` : option.label} cacheOptions defaultOptions
        isClearable  loadOptions=${loadEquipmentOptions('reference')}  value=${equipment.reference} onChange="${set('equipments', 'reference', index)}"
        className="rs form-control ${validated && (equipment.reference ? 'is-valid' : 'is-invalid')}" />
    </div>
        <div class="col-lg-3">
        <label class="form-label" for="type">${t('Equipment Type')}</label>
        <${AsyncCreatableSelect}  getOptionLabel=${option => option.__isNew__ ? t('Add') + ` '${option.value}'` : option.label} cacheOptions defaultOptions
        isClearable  loadOptions=${loadEquipmentOptions('kind')}  value=${equipment.kind} onChange="${set('equipments', 'kind', index)}"
        className="rs form-control ${validated && (equipment.kind ? 'is-valid' : 'is-invalid')}" />
    </div>
        <div class="col-lg-3">
        <label class="form-label" for="type">${t('Equipment Model')}</label>
        <${AsyncCreatableSelect}  getOptionLabel=${option => option.__isNew__ ? t('Add') + ` '${option.value}'` : option.label} cacheOptions defaultOptions
        isClearable  loadOptions=${loadEquipmentOptions('model')}  value=${equipment.model} onChange="${set('equipments', 'model', index)}"
        className="rs form-control ${validated && (equipment.model ? 'is-valid' : 'is-invalid')}" />
    </div>
<div class="col-lg-3" style="padding-top:30px">
                        <button onClick=${() => set('equipments')(form.equipments?.filter((it, i) => i !== index))} class="btn btn-primary" type="button"> ${t('Remove')}</button>
</div>
    `)}
    <button style="width:30%;margin-top:20px"  onClick=${() => set('equipments', 'reference', form.equipments?.length)('')} class="btn btn-primary" type="button"> ${t('Add')} ${t('Equipment')}</button>

    ${formRef ? '' : html`
    <div class="col-12 d-flex justify-content-end">
        <button id="entity-submit" class="btn btn-primary" type="submit"> ${data?.id ? t('Update') : t('Create')}</button>
    </div>`}
</form>
<${AddGuestModal} show=${showAddGuest} setShow=${setShowAddGuest} onAdded=${onGuestAdded} />

`;
}
/*
    pub operation: String,
    pub datetime: NaiveDateTime,
    pub reference: String,
    pub kind: String,
    pub model: String,
    pub person_identifier: String,
    pub purpose: String,
    pub destination: String,
    pub office_id: i32,
    pub department_id: Option<i32>,
*/

export interface NewEquipmentLog {
    operation: string;
    datetime: string;
    equipments: {
        reference: string;
        kind: string;
        model: string
    }[];
    user_id?: number;
    guest_id?: number;
    purpose: string;
    destination: string;
    office_id: number;
    department_id?: number;
}

export interface EquipmentLog extends NewEquipmentLog {
    id: number;
    created_by: number;
    created_at: string;
    updated_at: string;
    deleted_by: number;
    deleted_at: string;
}

export interface EquipmentLogRow extends EquipmentLog {
    office_name?: string;
    department_name?: string;
}

export interface IdName {
    id: number;
    name: string;
}

export function EquipmentLog(name: string, path: string, ObjectRow_: any, props: any) {

    const [session, dispatch] = useSession();

    const visitorLogFormRef = useRef(null);

    const [showEquipmentLog, setShowEquipmentLog] = useState(false);


    const [objects, setObjects] = useState<[EquipmentLogRow, User | undefined, Guest | undefined][]>([]);
    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;


    const onSearch = (q?: any) => {

        Api.search('/equipments-logs', q || '', 1, {}, session.token)
            .then((data: { data: [EquipmentLogRow, User | undefined, Guest | undefined][], offices: IdName[], departments: IdName[] }) => {
                data.data.forEach(([it]) => {
                    it.office_name = data.offices.find((t: any) => t.id == it.office_id)?.name || it.office_id.toString();
                    it.department_name = data.departments.find((t: any) => t.id == it.department_id)?.name || it.department_id?.toString();
                });
                setObjects(data.data);
            });
    };

    useEffect(() => {
        onSearch();
        dispatch({ type: 'onSearch', data: onSearch });
    }, []);

    const onDelete = (object: EquipmentLog) => {
        setObjects(objects.filter(it => it[0].id != object.id));
        toast.success(t('Log deleted successfully'));
    }

    const onAdded = (object: { data: EquipmentLog, user?: User, guest?: Guest }) => {
        setObjects([[object.data, object.user, object.guest], ...objects]);
        setShowEquipmentLog(false);
    }

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t('Equipments Log')}</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a onClick=${() => setShowEquipmentLog(true)} class="btn btn-falcon-default btn-sm" type="button">
                    <span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Add')}</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('date')}
                            class="sort ${sort.dir.date}" style="width:10px" data-sort>
                            ${t('Date')}</th>
                        <th onclick=${onSort('name')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.name}" data-sort>${t('Type')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Reference')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Equipment Type')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Equipment Model')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Person ID')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Destination')}</th>
                            <th class="sort align-middle white-space-nowrap desktop" >${t('Establishment')}</th>
                         <th class="sort align-middle white-space-nowrap desktop" >${t('Department')}</th>

                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${objects.map(it => VisistorLogRow(it, onDelete))}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${objects.length} page=${page}/>
</div>
  <${Modal}
show=${showEquipmentLog}
onHide=${() => setShowEquipmentLog(false)}
backdrop="static"
keyboard=${false}
size="xl">
<${Modal.Header} closeButton>
  <${Modal.Title}>${t('Add')} ${t('Equipment Log')}</Modal.Title>
</${Modal.Header}>
<${Modal.Body}>
  <${AddForm} formRef=${visitorLogFormRef} onAdded=${onAdded}/>
</${Modal.Body}>
<${Modal.Footer}>
  <${Button} variant="secondary" onClick=${() => setShowEquipmentLog(false)}>
    Close
    </${Button}>
  <${Button} variant="primary" onClick=${() => (visitorLogFormRef.current as any).dispatchEvent(new Event('submit'))} >${t('Add')}</${Button}>
</${Modal.Footer}>
</${Modal}>

`;
}


export function VisistorLogRow([object, user, guest]: [EquipmentLogRow, User?, Guest?], onDelete: any) {

    const visitor = (user || guest) as VisitorInfo;
    const [open, setOpen] = useState(false);
    const [state, dispatch] = useSession();
    const [busy, setBusy] = useState(false);

    const [departedAtSaved, setDepartedAtSaved] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);


    function onDeleteClick(object: any) {
        if (busy) return;
        setBusy(true);
        Api.remove('/equipments-logs/' + object.id, state.token || '')
            .then(() => {
                console.log('DELETED');
                setOpen(false);
                onDelete(object);
                setBusy(false);
            })
            .catch((err) => {
                console.log('ERROR', err);
                setBusy(false);
            });
    };

    console.log('object', object);

    return html`<tr class="btn-reveal-trigger">
    <td class="name align-middle white-space-nowrap">
    <a href=${'/tracking-tables/equipments-log/' + object.id}>
            <h6>${object.datetime}</h6>
            </a>

    </td>
        <td class="phone align-middle white-space-nowrap desktop">${t(object.operation)}</td>

    <td class="phone align-middle white-space-nowrap desktop">${object.equipments.map(it => it.reference).join(',')}</td>
    <td class="phone align-middle white-space-nowrap desktop">
        ${object.equipments.map(it => it.kind).join(',')}</td>
    <td class="phone align-middle white-space-nowrap desktop">${object.equipments.map(it => it.model).join(',')}</td>

    <td class="phone align-middle white-space-nowrap desktop">${visitor.name}</td>
        <td class="phone align-middle white-space-nowrap desktop">${object.destination}</td>
    <td class="phone align-middle white-space-nowrap desktop">${object.office_name}</td>
    <td class="phone align-middle white-space-nowrap desktop">${object.department_name}</td>

    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
        ${can(state, 'EquipmentLog', 'delete') ? html`
            <button onclick=${(ev: any) => {
                ev.preventDefault();
                ev.stopImmediatePropagation();
                setOpen(!open);
            }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(object)}>Delete</a></div>
            </div>`: ''}
        </div>
    </td>
</tr>`;
}

export function EquipmentLogDetails({ id }: { id?: string }) {

    const [session, dispatch] = useSession();

    const [object, setObject] = useState<{ data: EquipmentLog, office: Office, department?: Department, user?: User, guest?: Guest } | undefined>();

    const visitor = object?.user || object?.guest;

    useEffect(() => {
        if (id) {
            Api.get('/equipments-logs', id, session.token as any).then((data) => {
                setObject(data);
            });
        }
    }, []);


    const [showShare, setShowShare] = useState(false);
    const [shareUser, setShareUser] = useState<any>();
    const [shareValidated, setShareValidated] = useState(false);

    const onShareSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setShareValidated(true);
        if (shareUser) {
            Api.post('/procedures/' + id + '/share', { user_id: shareUser.value }, session.token).then(() => {
                setShowShare(false);
                toast.success(t('Procedure shared successfully'));
            });
        }
    };

    const loadOptionsUsers = (inputValue: string) =>
        Api.search('/users', inputValue, 1, {}, session.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });




    if (object) {

        return html`<div class="card mb-3">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h5 class="mb-0">#${object.data.datetime}  ${t(object.data.operation)}</h5>
            </div>
            <div class="col-auto">
        </div>
        </div>
    </div>
    <div class="card-body bg-light border-top">
        <div class="row">
            <div class="col-lg col-xxl-5">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Date')}</p>
                    </div>
                    <div class="col">${object.data.datetime}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Person ID')}</p>
                    </div>
                    <div class="col">${visitor?.name} - ${visitor?.identifier}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Destination')}</p>
                    </div>
                    <div class="col">${object.data.destination}</div>
                </div>

                 <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Establishment')}</p>
                    </div>
                    <div class="col">${object.office.name}</div>
                </div>
                 <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Department')}</p>
                    </div>
                    <div class="col">${object.department?.name}</div>
                </div>
                

            </div>
            <div class="col-lg col-xxl-5">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th class="sort" data-sort="date">${t('Equipment Type')}</th>
                        <th class="sort align-middle" data-sort="name">${t('Equipment Model')}</th>
                        <th class="sort align-middle" data-sort="price">${t('Reference')}</th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
${object.data.equipments.map((equipment, index) => html`
                    <tr class="btn-reveal-trigger">
    <td class="align-middle">${equipment.kind}
    </td>
    <td class="align-middle py-2">${equipment.model}
    </td>
    <td class="align-middle py-2">${equipment.reference}
    </td>

</tr>`)}
                </tbody>
            </table>
        </div>
            </div>
        </div>
    </div>
</div>
`;

    } else {
        return html`<div>User ${id} not found</div>`;
    }
}

