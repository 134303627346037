import { html } from "htm/preact";
import { useEffect, useState } from "preact/hooks";
import { Api, useSession } from "./store";
import { getPage, Pagination, t } from "./core";




export function ClosingTable() {


  const [session, dispatch] = useSession();
  const [data, setData] = useState<any>({ data: [] });

  const page = getPage();


  useEffect(() => {
    Api.search('/procedures', '', 1, { category: "إيقاف نشاط" }, session.token)
      .then(res => {
        res.data.forEach(([procedure], index) => {
          procedure.increment_id = res.data.length - index;

        });
        setData(res);
      });
  }, []);

  // Helper functions to find related data by ID
  const findEntityById = (id: any) => data.entities.find((entity) => entity.id === id);
  const findUserById = (id) => data.users.find((user) => user.id === id);
  const findProcedureTypeById = (id) => data.procedures_types.find((type) => type.id === id);


  const [sort, setSort] = useState({ field: '', dir: {} as any });

  const onSort = (field: string) => {
    return () => {
      let dir = sort.dir[field] == 'asc' ? 'desc' : 'asc';
      setSort({ field, dir: { [field]: dir } });
      const sorted = data.data.sort((a, b) => {
        if (dir == 'asc') {
          return a[field] > b[field] ? 1 : -1;
        } else {
          return a[field] < b[field] ? 1 : -1;
        }
      });
      setData({ ...data, data: sorted });
    };
  };

  return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t('Tracking Table for the Closure of Tax Files')} 2024</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a onClick=${() => printData2('.table-responsive', `${t('Tracking Table for the Closure of Tax Files')} 2024`)} class="btn btn-falcon-default btn-sm" type="button">
                    <span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Print')}</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th onClick=${onSort('id')} class="text-900 sort pe-1 align-middle white-space-nowrap ${sort.dir.id}" data-sort="incrementId" scope="col">${t('Ordinal Number')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Request Date')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Acceptance Date')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Name and surname or social name')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Chosen Address for Correspondence')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Outcome of the Request')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Tax Identification Number')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Closure Date')} (تأشيرة ر م على تصريح الغلق)</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Service Duration')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Agent in Charge of the File')}</th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                ${data.data.map(([procedure, share, entity, contact]) => {
    const procedureType = null;// findProcedureTypeById(procedure.type_id);

    return html`
                            <tr key=${procedure.id}>
                              <td>${procedure.increment_id}</td>
                              <td>${new Date(procedure.created_at).toLocaleDateString()}</td>
                              <td>${procedure.accepted_at ? new Date(procedure.accepted_at).toLocaleDateString() : "N/A"}</td>
                              <td>${entity ? entity.name || entity.commercial_name : "N/A"}</td>
                              <td>${entity?.address}</td>
                              <td>${t(procedure.status)}</td>
                              <td>${entity?.identifier}</td>
                              <td>${procedure.closed_at ? new Date(procedure.closed_at).toLocaleDateString() : "N/A"}</td>
                              <td>${differenceInDays(procedure.accepted_at, procedure.closed_at)}</td>
                              <td>${findUserById(procedure.owner_id)?.name}</td>
                            </tr>`;
  })}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${data.data.length} page=${page}/>
</div>`;

  return html`<div class="container mt-4">
      <h3 class="text-center mb-4">جدول متابعة غلق الملفات الجبائية 2024</h3>
      <div style="width:100%;text-align:left">
    <button class="btn btn-primary" style="margin-bottom:10px" onClick=${printData}>طباعة</button>
    </div>
      <div class="card table-list mb-3 table-responsive scrollbar mb-3">
      <table id="printTable" data-list class="table table-striped fs-10 mb-0 overflow-hidden">
        <thead class="thead-light">
          <tr>
            <th onClick=${onSort} class="text-900 sort pe-1 align-middle white-space-nowrap asc" data-sort="incrementId" scope="col">العدد الرتبي</th>
            <th scope="col">تاريخ المطلب</th>
            <th scope="col">تاريخ القبول</th>
            <th scope="col">االمنتفع<br/>الإسم أو اللقب أو الإسم الإجتماعي</th>
            <th scope="col">محل المخابرة المختار</th>
            <th scope="col">مآل المطلب</th>
            <th scope="col">معرف جبائي</th>
            <th scope="col">تاريخ الغلق (تأشيرة ر م على تصريح الغلق)</th>
            <th scope="col">مدة الخدمة</th>
            <th scope="col">العون المكلف بالملف</th>
          </tr>
        </thead>
        <tbody>
          ${data.data.map(([procedure, share, entity, contact]) => {
    const procedureType = null;// findProcedureTypeById(procedure.type_id);

    return html`
              <tr key=${procedure.id}>
                <td>${procedure.increment_id}</td>
                <td>${new Date(procedure.created_at).toLocaleDateString()}</td>
                <td>${procedure.accepted_at ? new Date(procedure.accepted_at).toLocaleDateString() : "N/A"}</td>
                <td>${entity ? entity.name || entity.commercial_name : "N/A"}</td>
                <td>${entity?.address}</td>
                <td>${t(procedure.status)}</td>
                <td>${entity?.identifier}</td>
                <td>${procedure.closed_at ? new Date(procedure.closed_at).toLocaleDateString() : "N/A"}</td>
                <td>${differenceInDays(procedure.accepted_at, procedure.closed_at)}</td>
                <td>${findUserById(procedure.owner_id)?.name}</td>
              </tr>`;
  })}
        </tbody>
      </table>
      </div>
    </div>`;

  return html`<div className="container mt-4">
      <h3 className="text-center mb-4">جدول متابعة غلق الملفات الجبائية 2024</h3>
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th scope="col">الرقم</th>
            <th scope="col">تاريخ الطلب</th>
            <th scope="col">تاريخ القبول</th>
            <th scope="col">الإسم أو اللقب أو الإسم الإجتماعي</th>
            <th scope="col">التنفيذ</th>
            <th scope="col">مختار</th>
            <th scope="col">مآل المطلب</th>
            <th scope="col">مدة</th>
            <th scope="col">تاريخ الغلق (تأشيرة رمز على تصريح الغلق)</th>
            <th scope="col">معرف جبائي</th>
            <th scope="col">محل التجارة</th>
            <th scope="col">عون المكلف بالملف</th>
            <th scope="col">الخدمة</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="13" className="text-center">
              No data available
            </td>
          </tr>
        </tbody>
      </table>
    </div>`;
};


function differenceInDays(from: string, to: string) {
  if (!from || !to) {
    return "N/A";
  }
  const a = new Date(to);
  const b = new Date(from);
  return Math.floor((a.getTime() - b.getTime()) / (1000 * 60 * 60 * 24));
}

function printData() {
  var divToPrint = document.getElementById("printTable");
  const newWin = window.open("");
  newWin.document.write(`<html dir="rtl"><head>
        <style>
        table {
            border: solid #000 !important;
            border-width: 1px 0 0 1px !important;
        }
        th, td {
            border: solid #000 !important;
            border-width: 0 1px 1px 0 !important;
            padding: 0px 5px !important;
        }
            h3 { text-align: center; }
        </style>
        
        <title></title></head><body><h3>جدول متابعة غلق الملفات الجبائية 2024</h3>
        
        ${divToPrint.outerHTML}</body>`);
  newWin.print();
  newWin.close();
}


function printData2(selector, title: string) {
  var divToPrint = document.querySelector(selector);
  const newWin = window.open("");
  newWin.document.write(`<html dir="rtl"><head>
        <style>
        table {
            border: solid #000 !important;
            border-width: 1px 0 0 1px !important;
        }
        th, td {
            border: solid #000 !important;
            border-width: 0 1px 1px 0 !important;
            padding: 0px 5px !important;
        }
            h3 { text-align: center; }
        </style>
        
        <title></title></head><body><h3>${title}</h3>
        
        ${divToPrint.outerHTML}</body>`);
  newWin.print();
  newWin.close();
}




export function GuidesTable() {


  const [session, dispatch] = useSession();
  const [data, setData] = useState<any>({ data: [] });



  useEffect(() => {
    Api.search('/procedures', '', 1, { category: "طلبات التوضيحات" }, session.token)
      .then(res => {
        res.data.forEach(([procedure], index) => {
          procedure.increment_id = res.data.length - index;

        });
        setData(res);
      });
  }, []);

  // Helper functions to find related data by ID
  const findEntityById = (id: any) => data.entities.find((entity) => entity.id === id);
  const findUserById = (id) => data.users.find((user) => user.id === id);
  const findProcedureTypeById = (id) => data.procedures_types.find((type) => type.id === id);


  const [sort, setSort] = useState({ field: '', dir: {} as any });

  const page = getPage();

  const onSort = (field: string) => {
    return () => {
      let dir = sort.dir[field] == 'asc' ? 'desc' : 'asc';
      setSort({ field, dir: { [field]: dir } });
      const sorted = data.data.sort((a, b) => {
        if (dir == 'asc') {
          return a[field] > b[field] ? 1 : -1;
        } else {
          return a[field] < b[field] ? 1 : -1;
        }
      });
      setData({ ...data, data: sorted });
    };
  };

  return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t('Tracking Table for Requests for Clarifications and Guidance')} 2024</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a onClick=${() => printData2('.table-responsive', `${t('Tracking Table for Requests for Clarifications and Guidance')} 2024`)} class="btn btn-falcon-default btn-sm" type="button">
                    <span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Print')}</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th onClick=${onSort('id')} class="text-900 sort pe-1 align-middle white-space-nowrap ${sort.dir.id}" data-sort="incrementId" scope="col">${t('Ordinal Number')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Request Date')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Name and surname or social name')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Tax Identification Number')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Clarifications and Guidance Request Subject')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Chosen Address for Correspondence')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Number')} (صادق)</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Date')} (صادق)</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Notification Date')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Agent')}</th>
                        <th scope="sort align-middle white-space-nowrap desktop">${t('Notes')}</th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
          ${data.data.map(([procedure, share, entity, contact]) => {
    const procedureType = null;// findProcedureTypeById(procedure.type_id);

    return html`
              <tr key=${procedure.id}>
                <td>${procedure.increment_id}</td>
                <td>${new Date(procedure.created_at).toLocaleDateString()}</td>
                <td>${entity ? entity.name || entity.commercial_name : "N/A"}</td>
                <td>${entity?.identifier}</td>
                <td>${procedure.note}</td>
                <td>${procedure.note}</td>
                <td>${procedure.note}</td>
                <td>${procedure.closed_at ? new Date(procedure.closed_at).toLocaleDateString() : "N/A"}</td>
                <td>${findUserById(procedure.owner_id)?.name}</td>
                <td>${procedure.note}</td>
              </tr>`;
  })}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${data.data.length} page=${page}/>
</div>`;

  return html`<div class="container mt-4">
      <h3 class="text-center mb-4">جدول متابعة طلبات التوضيحات و الإرشادات سنة 2024</h3>
            <div style="width:100%;text-align:left">
    <button class="btn btn-primary" style="margin-bottom:10px" onClick=${printData}>طباعة</button>
        </div>

      <div class="card table-list mb-3 table-responsive scrollbar mb-3">
      <table id="printTable" data-list class="table table-striped fs-10 mb-0 overflow-hidden">
        <thead class="thead-light">
          <tr>
            <th onClick=${onSort} class="text-900 sort pe-1 align-middle white-space-nowrap asc" data-sort="incrementId" scope="col">العدد الرتبي</th>
            <th scope="col">تاريخ الطلب</th>
            <th scope="col">الإسم أو اللقب أو الإسم الإجتماعي</th>
            <th scope="col">معرف جبائي</th>
            <th scope="col">موضوع طلب التوضيحات والإرشادات </th>
            <th scope="col">العدد (صادق)</th>
            <th scope="col">التاريخ (صادق)</th>
            <th scope="col">تاريخ االتبليغ</th>
            <th scope="col">العون</th>
            <th scope="col">ملاحظات</th>

          </tr>
        </thead>
        <tbody>
          ${data.data.map(([procedure, share, entity, contact]) => {
    const procedureType = null;// findProcedureTypeById(procedure.type_id);

    return html`
              <tr key=${procedure.id}>
                <td>${procedure.increment_id}</td>
                <td>${new Date(procedure.created_at).toLocaleDateString()}</td>
                <td>${entity ? entity.name || entity.commercial_name : "N/A"}</td>
                <td>${entity?.identifier}</td>
                <td>${procedure.note}</td>
                <td>${procedure.note}</td>
                <td>${procedure.note}</td>
                <td>${procedure.closed_at ? new Date(procedure.closed_at).toLocaleDateString() : "N/A"}</td>
                <td>${findUserById(procedure.owner_id)?.name}</td>
                <td>${procedure.note}</td>
              </tr>`;
  })}
        </tbody>
      </table>
      </div>
    </div>`;

  return html`<div className="container mt-4">
      <h3 className="text-center mb-4">جدول متابعة غلق الملفات الجبائية 2024</h3>
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th scope="col">الرقم</th>
            <th scope="col">تاريخ الطلب</th>
            <th scope="col">تاريخ القبول</th>
            <th scope="col">الإسم أو اللقب أو الإسم الإجتماعي</th>
            <th scope="col">التنفيذ</th>
            <th scope="col">مختار</th>
            <th scope="col">مآل المطلب</th>
            <th scope="col">مدة</th>
            <th scope="col">تاريخ الغلق (تأشيرة رمز على تصريح الغلق)</th>
            <th scope="col">معرف جبائي</th>
            <th scope="col">محل التجارة</th>
            <th scope="col">عون المكلف بالملف</th>
            <th scope="col">الخدمة</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="13" className="text-center">
              No data available
            </td>
          </tr>
        </tbody>
      </table>
    </div>`;
};